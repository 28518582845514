import { useToast } from '@chakra-ui/react';
import { useQuery, UseQueryResult } from 'react-query';
import { getTradingAccount } from '../../api/api';
import { TradingAccount } from '../../api/types';

const useTradingAccount = (accountID: string): UseQueryResult<TradingAccount, unknown> => {
  const toast = useToast();
  const resp = useQuery(['trading-account', accountID], () => getTradingAccount(accountID), {
    // Show a toast if there is an error
    onError: (err) =>
      toast({
        title: 'An error occurred fetching the trading account',
        description: (err as Error).message,
        status: 'error',
      }),
  });

  return resp;
};

export default useTradingAccount;
