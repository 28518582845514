import * as Sentry from '@sentry/browser';
import { Integrations } from '@sentry/tracing';

const init = (): void =>
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    environment: process.env.REACT_APP_ENV || 'production',
    release: process.env.REACT_APP_TAG_NAME || 'unknown',
    integrations: [new Integrations.BrowserTracing()],
  });

export default init;
