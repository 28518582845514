import styled from '@emotion/styled';
import React from 'react';
import { useDropzone } from 'react-dropzone';

export interface FileUploadProps {
  onDrop: (files: File[]) => void;
}

const DropzoneContainer = styled.div`
  background: #80808050;
  border: 2px dashed;
  border-radius: 4px;
  text-align: center;
  height: 3rem;
  line-height: 2.75rem;
  font-size: 1rem;

  &:hover {
    cursor: pointer;
  }
`;

const FileUpload = (props: FileUploadProps): React.ReactElement => {
  const { onDrop } = props;

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <DropzoneContainer {...getRootProps()}>
      <input {...getInputProps()} />
      {isDragActive ? <p>Drop the files here ...</p> : <p>Drag and drop some files here, or click to select files</p>}
    </DropzoneContainer>
  );
};

export default FileUpload;
