import React, { useEffect } from 'react';
import { Tabs, TabList, TabPanels, TabPanel, Flex, Spacer, useToast, Box } from '@chakra-ui/react';
import Table from '../components/general/Table';
import Header from '../components/layout/Header';
import { useState } from 'react';
import Tab from '../components/general/Tab';
import {
  FilterDrawer,
  dateRangeToPill,
  FilterDateSelect,
  FilterSelect,
  RangeType,
  FilterButton,
  QueryKey,
} from '../components/filter';
import ExportToCsv from '../components/tables/ExportButton';
import { getJournals } from '../api/api';
import { useInfiniteQuery } from 'react-query';
import moment from 'moment';
import { JournalStatus, JournalType } from '../api/types';
import { isMobile } from 'react-device-detect';
import { journalsToTableRows } from '../globals/utils';
import { useNavigate } from 'react-router-dom';
import { APILinks } from '../globals/consts';

const Journals = (): React.ReactElement => {
  const [selectedTab, setSelectedTab] = useState(0);
  const toast = useToast();
  const navigate = useNavigate();

  // Filter
  const [filterOpen, setFilterOpen] = useState(false);
  const [statusFilter, setStatusFilter] = useState<JournalStatus>('all');
  const [selectedDates, setSelectedDates] = useState<RangeType>([null, null]);
  const [filterKey, setFilterKey] = useState<QueryKey>(['journals']);
  const [applyFilter, setApplyFilter] = useState(false);

  // journal type depending on tab selected
  const typeFilter: JournalType = selectedTab === 0 ? 'JNLC' : 'JNLS';

  // Paginated query
  const fetchJournals = ({ pageParam = moment() }) => {
    const params = {
      entry_type: typeFilter,
      status: statusFilter,
      before: pageParam.toISOString(),
      after: moment(pageParam).subtract(3, 'months').toISOString(),
    };

    if (applyFilter) {
      if (selectedDates[0] && selectedDates[1]) {
        params.after = selectedDates[0].toISOString();
        params.before = selectedDates[1].toISOString();
      }
    }

    return getJournals(params);
  };

  const queryKeys = ['journals', statusFilter, selectedDates];
  useEffect(() => {
    if (applyFilter) setFilterKey(queryKeys);
  }, [...queryKeys, applyFilter]);

  const journalsQuery = useInfiniteQuery(filterKey, fetchJournals, {
    getNextPageParam: (lastPage) => {
      if (lastPage.length !== 0) {
        return lastPage[lastPage.length - 1].system_date;
      }
    },
  });

  const journals = journalsToTableRows(journalsQuery.data?.pages.flat(), typeFilter);

  if (journalsQuery.isError) {
    toast({
      title: 'An error occurred fetching journal entries',
      description: (journalsQuery.error as Error).message,
      status: 'error',
    });
  }

  const filterPills = {
    status: statusFilter,
    dates: dateRangeToPill(selectedDates),
  };

  const removeFilter = (filterID: string) => {
    if (filterID === 'status') setStatusFilter('all');
    if (filterID === 'dates') setSelectedDates([null, null]);
  };

  const openFilter = () => {
    setFilterOpen(true);
    setApplyFilter(false);
  };

  const FilterPills = (
    <Box display="flex">
      <ExportToCsv data={journals} />
      <FilterButton filterPills={applyFilter ? filterPills : {}} openFilter={openFilter} removeFilter={removeFilter} />
    </Box>
  );

  return (
    <>
      <FilterDrawer
        isOpen={filterOpen}
        onClose={() => setFilterOpen(false)}
        onApply={() => {
          setApplyFilter(true);
          setFilterOpen(false);
        }}
      >
        <FilterSelect
          header="Transfer Type"
          options={['all', 'pending', 'canceled', 'executed', 'rejected']}
          onSelect={(status) => setStatusFilter(status as JournalStatus)}
          selected={statusFilter}
        />
        <FilterDateSelect header="Updated At" onDateSelect={(st, ed) => setSelectedDates([st, ed])} />
      </FilterDrawer>
      <Header title="Transactions > Journals" />
      {isMobile && FilterPills}
      <Tabs onChange={(idx) => setSelectedTab(idx)}>
        <Flex>
          <TabList>
            <Tab text="Cash" isSelected={selectedTab === 0} />
          </TabList>
          <Spacer />
          {!isMobile && FilterPills}
        </Flex>
        <TabPanels>
          <TabPanel>
            <Table
              isLoading={journalsQuery.isLoading}
              headers={[
                'Journal ID',
                'Entry Type',
                'From Account',
                'To Account',
                'Net Amount',
                'Status',
                'Settle Date',
              ]}
              copyIndexes={[0, 2, 3]}
              rightJustifyIndexes={[4]}
              rowsPerPage={10}
              rows={journals}
              onValueClick={(rowIdx, colIdx) => {
                if ([2, 3].includes(colIdx)) navigate(`/accounts/${journals[rowIdx][colIdx]}`);
              }}
              noContentTitle="No journals available"
              noContentLinkText="Learn more about Journals"
              noContentLinkDestination={APILinks.journals}
            />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </>
  );
};

export default Journals;
