import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { Box, Text, Icon, Tooltip, useColorModeValue, Collapse } from '@chakra-ui/react';
import colors from '../theme/colors';

const Container = styled(Box)`
  padding: 5px;
  display: flex;
  margin: 10px 0px;
  padding: 18px;
  font-size: 16px;
  border-radius: 8px;
  opacity: ${(props) => (props.$active ? 100 : 70)}%;
  font-weight: ${(props) => (props.$active ? 600 : 400)};

  ${() => {
    const color = useColorModeValue('black', 'white');
    return `stroke: ${color}; fill: ${color};`;
  }}

  &:hover {
    cursor: pointer;
    background-color: ${colors.yellow100};
  }
`;

interface SubmenItem {
  name: string;
  path: string;
}

export interface MenuItem {
  name: string;
  path: string;
  icon: React.ElementType;
  submenu?: SubmenItem[];
}

interface Props extends MenuItem {
  expanded: boolean;
}

const MenuElement = (props: Props): React.ReactElement => {
  const location = useLocation();
  const p = location.pathname;

  // wrap in tooltip if menu collapsed
  const wrapper = (children: React.ReactElement) => {
    if (props.expanded) {
      return children;
    }

    return (
      <Tooltip label={props.name} placement="right">
        {children}
      </Tooltip>
    );
  };

  const submenu = (
    <Collapse in={p.includes(props.path) && props.expanded}>
      {props.submenu?.map((item) => (
        <NavLink key={item.name} to={item.path}>
          <Container $active={p === item.path}>
            <Box flex="1" />
            <Text flex="4">{item.name}</Text>
          </Container>
        </NavLink>
      ))}
    </Collapse>
  );

  const menu = wrapper(
    <NavLink to={props.path}>
      <Container $active={p === props.path}>
        <Icon as={props.icon} fontSize="2xl" flex="1" />
        {props.expanded && <Text flex="4">{props.name}</Text>}
      </Container>
    </NavLink>,
  );

  return (
    <>
      {menu}
      {props.submenu && submenu}
    </>
  );
};

export default MenuElement;
