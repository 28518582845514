import React from 'react';
import { createIcon } from '@chakra-ui/icons';

export const AlpacaSVG = createIcon({
  displayName: 'Alpaca',
  viewBox: '0 0 102 218',
  path: [
    <path
      key="1"
      opacity="0.05"
      d="M71.374 218L71.4092 83.5899C102.87 86.2266 101.907 56.6353 101.907 56.6353C101.907 56.6353 90.3523 47.6081 82.4009 35.2755C74.9277 23.6844 54.716 19.8206 54.716 19.8206L59.5303 5.0194C49.2526 2.71502 42.2205 19.8206 42.2205 19.8206V0.0893555C42.2205 0.0893555 33.7215 2.91746 31.3025 6.53739C30.7146 7.41709 30.1897 8.34994 29.721 9.31137C26.1932 16.5481 25.8518 25.4043 25.8518 25.4043C17.0346 31.1652 7.29787 40.4492 4.95022 59.5933C4.95022 71.9769 0.927162 218 0.927162 218H71.374Z"
      fill="#36383D"
    />,
    <ellipse key="2" rx="2.21454" ry="10.1869" transform="matrix(-1 0 0 1 42.1165 10.2761)" fill="#D8D8D8" />,
    <ellipse key="3" rx="0.895161" ry="1.16186" transform="matrix(-1 0 0 1 101.024 56.696)" fill="#D8D8D8" />,
    <ellipse
      key="4"
      rx="7.97234"
      ry="2.21454"
      transform="matrix(-0.325568 0.945519 0.945519 0.325568 57.0316 12.4649)"
      fill="#D8D8D8"
    />,
    <path
      key="5"
      d="M70.8994 50.8838H63.2725C59.5964 50.8838 58.5423 46.1536 58.5423 46.1536H66.6918C70.3768 46.1536 70.8994 50.8838 70.8994 50.8838Z"
      fill="#D8D8D8"
    />,
  ],
});

export const AlpacaDarkSVG = createIcon({
  displayName: 'Alpaca',
  viewBox: '0 0 102 218',
  path: [
    <path
      key="1"
      opacity="0.15"
      d="M71.374 218L71.4092 83.5899C102.87 86.2266 101.907 56.6353 101.907 56.6353C101.907 56.6353 90.3523 47.6081 82.4009 35.2755C74.9277 23.6844 54.716 19.8206 54.716 19.8206L59.5303 5.0194C49.2526 2.71502 42.2205 19.8206 42.2205 19.8206V0.0893555C42.2205 0.0893555 33.7215 2.91746 31.3025 6.53739C30.7146 7.41709 30.1897 8.34994 29.721 9.31137C26.1932 16.5481 25.8518 25.4043 25.8518 25.4043C17.0346 31.1652 7.29787 40.4492 4.95022 59.5933C4.95022 71.9769 0.927162 218 0.927162 218H71.374Z"
      fill="white"
    />,
    <ellipse key="2" rx="2.21454" ry="10.1869" transform="matrix(-1 0 0 1 42.1165 10.2761)" fill="#171719" />,
    <ellipse key="3" rx="0.895161" ry="1.16186" transform="matrix(-1 0 0 1 101.024 56.696)" fill="#171719" />,
    <ellipse
      key="4"
      rx="7.97234"
      ry="2.21454"
      transform="matrix(-0.325568 0.945519 0.945519 0.325568 57.0316 12.4649)"
      fill="#171719"
    />,
    <path
      key="5"
      d="M70.8994 50.8838H63.2725C59.5964 50.8838 58.5423 46.1536 58.5423 46.1536H66.6918C70.3768 46.1536 70.8994 50.8838 70.8994 50.8838Z"
      fill="#171719"
    />,
  ],
});

export const AlpacaIconFilled = createIcon({
  displayName: 'IconFilled',
  viewBox: '0 0 54 54',
  path: (
    <>
      <circle cx="27" cy="27" r="27" fill="#FCD600" />,
      <mask id="mask0" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="54" height="54">
        <circle cx="27" cy="27" r="27" fill="white" />
      </mask>
      <g mask="url(#mask0)">
        <path
          d="M21.3847 54.9507L21.3771 26.0995C14.6241 26.6654 14.8308 20.3136 14.8308 20.3136C14.8308 20.3136 17.311 18.376 19.0177 15.7287C20.6219 13.2407 24.9603 12.4113 24.9603 12.4113L23.9269 9.23426C26.1331 8.73963 27.6425 12.4113 27.6425 12.4113V8.17603C27.6425 8.17603 29.4668 8.78308 29.9861 9.5601C30.1122 9.74893 30.2249 9.94916 30.3255 10.1555C31.0828 11.7089 31.156 13.6099 31.156 13.6099C33.0487 14.8465 35.1387 16.8393 35.6426 20.9486C35.6426 23.6067 36.5061 54.9507 36.5061 54.9507H21.3847Z"
          fill="white"
        />
      </g>
      <ellipse cx="27.6648" cy="10.3626" rx="0.475352" ry="2.18662" fill="#D8D8D8" />
      <ellipse cx="15.0203" cy="20.3263" rx="0.192147" ry="0.249393" fill="#D8D8D8" />
      <ellipse
        cx="24.4631"
        cy="10.8329"
        rx="1.71127"
        ry="0.475352"
        transform="rotate(71 24.4631 10.8329)"
        fill="#D8D8D8"
      />
      <path
        d="M21.4863 19.0786H23.1234C23.9125 19.0786 24.1388 18.0632 24.1388 18.0632H22.3895C21.5985 18.0632 21.4863 19.0786 21.4863 19.0786Z"
        fill="#D8D8D8"
      />
    </>
  ),
});
