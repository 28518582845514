import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Text,
  CloseButton,
  HStack,
  VStack,
  Button,
  Box,
  FormLabel,
  Input,
  Icon,
  Checkbox,
  useColorModeValue,
  FormControl,
  FormErrorMessage,
} from '@chakra-ui/react';
import { createEntityDetails, createControlPerson, setCorrespondentHeader } from '../../api/api';
import { CheckIcon } from '@chakra-ui/icons';
import styled from '@emotion/styled';
import { AlpacaSVG, AlpacaDarkSVG } from '../../icons/Login';
import { alpacaAuthorizeCopy } from './constants';
import BusinessDetails from './BusinessDetails';
import ProductDetails from './ProductDetails';
import FundingDetails from './FundingDetails';
import ControlPersonCheck from './ControlPersonCheck';
import Summary from './Summary';
import { AppContext } from '../../globals/appcontext';

export const GoLiveFormLabel = styled(FormLabel)`
  font-size: 14px;
  margin-left: 12px;
  margin-bottom: 4px;
  color: #ffffff90;
`;

export const GoLivePill = styled(Button)`
  height: 36px;
  width: 137px;
  border-radius: 8px;
  background-color: ${(props) => (props.active ? '#F6D00012' : '#35383d')};
  color: ${(props) => (props.active ? '#F6D000' : '#ffffff95')};
  border-color: ${(props) => (props.active ? '#F6D000' : '')};
  border-width: ${(props) => (props.active ? '1px' : '')};
  :focus {
    outline: 0 !important;
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0) !important;
  }
  :hover {
    color: black;
  }
`;

const GoLiveCircle = styled(Box)`
  && {
    height: 26px;
    min-width: 26px;
    line-height: 26px;
    text-align: center;
    margin-right: 8px;
    border-radius: 100%;
    background-color: #f6d000;
    color: black;
  }
`;

const AlpacaIconBox = styled(Box)`
  @media (max-width: 1525px) {
    display: none;
  }
`;

export interface InputValue {
  value: string;
  invalid: boolean;
}

const AlpacaLeft = () => {
  const svg = useColorModeValue(AlpacaSVG, AlpacaDarkSVG);
  return (
    <AlpacaIconBox>
      <Icon as={svg} fontSize="280px" position="absolute" right="25rem" top="40.25rem" />
    </AlpacaIconBox>
  );
};

const AlpacaRight = () => {
  const svg = useColorModeValue(AlpacaSVG, AlpacaDarkSVG);
  return (
    <AlpacaIconBox>
      <Icon as={svg} fontSize="500px" position="absolute" right="6.5rem" top="26.5rem" transform="scaleX(-1)" />
    </AlpacaIconBox>
  );
};

const GoLiveProgressStep = (step: number, title: string) => {
  return (
    <HStack>
      <GoLiveCircle>
        <Text fontSize="14px" fontWeight="500">
          {step}
        </Text>
      </GoLiveCircle>
      <Text fontSize="16px">{title}</Text>
    </HStack>
  );
};

const GoLiveEllipse = () => {
  return (
    <VStack ml="11px !important">
      <Box h="3px" w="3px" backgroundColor="gray" borderRadius="100%" />
      <Box h="3px" w="3px" backgroundColor="gray" borderRadius="100%" />
      <Box h="3px" w="3px" backgroundColor="gray" borderRadius="100%" />
    </VStack>
  );
};

export const GoLiveInput = (
  text: string,
  placeholder: string,
  setValue: (value: React.SetStateAction<InputValue>) => void,
  // eslint-disable-next-line
  validation = (val: string) => true,
  errorMsg?: string,
): React.ReactElement => {
  // used for validation and formatting
  const [isInvalid, setIsInvalid] = useState(false);

  return (
    <FormControl isInvalid={isInvalid}>
      <GoLiveFormLabel>{text}</GoLiveFormLabel>
      <Input
        variant="filled"
        type={text}
        size="md"
        placeholder={placeholder}
        onChange={(event) => {
          const { value } = event.currentTarget;
          const invalid = !validation(value);
          setIsInvalid(invalid);
          setValue({ value, invalid });
        }}
      />
      {isInvalid && <FormErrorMessage>{errorMsg}</FormErrorMessage>}
    </FormControl>
  );
};

// verify a list of Input Values are all valid and non empty
const inputsAllValid = (inputs: InputValue[]) => {
  return inputs.every((i) => {
    return !i.invalid && i.value !== '';
  });
};

const GoLive = (): React.ReactElement => {
  const [step, setStep] = useState(0);
  const navigate = useNavigate();
  const initialInputValue = { value: '', invalid: false };

  const appContext = useContext(AppContext);
  const sandboxCode = appContext.correspondent.ID;

  // Business Details
  const [legalEntityName, setLegalEntityName] = useState<InputValue>(initialInputValue);
  const [legalEntityType, setLegalEntityType] = useState<InputValue>(initialInputValue);
  const [dateOfIncorporation, setDateOfIncorporation] = useState<InputValue>(initialInputValue);
  const [countryOfRegistration, setCountryOfRegistration] = useState<InputValue>(initialInputValue);
  const [streetAddress, setStreetAddress] = useState<InputValue>(initialInputValue);
  const [city, setCity] = useState<InputValue>(initialInputValue);
  const [state, setState] = useState<InputValue>(initialInputValue);
  const [postalCode, setPostalCode] = useState<InputValue>(initialInputValue);
  const [unitNumber, setUnitNumber] = useState<InputValue>(initialInputValue);
  const [phoneNumber, setPhoneNumber] = useState<InputValue>(initialInputValue);
  const [taxId, setTaxId] = useState<InputValue>(initialInputValue);
  const [taxIdType, setTaxIdType] = useState<InputValue>(initialInputValue);
  const [dbaLegalEntityName, setDbaLegalEntityName] = useState<InputValue>(initialInputValue);
  const [dbaLegalEntityType, setDbaLegalEntityType] = useState<InputValue>(initialInputValue);
  const [dbaDateOfIncorporation, setDbaDateOfIncorporation] = useState<InputValue>(initialInputValue);
  const [dbaCountryOfRegistration, setDbaCountryOfRegistration] = useState<InputValue>(initialInputValue);
  const [dbaStreetAddress, setDbaStreetAddress] = useState<InputValue>(initialInputValue);
  const [dbaRelationship, setDbaRelationship] = useState<InputValue>(initialInputValue);

  // Product Details
  const [productUrl, setProductUrl] = useState<InputValue>(initialInputValue);
  const [productUserbase, setProductUserbase] = useState<string>('');
  const [productReplacingPlatform, setProductReplacingPlatform] = useState<boolean | null>(null);
  const [productAlpacaUse, setProductAlpacaUse] = useState<string>('');

  // Funding Details
  const [fundingPlan, setFundingPlan] = useState<string>('');
  const [fundingFlow, setFundingFlow] = useState<InputValue>(initialInputValue);

  // Control Person
  const [controlFirstName, setControlFirstName] = useState<InputValue>(initialInputValue);
  const [controlLastName, setControlLastName] = useState<InputValue>(initialInputValue);
  const [controlStreetAddress, setControlStreetAddress] = useState<InputValue>(initialInputValue);
  const [controlAptUnit, setControlAptUnit] = useState<InputValue>(initialInputValue);
  const [controlCity, setControlCity] = useState<InputValue>(initialInputValue);
  const [controlState, setControlState] = useState<InputValue>(initialInputValue);
  const [controlPostalCode, setControlPostalCode] = useState<InputValue>(initialInputValue);
  const [controlCountry, setControlCountry] = useState<InputValue>(initialInputValue);
  const [controlPhoneNumber, setControlPhoneNumber] = useState<InputValue>(initialInputValue);
  const [controlEmail, setControlEmail] = useState<InputValue>(initialInputValue);
  const [controlDOB, setControlDOB] = useState<InputValue>(initialInputValue);
  const [controlTaxID, setControlTaxID] = useState<InputValue>(initialInputValue);
  const [controlTaxIDType, setControlTaxIDType] = useState<InputValue>(initialInputValue);

  interface GoLiveStep {
    title: string;
    view: React.ReactElement;
  }

  enum GoLivePage {
    Business = 0,
    Product = 1,
    Funding = 2,
    ControlPerson = 3,
    Summary = 4,
  }

  const steps: GoLiveStep[] = [
    {
      title: 'Business Details',
      view: (
        <BusinessDetails
          handleLegalEntityName={setLegalEntityName}
          handleLegalEntityType={setLegalEntityType}
          handleDateOfIncorporation={setDateOfIncorporation}
          handleCountryOfRegistration={setCountryOfRegistration}
          handleStreetAddress={setStreetAddress}
          handleCity={setCity}
          handleState={setState}
          handlePostalCode={setPostalCode}
          handleUnitNumber={setUnitNumber}
          handlePhoneNumber={setPhoneNumber}
          handleTaxID={setTaxId}
          handleTaxIDType={setTaxIdType}
          handleDbaLegalEntityName={setDbaLegalEntityName}
          handleDbaLegalEntityType={setDbaLegalEntityType}
          handleDbaDateOfIncorporation={setDbaDateOfIncorporation}
          handleDbaCountryOfRegistration={setDbaCountryOfRegistration}
          handleDbaStreetAddress={setDbaStreetAddress}
          handleDbaRelationship={setDbaRelationship}
        />
      ),
    },
    {
      title: 'Product Details',
      view: (
        <ProductDetails
          handleUrl={setProductUrl}
          handleReplacingPlatform={setProductReplacingPlatform}
          handleUserbase={setProductUserbase}
          handleAlpacaUse={setProductAlpacaUse}
          userbase={productUserbase}
          replacingPlatform={productReplacingPlatform}
          alpacaUse={productAlpacaUse}
          productUrl={productUrl.value}
        />
      ),
    },
    {
      title: 'Funding Details',
      view: (
        <FundingDetails
          handleFundingFlow={setFundingFlow}
          handleFundingPlan={setFundingPlan}
          fundingPlan={fundingPlan}
          fundingFlow={fundingFlow.value}
        />
      ),
    },
    {
      title: 'Control Person Check',
      view: (
        <ControlPersonCheck
          handleFirstName={setControlFirstName}
          handleLastName={setControlLastName}
          handleStreetAddress={setControlStreetAddress}
          handleAptUnit={setControlAptUnit}
          handleCity={setControlCity}
          handleState={setControlState}
          handlePostalCode={setControlPostalCode}
          handleCountry={setControlCountry}
          handlePhoneNumber={setControlPhoneNumber}
          handleEmail={setControlEmail}
          handleDOB={setControlDOB}
          handleTaxID={setControlTaxID}
          handleTaxIDType={setControlTaxIDType}
        />
      ),
    },
    { title: 'Summary', view: <Summary /> },
  ];

  const GoLiveProgress = () => {
    return (
      <VStack alignItems="baseline" ml="1em" mt="84px">
        {steps.map((val, idx) => {
          return (
            <>
              {GoLiveProgressStep(idx + 1, val.title)}
              {idx < 4 && GoLiveEllipse()}
            </>
          );
        })}
      </VStack>
    );
  };

  // Required fields for pages (disable next button if any field is empty or invalid)
  const bizDetailsRequired = [
    legalEntityName,
    legalEntityType,
    dateOfIncorporation,
    countryOfRegistration,
    streetAddress,
    city,
    state,
    taxId,
    taxIdType,
    phoneNumber,
  ];

  const controlPersonRequired = [
    controlFirstName,
    controlLastName,
    controlStreetAddress,
    controlCity,
    controlState,
    controlPostalCode,
    controlCountry,
    controlDOB,
    controlEmail,
    controlTaxID,
    controlTaxIDType,
    controlPhoneNumber,
  ];

  const handleDisabledNext = (): boolean => {
    switch (step) {
      case GoLivePage.Business:
        return !inputsAllValid(bizDetailsRequired);
      case GoLivePage.Product:
        return !(
          inputsAllValid([productUrl]) &&
          productUserbase &&
          productAlpacaUse &&
          productReplacingPlatform !== null
        );
      case GoLivePage.Funding:
        return fundingPlan === '';
      default:
        return true;
    }
  };

  // 1. Creates an entry in the Entity Details table in GoBroker
  // 2. Uses the generated entity_id to create a Control Person entry linked to the entity
  // 3. Finally link current correspondent to the given entity and submit request to go live
  const submitRequest = () => {
    const entityReq = {
      entity_legal_name: legalEntityName.value,
      entity_type: legalEntityType.value,
      incorporation_date: dateOfIncorporation.value,
      country_of_registration: countryOfRegistration.value,
      tax_id: taxId.value,
      tax_id_type: taxIdType.value,
      address: streetAddress.value.concat(unitNumber.value),
      city: city.value,
      state: state.value,
      postal_code: postalCode.value,
      phone_number: phoneNumber.value,
      dba_entity_type: dbaLegalEntityType.value,
      dba_legal_entity_name: dbaLegalEntityName.value,
      dba_incorporation_date: dbaDateOfIncorporation.value,
      dba_country_of_registration: dbaCountryOfRegistration.value,
      dba_address: dbaStreetAddress.value,
      dba_relationship: dbaRelationship.value,
      kyb_status: 'SUBMITTED',
    };
    createEntityDetails(entityReq).then((resp) => {
      const entityId = resp.id;
      const controlPersonReq = {
        entity_id: entityId,
        first_name: controlFirstName.value,
        last_name: controlLastName.value,
        street_address: controlStreetAddress.value,
        apartment_unit: controlAptUnit.value,
        city: controlCity.value,
        state: controlState.value,
        postal_code: controlPostalCode.value,
        country: controlCountry.value,
        phone_number: controlPhoneNumber.value,
        email: controlEmail.value,
        dob: controlDOB.value,
        tax_id: controlTaxID.value,
        tax_id_type: controlTaxIDType.value,
        kyc_status: 'SUBMITTED',
      };
      createControlPerson(controlPersonReq).then(() => {
        setCorrespondentHeader(sandboxCode as string, 'sandbox');
        setStep(4);
        localStorage.setItem('go-live-submitted', 'true');
      });
    });
  };

  const SubmitDocuments = (accepted: boolean, setValue: (value: React.SetStateAction<boolean>) => void) => {
    return (
      <VStack>
        <HStack>
          <Checkbox
            isChecked={accepted}
            onChange={() => {
              setValue(!accepted);
            }}
          >
            Accept Terms and Conditions
          </Checkbox>
        </HStack>
        <Button
          w="191px"
          h="36px"
          mb="48px !important"
          mt="22px !important"
          disabled={!accepted || !inputsAllValid(controlPersonRequired)}
          rightIcon={<CheckIcon />}
          onClick={() => submitRequest()}
        >
          Submit Documents
        </Button>
        <Text fontSize="14px" color="grey">
          {alpacaAuthorizeCopy}
        </Text>
      </VStack>
    );
  };

  const [acceptTerms, setAcceptTerms] = useState<boolean>(false);

  let next: JSX.Element;

  switch (step) {
    case GoLivePage.ControlPerson:
      next = SubmitDocuments(acceptTerms, setAcceptTerms);
      break;
    case GoLivePage.Summary:
      next = <Button onClick={() => navigate('/dashboard')}>Return to Dashboard</Button>;
      break;
    default:
      next = (
        <Button w="98px" h="36px" ml="auto" onClick={() => setStep(step + 1)} disabled={handleDisabledNext()}>
          Next
        </Button>
      );
      break;
  }

  return (
    <HStack>
      <Box w="224px" alignSelf="baseline">
        {GoLiveProgress()}
      </Box>
      <Box pt="1.5em" pl="1.5em" pr="2.5em">
        <HStack>
          <Text fontSize="22px" fontWeight="medium" mr="auto">
            {steps[step].title}
          </Text>
          <HStack width="max-content">
            <Text fontSize="16px" fontWeight="medium">
              Close
            </Text>
            <CloseButton onClick={() => navigate('/dashboard')} />
          </HStack>
        </HStack>
        <HStack mt="4rem">
          <Box w="608px">
            {steps[step].view}
            <HStack w="100%" justifyContent="center">
              {next}
            </HStack>
          </Box>
          <Box>
            <AlpacaLeft />
            <AlpacaRight />
          </Box>
        </HStack>
      </Box>
    </HStack>
  );
};

export default GoLive;
