import React from 'react';
import { Button, HStack, Flex, Grid, GridItem, Spacer, Text } from '@chakra-ui/react';
import { Account } from '../../api/types';
import numeral from 'numeral';
import styled from 'styled-components';
import CopyButton from '../general/CopyButton';
import Card from '../general/Card';
import { isMobile } from 'react-device-detect';
import useTradingAccount from './TradingAccount';

const SubHeader = styled(Text)`
  opacity: 90%;
  margin-top: 1rem;
  font-weight: 500;
  font-size: 1.1rem;
`;

const FieldName = styled(Text)`
  opacity: 70%;
  font-size: 1.125rem;
  white-space: nowrap;
`;

const FieldValue = styled(Text)`
  text-align: right;
  white-space: nowrap;
  margin-left: 0.5rem;
`;

const ValueVariant = {
  textAlign: 'left',
  fontWeight: '500',
};

interface OverviewProps {
  account: Account | undefined;
}

const UserOverview = (props: OverviewProps): React.ReactElement => {
  const a = props.account || ({} as Account);
  const tradingAccountResponse = useTradingAccount(a.id);

  const [showMoreIdentity, setShowMoreIdentity] = React.useState(false);
  const [showMoreDisclosures, setShowMoreDisclosures] = React.useState(false);

  // Wait for the trading account to be fetched before rendering
  // If an error does occur fetching the trading account, the rest of the page will load anyway
  if (tradingAccountResponse.isLoading) {
    return <></>;
  }

  const getIdentity = (key: string) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const identity = a.identity as any;
    const value = identity && identity[key];

    // format if number
    if (value && /^-?\d+$/.test(value)) {
      return numeral(value).format('$0,0.00');
    }

    return value;
  };

  const gridWidth = isMobile ? 2 : 6;
  const mr = isMobile ? '' : '2rem';

  return (
    <>
      <HStack>
        <FieldName>Account #:</FieldName>
        <CopyButton name="Account Number" value={a.account_number} lightColor="brand" />
        {!isMobile && (
          <>
            <FieldName>Account ID:</FieldName>
            <CopyButton name="Account ID" value={a.id} lightColor="brand" />
          </>
        )}
      </HStack>
      {isMobile && (
        <HStack>
          <FieldName>Account ID:</FieldName>
          <CopyButton isTruncated name="Account ID" value={a.id} lightColor="brand" />
        </HStack>
      )}
      <Flex>
        <Grid templateColumns={`repeat(${gridWidth}, 1fr)`} gap={8} flex="2.5" mr={mr}>
          <GridItem colSpan={2}>
            <SubHeader>Contact</SubHeader>
            <Flex mt="10px">
              <FieldName>Full Name</FieldName>
              <FieldValue isTruncated flex="1.5" fontSize="lg">
                {`${a.identity?.given_name} ${a.identity?.family_name}`}
              </FieldValue>
            </Flex>
            <Flex mt="10px" justify="space-between" w="104%">
              <FieldName mt="4px">Email Address</FieldName>
              <CopyButton
                name="Email Address"
                value={a.contact?.email_address}
                lightColor="brand"
                isTruncated
                showTooltip
              />
            </Flex>
            <Flex mt="10px">
              <FieldName>Phone Number</FieldName>
              <FieldValue isTruncated flex="1.5" fontSize="lg">
                {a.contact?.phone_number}
              </FieldValue>
            </Flex>
          </GridItem>
          <GridItem colSpan={2}>
            <SubHeader>Address</SubHeader>
            <FieldValue isTruncated style={ValueVariant} fontSize="lg" mt="10px">
              {a.contact?.street_address}
            </FieldValue>
            <FieldValue isTruncated style={ValueVariant} fontSize="lg" mt="10px">
              {`${a.contact?.city}, ${a.contact?.state} ${a.contact?.postal_code}, ${a.identity?.country_of_tax_residence}`}
            </FieldValue>
          </GridItem>
          <GridItem colSpan={2}>
            <Card>
              <Flex pb="10px">
                <FieldValue fontSize="2xl" opacity="60%">
                  Equity
                </FieldValue>
                <Spacer />
                <FieldValue fontSize="2xl">{numeral(tradingAccountResponse.data?.equity).format('$0,0.00')}</FieldValue>
              </Flex>
              <Flex borderBottom="1px solid grey" pb="10px" mb="10px">
                <FieldValue fontSize="2xl" opacity="60%">
                  Last Equity
                </FieldValue>
                <Spacer />
                <FieldValue fontSize="2xl">
                  {numeral(tradingAccountResponse.data?.last_equity).format('$0,0.00')}
                </FieldValue>
              </Flex>
              <Flex mt="10px">
                <FieldValue fontSize="lg" opacity="60%">
                  Approval Status
                </FieldValue>
                <Spacer />
                <FieldValue fontSize="lg" color="success">
                  {a.status}
                </FieldValue>
              </Flex>
              <Flex mt="10px">
                <FieldValue fontSize="lg" opacity="60%">
                  Approved At
                </FieldValue>
                <Spacer />
                <FieldValue fontSize="lg">-</FieldValue>
              </Flex>
            </Card>
          </GridItem>
          <GridItem colSpan={2}>
            <SubHeader>Identity</SubHeader>
            {Object.entries({
              given_name: 'Given Name',
              family_name: 'Family Name',
              date_of_birth: 'Date of Birth',
              tax_id: 'Tax ID',
              tax_id_type: 'Tax ID type',
            }).map((entry) => {
              const value = getIdentity(entry[0]);
              if (value)
                return (
                  <Flex key={entry[0]} mt="10px">
                    <FieldName>{entry[1]}</FieldName>
                    <FieldValue isTruncated flex="1.5" fontSize="lg">
                      {value}
                    </FieldValue>
                  </Flex>
                );
            })}
            {showMoreIdentity && (
              <>
                {Object.entries({
                  country_of_citizenship: 'Country of Citizenship',
                  country_of_birth: 'Country of Birth',
                  country_of_tax_residence: 'Country of Tax Residence',
                  funding_source: 'Funding Source',
                  annual_income_min: 'Annual Income Min',
                  annual_income_max: 'Annual Income Max',
                  liquid_net_worth_min: 'Liquid Net Worth Min',
                  liquid_net_worth_max: 'Liquid Net Worth Max',
                  total_net_worth_min: 'Total Net Worth Min',
                  total_net_worth_max: 'Total Net Worth Max',
                }).map((entry) => {
                  const value = getIdentity(entry[0]);
                  if (value)
                    return (
                      <Flex key={entry[0]} mt="10px">
                        <FieldName>{entry[1]}</FieldName>
                        <FieldValue isTruncated flex="1.5" fontSize="lg">
                          {value}
                        </FieldValue>
                      </Flex>
                    );
                })}
              </>
            )}
            <Flex mt="10px">
              <Button flex="1" variant="ghost" onClick={() => setShowMoreIdentity(!showMoreIdentity)}>
                {showMoreIdentity ? 'Less' : 'Extend'}
              </Button>
            </Flex>
          </GridItem>
          <GridItem colSpan={2}>
            <SubHeader>Employment and Disclosures</SubHeader>
            {a.disclosures?.employment_status && (
              <Flex mt="10px">
                <FieldName>Employment Status</FieldName>
                <FieldValue isTruncated flex="1.5" fontSize="lg">
                  {a.disclosures?.employment_status}
                </FieldValue>
              </Flex>
            )}
            {a.disclosures?.employer_name && (
              <Flex mt="10px">
                <FieldName>Employer Name</FieldName>
                <FieldValue isTruncated flex="1.5" fontSize="lg">
                  {a.disclosures?.employer_name}
                </FieldValue>
              </Flex>
            )}
            {a.disclosures?.employer_address && (
              <Flex mt="10px">
                <FieldName>Employer Address</FieldName>
                <FieldValue isTruncated flex="1.5" fontSize="lg">
                  {a.disclosures?.employer_address}
                </FieldValue>
              </Flex>
            )}
            {a.disclosures?.employment_position && (
              <Flex mt="10px">
                <FieldName>Employment Position</FieldName>
                <FieldValue isTruncated flex="1.5" fontSize="lg">
                  {a.disclosures?.employment_position}
                </FieldValue>
              </Flex>
            )}
            <Flex mt="10px">
              <FieldName>Is Control Person</FieldName>
              <FieldValue flex="1.5" fontSize="lg">
                {a.disclosures?.is_control_person ? 'True' : 'False'}
              </FieldValue>
            </Flex>
            {showMoreDisclosures && (
              <>
                <Flex mt="10px">
                  <FieldName>Is Affiliated With Exch. or FINRA</FieldName>
                  <FieldValue flex="1.5" fontSize="lg">
                    {a.disclosures?.is_affiliated_exchange_or_finra ? 'True' : 'False'}
                  </FieldValue>
                </Flex>
                <Flex mt="10px">
                  <FieldName>Is Politically Exposed</FieldName>
                  <FieldValue flex="1.5" fontSize="lg">
                    {a.disclosures?.is_politically_exposed ? 'True' : 'False'}
                  </FieldValue>
                </Flex>
                <Flex mt="10px">
                  <FieldName>Immediate Family Exposed</FieldName>
                  <FieldValue flex="1.5" fontSize="lg">
                    {a.disclosures?.immediate_family_exposed ? 'True' : 'False'}
                  </FieldValue>
                </Flex>
              </>
            )}
            <Flex mt="10px">
              <Button flex="1" variant="ghost" onClick={() => setShowMoreDisclosures(!showMoreDisclosures)}>
                {showMoreDisclosures ? 'Less' : 'Extend'}
              </Button>
            </Flex>
          </GridItem>
          <GridItem colSpan={2}>
            <SubHeader>Trusted Contact</SubHeader>
            <Flex mt="10px">
              <FieldName>Given Name</FieldName>
              <FieldValue isTruncated flex="1.5" fontSize="lg">
                {a.trusted_contact?.given_name}
              </FieldValue>
            </Flex>
            <Flex mt="10px">
              <FieldName>Family Name</FieldName>
              <FieldValue isTruncated flex="1.5" fontSize="lg">
                {a.trusted_contact?.family_name}
              </FieldValue>
            </Flex>
            {a.trusted_contact?.email_address && (
              <Flex mt="10px">
                <FieldName>Email</FieldName>
                <FieldValue isTruncated flex="1.5" fontSize="lg">
                  {a.trusted_contact?.email_address}
                </FieldValue>
              </Flex>
            )}
            {a.trusted_contact?.phone_number && (
              <Flex mt="10px">
                <FieldName>Phone</FieldName>
                <FieldValue isTruncated flex="1.5" fontSize="lg">
                  {a.trusted_contact?.phone_number}
                </FieldValue>
              </Flex>
            )}
            <Flex mt="10px">
              <FieldName>Address</FieldName>
              <FieldValue isTruncated flex="1.5" fontSize="lg">
                {a.trusted_contact?.street_address ? `${a.trusted_contact?.street_address}\n` : ''}
                {a.trusted_contact?.city ? `${a.trusted_contact?.city} ` : ''}
                {a.trusted_contact?.state ? `${a.trusted_contact?.state} ` : ''}
                {a.trusted_contact?.postal_code ? `${a.trusted_contact?.postal_code}` : ''}
                <br />
                {a.trusted_contact?.country || ''}
              </FieldValue>
            </Flex>
          </GridItem>
        </Grid>
      </Flex>
    </>
  );
};

export default UserOverview;
