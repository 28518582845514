import React, { useState, useContext, useEffect, Suspense } from 'react';
import styled from 'styled-components';
import { Box, Button, Flex, useToast, Grid, GridItem, Select, Input, Text } from '@chakra-ui/react';
import { ArrowForwardIcon } from '@chakra-ui/icons';
import { useNavigate } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import { useDebounce } from 'use-debounce';
import { HotKeys, configure as keyConf } from 'react-hotkeys';

import Header from '../components/layout/Header';
import Card from '../components/general/Card';
import Editor from '../components/general/Editor';
import { brokerAPIRequest } from '../api/api';
import { formatJSON } from '../globals/utils';
import { AppContext } from '../globals/appcontext';

const PageCaption = styled(Box)`
  max-width: 500px;
  margin-bottom: 3rem;
  opacity: 0.6;
`;

const CardCaption = styled(Text)`
  margin-top: 0.5rem;
  margin-bottom: 1.5rem;
  opacity: 0.6;
  font-size: 1.2rem;
`;

const StyledHotKeys = styled(HotKeys)`
  outline: 0;
`;

const methodKey = 'TESTING_METHOD';
const pathKey = 'TESTING_PATH';
const bodyKey = 'TESTING_BODY';
const saveDelay = 1000;

const LiveTesting = (): React.ReactElement => {
  const methods = ['GET', 'POST', 'PUT', 'DELETE', 'PATCH'];
  const toast = useToast();
  const navigate = useNavigate();
  const appContext = useContext(AppContext);

  const [method, setMethod] = useState(localStorage.getItem(methodKey) || methods[0]);
  const [path, setPath] = useState(localStorage.getItem(pathKey) || '');

  const [evokeSubmit, setEvokeSubmit] = useState(false);
  const [requestBody, setRequestBody] = useState(localStorage.getItem(bodyKey) || '{}');
  const [responseBody, setResponseBody] = useState('{}');
  const [showResponse, setShowResponse] = useState(true);

  const [storedMethod] = useDebounce(method, saveDelay);
  const [storedPath] = useDebounce(path, saveDelay);
  const [storedBody] = useDebounce(requestBody, saveDelay);

  useEffect(() => {
    localStorage.setItem(methodKey, storedMethod);
  }, [storedMethod]);

  useEffect(() => {
    localStorage.setItem(pathKey, storedPath);
  }, [storedPath]);

  useEffect(() => {
    localStorage.setItem(bodyKey, storedBody);
  }, [storedBody]);

  useEffect(() => {
    if (!appContext || !appContext.correspondent) return;
    if (appContext.correspondent.Env !== 'sandbox') {
      navigate('/dev');
    }
  }, [appContext]);

  useEffect(() => {
    if (!evokeSubmit) return;
    setEvokeSubmit(false);
    send(requestBody);
  }, [evokeSubmit]);

  const doSend = () => {
    setEvokeSubmit(true);
  };

  // Must hide and show response so 'value' in editor gets refreshed
  const send = async (body: string) => {
    if (!path || path === '') return;
    setShowResponse(false);
    setResponseBody('{}');

    try {
      const resp = await brokerAPIRequest(method, path, body);
      setResponseBody(formatJSON(resp));
    } catch (err) {
      toast({ title: 'Error Performing Request', description: err.message, status: 'error' });
      if (err.response) {
        try {
          setResponseBody(formatJSON(err.response.data));
        } catch {
          setResponseBody(err.response.data);
        }
      }
      setShowResponse(true);
    }
    setShowResponse(true);
  };

  keyConf({
    ignoreEventsCondition: () => false,
  });

  const keyMap = {
    SEND_REQUEST: ['meta+enter', 'ctrl+enter'],
  };

  const keyHandlers = {
    SEND_REQUEST: doSend,
  };

  const loading = <Text>...</Text>;

  return (
    <StyledHotKeys keyMap={keyMap} handlers={keyHandlers}>
      <Box>
        <Header title="API/Devs > Live Testing" />
        <PageCaption>
          We have opened up access to your sandbox environment straight from the dashboard. Now you can pass real
          requests in the box below and it will reflect everywhere outside this dashboard.
        </PageCaption>
        <Flex>
          <Box width="110px" marginRight="1rem">
            <Select fontWeight="500" variant="filled" value={method} onChange={(e) => setMethod(e.currentTarget.value)}>
              {methods.map((m, i) => (
                <option key={i} value={m}>
                  {m}
                </option>
              ))}
            </Select>
          </Box>
          <Input
            mr="1rem"
            fontWeight="500"
            value={path}
            variant="filled"
            placeholder="/accounts/<account_id>/..."
            onChange={(e) => setPath(e.currentTarget.value)}
          />
          <Button onClick={() => doSend()}>
            Send <ArrowForwardIcon />
          </Button>
        </Flex>
        <Grid templateColumns="repeat(7, 1fr)" gap="2.5rem" mt="2rem">
          <GridItem colSpan={isMobile ? 7 : 4}>
            <Card>
              <Text fontSize="2xl" fontWeight="500">
                Request
              </Text>
              <CardCaption>All requests are JSON encoded.</CardCaption>
              <Suspense fallback={loading}>
                <Editor
                  content={requestBody}
                  language="json"
                  showFormat
                  onSubmit={send}
                  onChange={(v) => setRequestBody(v)}
                />
              </Suspense>
            </Card>
          </GridItem>
          <GridItem colSpan={isMobile ? 7 : 3}>
            <Card>
              <Text fontSize="2xl" fontWeight="500">
                Response
              </Text>
              <CardCaption>All responses are JSON encoded.</CardCaption>
              <>
                {showResponse && (
                  <Suspense fallback={loading}>
                    <Editor content={responseBody} language="json" readOnly />
                  </Suspense>
                )}
              </>
            </Card>
          </GridItem>
        </Grid>
      </Box>
    </StyledHotKeys>
  );
};

export default LiveTesting;
