import React, { useState } from 'react';
import { Tabs, TabList, TabPanels, TabPanel } from '@chakra-ui/react';
import { useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import Header from '../components/layout/Header';
import { getAccount } from '../api/api';
import Tab from '../components/general/Tab';
import UserOverview from '../components/user/UserOverview';
import DocumentsTable from '../components/tables/DocumentsTable';
import { isMobile } from 'react-device-detect';
import OrdersTable from '../components/tables/OrdersTable';
import TransactionsTable from '../components/tables/TransactionsTable';
import PositionsTable from '../components/tables/PositionsTable';
import ActivitesTable from '../components/tables/ActivitiesTable';
import { Account } from '../api/types';

const User = (): React.ReactElement => {
  const { accountID } = useParams();
  const [selectedTab, setSelectedTab] = useState(0);
  const { data: account } = useQuery(['account', accountID], () => getAccount(accountID));

  return (
    <>
      <Header title={`Accounts > ${account?.account_number || ''}`} />
      <Tabs onChange={(idx) => setSelectedTab(idx)} mt="3rem">
        <TabList>
          <Tab text="Overview" isSelected={selectedTab === 0} />
          <Tab text="Documents" isSelected={selectedTab === 1} />
          <Tab text="Funding" isSelected={selectedTab === 2} />
          {!isMobile && (
            <>
              <Tab text="Positions" isSelected={selectedTab === 3} />
              <Tab text="Orders" isSelected={selectedTab === 4} />
              <Tab text="Activities" isSelected={selectedTab === 5} />
            </>
          )}
        </TabList>
        {isMobile && (
          <TabList>
            <Tab text="Positions" isSelected={selectedTab === 3} />
            <Tab text="Orders" isSelected={selectedTab === 4} />
            <Tab text="Activities" isSelected={selectedTab === 5} />
          </TabList>
        )}
        <TabPanels>
          <TabPanel>
            <UserOverview
              account={
                // Assign the accountID since it's already known
                // The child component will use the accountID to fetch the trading account data
                { ...account, id: accountID } as Account
              }
            />
          </TabPanel>
          <TabPanel>
            <DocumentsTable accountID={accountID} />
          </TabPanel>
          <TabPanel>
            <TransactionsTable accountID={accountID} />
          </TabPanel>
          <TabPanel>
            <PositionsTable accountID={accountID} />
          </TabPanel>
          <TabPanel>
            <OrdersTable accountID={accountID} />
          </TabPanel>
          <TabPanel>
            <ActivitesTable accountID={accountID} nonTradeOnly />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </>
  );
};

export default User;
