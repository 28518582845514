import React, { useContext, useEffect } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { Flex, useColorModeValue } from '@chakra-ui/react';

import LeftMenu from './LeftMenu';
import { AuthService } from '../../auth/authServices';
import { AppContext } from '../../globals/appcontext';
import { Scrollbox } from '../general/ScrollBox';
import { Loader } from '../general/Loader';
import { setAuthHeader } from '../../api/api';
import { locationUpdate } from '../../globals/analytics';

const Content = styled(Scrollbox)`
  padding: 1rem;
  flex: 1;
  max-height: 100vh;
  overflow-y: scroll;
`;

interface LayoutProps {
  children: React.ReactElement;
}

const Layout = (props: LayoutProps): React.ReactElement => {
  const appContext = useContext(AppContext);
  const colorMode = useColorModeValue('light', 'dark');
  const location = useLocation();

  useEffect(() => {
    // Load the current user on mount to ensure they are authenticated.
    setAuthHeader().then(() => {
      appContext.setIsLoading(true);
      AuthService.getCurrentUser().then((user) => {
        if (!user) {
          appContext.setIsAuthenticated(false);
          appContext.setIsLoading(false);
        } else {
          appContext.setIsAuthenticated(true);
          appContext.setCognitoUser(user);
        }
      });
    });
  }, []);

  useEffect(() => locationUpdate(location), [location]);

  if (appContext.isLoading) {
    return <Loader />;
  }

  if (!appContext.isLoading && !appContext.authenticated) {
    return <Navigate to="/login" />;
  }

  if (!appContext.correspondent.ID && location.pathname !== '/onboarding') {
    return <Navigate to="/onboarding" />;
  }

  return (
    <Flex>
      <LeftMenu />
      <Content colormode={colorMode}>{props.children}</Content>
    </Flex>
  );
};

export default Layout;
