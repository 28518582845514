import React from 'react';
import {
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerHeader,
  DrawerBody,
  DrawerFooter,
  Button,
  Spacer,
  useColorModeValue,
} from '@chakra-ui/react';

interface FilterProps {
  children: React.ReactElement | React.ReactElement[];
  onApply?: () => void;
  onClose: () => void;
  isOpen: boolean;
  title?: string;
}

const FilterDrawer = (props: FilterProps): React.ReactElement => {
  const footerProps = useColorModeValue({ borderTop: '1px solid lightgray' }, { bg: 'gray.600' });

  return (
    <Drawer onClose={props.onClose} isOpen={props.isOpen} size="md">
      {/* z-index of zendesk widget is set to 999998 -_- */}
      <DrawerOverlay zIndex={999999}>
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>{props.title || 'Filter'}</DrawerHeader>
          <DrawerBody>{props.children}</DrawerBody>
          <DrawerFooter {...footerProps}>
            <Button colorScheme="grey" variant="ghost" onClick={props.onClose}>
              Cancel
            </Button>
            <Spacer />
            <Button
              variant="ghost"
              onClick={() => {
                if (props.onApply) props.onApply();
              }}
            >
              Apply
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </DrawerOverlay>
    </Drawer>
  );
};

export default FilterDrawer;
