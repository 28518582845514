import React from 'react';
import {
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerHeader,
  DrawerBody,
  Text,
  Flex,
  Spacer,
  Divider,
} from '@chakra-ui/react';
import { Transfer } from '../../api/types';
import styled from 'styled-components';
import CopyButton from '../general/CopyButton';
import moment from 'moment';
import numeral from 'numeral';
import { capitalize } from '../../globals/utils';
import { CheckIcon, CloseIcon } from '@chakra-ui/icons';

const DetailLabel = styled(Text)`
  opacity: 60%;
  font-weight: 600;
  margin: auto;
  min-width: 100px;
`;

interface TransferDetailsProps {
  isOpen: boolean;
  onClose: () => void;
  transfer: Transfer | undefined;
}

const DetailRow = (props: { label: string; children: React.ReactElement }) => (
  <Flex>
    <DetailLabel>{props.label}</DetailLabel>
    <Spacer />
    {props.children}
  </Flex>
);

const TransferDetails = (props: TransferDetailsProps): React.ReactElement => {
  const t = props.transfer;

  const copyDetail = (label: string, value: string | undefined) => (
    <DetailRow label={label}>
      <CopyButton isTruncated iconSide="left" value={value || ''} />
    </DetailRow>
  );

  const transferDetail = (label: string, value: string | undefined) => (
    <DetailRow label={label}>
      <Text color={getTextColor(value)} lineHeight="40px" fontWeight={600}>
        {getStatusIcon(value)}
        {value ? capitalize(value) : '-'}
      </Text>
    </DetailRow>
  );

  const dateDetail = (label: string, date: moment.Moment | undefined) => (
    <DetailRow label={label}>
      <>
        <Text fontSize="12px" lineHeight="40px" fontWeight={600} opacity="60%" mr="5px">
          {!date ? '' : date.format('hh:mm a')}
        </Text>
        <Text lineHeight="40px" fontWeight={600}>
          {!date ? '-' : date.format('MM.DD.YYYY')}
        </Text>
      </>
    </DetailRow>
  );

  const getTextColor = (text: string | undefined): string => {
    text = text?.toLowerCase() || '';
    if (['complete', 'incoming', 'approved'].includes(text)) return 'success';
    if (['outgoing', 'rejected', 'canceled'].includes(text)) return 'success';
    return '';
  };

  const getStatusIcon = (status: string | undefined) => {
    status = status?.toLowerCase() || '';
    if (['complete'].includes(status)) return <CheckIcon mr="5px" />;
    if (['canceled', 'rejected'].includes(status)) return <CloseIcon mr="5px" />;
    return null;
  };

  return (
    <Drawer isOpen={props.isOpen} placement="right" size="md" onClose={props.onClose}>
      <DrawerOverlay>
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>Transaction details</DrawerHeader>
          <DrawerBody>
            {copyDetail('Transaction ID', t?.id)}
            {copyDetail('Account ID', t?.account_id)}
            <Divider borderTopWidth="2px" m="20px 0px" />
            {transferDetail('Type', t?.type)}
            {transferDetail('Status', t?.status)}
            <DetailRow label="Amount">
              <Text lineHeight="40px" fontWeight={600}>
                {numeral(t?.amount).format('$0,0.00')}
              </Text>
            </DetailRow>
            {transferDetail('Direction', t?.direction)}
            <Divider borderTopWidth="2px" m="20px 0px" />
            {dateDetail('Created At', t?.created_at)}
            {dateDetail('Updated At', t?.updated_at)}
            {dateDetail('Expires At', t?.expires_at)}
            {t?.additional_information && (
              <Flex mt="10px">
                <Text flex="1" fontWeight="600" margin="auto">
                  Additional Info
                </Text>
                <Text flex="2" opacity="60%">
                  {t?.additional_information}
                </Text>
              </Flex>
            )}
          </DrawerBody>
        </DrawerContent>
      </DrawerOverlay>
    </Drawer>
  );
};

export default TransferDetails;
