import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Tabs, TabList, TabPanels, TabPanel, Flex, Spacer, useToast, Input } from '@chakra-ui/react';
import Table from '../components/general/Table';
import Header from '../components/layout/Header';
import Tab from '../components/general/Tab';
import AddUser from '../components/user/AddUser';
import { getAccounts, getTradingAccount } from '../api/api';
import { Account, AccountsParams } from '../api/types';
import { useQueries, useQuery } from 'react-query';
import {
  FilterDrawer,
  FilterDateSelect,
  FilterSelect,
  FilterButton,
  RangeType,
  dateRangeToPill,
} from '../components/filter';
import ExportToCsv from '../components/tables/ExportButton';
import { capitalize, cashFormat, mapAccountsToRowsInPlace, mapAccountTableRowsToTableValues } from '../globals/utils';
import { APILinks } from '../globals/consts';
import { AccountTableRow, UseQueryOptionsTradingAccount, UseQueryOptionsUnknown } from '../globals/types';

const inactiveAccounts = ['SUBMITTED', 'ACTION_REQUIRED', 'APPROVAL_PENDING', 'APPROVED', 'DISABLED', 'ACCOUNT_CLOSED'];
const statusOptions = ['ALL', 'ACTIVE', 'ACCOUNT_UPDATED', ...inactiveAccounts];

const Users = (): React.ReactElement => {
  const toast = useToast();
  const navigate = useNavigate();

  const [selectedTab, setSelectedTab] = useState(0);
  const [addUserOpen, setAddUserOpen] = useState(false);

  // Filters
  const [filterOpen, setFilterOpen] = useState(false);
  const [statusFilter, setStatusFilter] = useState('All');
  const [selectedDates, setSelectedDates] = useState<RangeType>([null, null]);
  const [appliedFilters, setAppliedFilters] = useState<AccountsParams>({});
  const [applyFilter, setApplyFilter] = useState(false);
  const [page, setPage] = useState<number>(0);

  let rows: Record<string, AccountTableRow> = {};

  const rowsPerPage = 15;

  // Fetch brokerage accounts
  const brokerAccountsResponse = useQuery<Account[], unknown>(
    ['accounts', appliedFilters],
    () => getAccounts(appliedFilters),
    {
      // We use select to repopulate the rows in-case the component has re-rendered
      select: (data) => {
        // If rows have changed
        if (Object.keys(rows).length !== data.length) {
          mapAccountsToRowsInPlace(data, rows);
        }

        return data;
      },
    },
  );

  // Keep either the accounts or an empty array
  const brokerageAccounts = brokerAccountsResponse.data ?? [];

  // Fetch trading accounts
  // NOTE: Providing type args doesn't seem to work with useQueries
  const tradingAccountsResponse = useQueries(
    brokerageAccounts
      ?.filter(
        (_, index) =>
          // If index is greater than or equal to page start index
          index >= (page - 1) * rowsPerPage &&
          // If index is less than page end index
          index < page * rowsPerPage,
      )
      .map<UseQueryOptionsTradingAccount>((account) => ({
        queryKey: ['trading-account', account.id],
        queryFn: () => getTradingAccount(account.id),
        // We use select to reapply the equities in-case the component has re-rendered
        select: (data) => {
          // Update equity value for account
          if (data.id in rows) {
            rows[data.id].equity = cashFormat(data.equity);
          }

          return data;
        },
      })) as UseQueryOptionsUnknown,
  );

  const tradingAccountResponseErrored = tradingAccountsResponse.some((r) => r.isError);

  useEffect(() => {
    if (!applyFilter) {
      return;
    }

    const params: AccountsParams = { status: statusFilter };

    // date filter
    if (selectedDates[0] && selectedDates[1]) {
      params.updated_after = selectedDates[0].toISOString();
      params.updated_before = selectedDates[1].toISOString();
    }

    setAppliedFilters(params);

    // Purge rows now that filters have been applied
    rows = {};
  }, [statusFilter, applyFilter, selectedDates]);

  useEffect(() => {
    if (brokerAccountsResponse.isError) {
      toast({
        title: 'An error occurred fetching brokerage accounts',
        description: (brokerAccountsResponse.error as Error).message,
        status: 'error',
      });
    }

    if (tradingAccountResponseErrored) {
      toast({
        title: 'An error occurred fetching trading accounts',
        description: (tradingAccountsResponse.find((r) => r.isError)?.error as Error).message,
        status: 'error',
      });
    }
  }, [brokerAccountsResponse.isError, tradingAccountResponseErrored]);

  // We need to work with just the accounts
  const rowsAll = Object.values(rows);

  // Filter based on activity
  const rowsActive = useMemo(() => rowsAll.filter((user) => user.status.toUpperCase() === 'ACTIVE'), [rows]);
  const rowsInactive = useMemo(() => rowsAll.filter((user) => inactiveAccounts.includes(user.status.toUpperCase())), [
    rows,
  ]);

  const filterPills = {
    status: capitalize(statusFilter),
    dates: dateRangeToPill(selectedDates),
  };

  const removeFilter = (filterID: string) => {
    if (filterID === 'status') {
      setStatusFilter('All');
    }
    if (filterID === 'dates') {
      setSelectedDates([null, null]);
    }
  };

  // Update the page to trigger a new fetch of accounts
  const onPage = (page: number) => setPage(page);

  const getTablePanel = (rows: AccountTableRow[]) => (
    <TabPanel>
      <Table
        // Remain loading until all queries are complete
        isLoading={brokerAccountsResponse.isLoading || tradingAccountsResponse.some((q) => q.isLoading)}
        onRowClick={(rowIndex) => navigate(`/accounts/${rows[rowIndex]?.id}`)}
        headers={['Name', 'Email', 'Account ID', 'Equity', 'Status', 'Created At']}
        copyIndexes={[2]}
        rowsPerPage={rowsPerPage}
        rightJustifyIndexes={[3]}
        rows={mapAccountTableRowsToTableValues(rows)}
        totalRows={rows.length}
        noContentTitle="No accounts available"
        noContentLinkText="Learn more about Accounts"
        noContentLinkDestination={APILinks.accounts}
        onPage={onPage}
      />
    </TabPanel>
  );

  return (
    <>
      <Header title="Accounts" />
      <FilterDrawer
        isOpen={filterOpen}
        onClose={() => setFilterOpen(false)}
        onApply={() => {
          setApplyFilter(true);
          setFilterOpen(false);
        }}
      >
        <FilterSelect header="Status" options={statusOptions} onSelect={setStatusFilter} selected={statusFilter} />
        <FilterDateSelect header="Updated At" onDateSelect={(st, ed) => setSelectedDates([st, ed])} />
      </FilterDrawer>
      <AddUser isOpen={addUserOpen} onClose={() => setAddUserOpen(false)}>
        <Input variant="filled" type="email" placeholder="Email" />
      </AddUser>
      <Tabs onChange={(idx) => setSelectedTab(idx)} mt="3rem">
        <Flex>
          <TabList>
            <Tab text="All Users" isSelected={selectedTab === 0} />
            <Tab text="Active Users" isSelected={selectedTab === 1} />
            <Tab text="Inactive Users" isSelected={selectedTab === 2} />
          </TabList>
          <Spacer />

          <ExportToCsv data={brokerageAccounts} />
          <FilterButton
            filterPills={applyFilter ? filterPills : {}}
            openFilter={() => {
              setFilterOpen(true);
              setApplyFilter(false);
            }}
            removeFilter={(filterID) => removeFilter(filterID)}
          />
        </Flex>
        <TabPanels>
          {getTablePanel(rowsAll)}
          {getTablePanel(rowsActive)}
          {getTablePanel(rowsInactive)}
        </TabPanels>
      </Tabs>
    </>
  );
};

export default Users;
