const colors = {
  bgLight: 'rgb(244,246,251)',
  bgDark: '#171719',
  cardBackgroundDark: '#24262C',
  cardBackgroundLight: 'white',
  gray: 'gray',
  nestedBGDark: '#35383D',
  nestedBGLight: '#F1F2F5',
  yellow: 'rgba(239, 202, 0, 1)',
  yellow100: 'rgba(239, 202, 0, 0.1)',
  lightGreen: 'rgba(0, 223, 89, 0.8)',
  lightBlue: 'rgba(0, 108, 203, 0.7)',
  lightOrange: '#E18905',
  pieChart: ['#1473C6', '#CCE2F5', '#D600C0', '#885FFE', '#21CFCF'],
};

export default colors;
