import { useState, useEffect } from 'react';
import { isMobileOnly } from 'react-device-detect';

type WindowDim = 'small' | 'medium' | 'large' | 'mobile';

const getWindowSize = (): WindowDim => {
  const { innerWidth: width } = window;

  if (isMobileOnly) {
    return 'mobile';
  }

  if (width < 1250) {
    return 'small';
  } else if (width < 1800) {
    return 'medium';
  }

  return 'large';
};

const useWindowSize = (): WindowDim => {
  const [windowSize, setWindowSize] = useState(getWindowSize());

  useEffect(() => {
    window.addEventListener('resize', () => setWindowSize(getWindowSize()));
  }, []);

  return windowSize;
};

export default useWindowSize;
