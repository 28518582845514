import React, { Dispatch, SetStateAction, useState } from 'react';
import { Box, Text, HStack, FormControl } from '@chakra-ui/react';
import FileUpload from '../../components/general/FileUpload';
import { GoLivePill, GoLiveInput, InputValue } from './GoLive';
import { CloseIcon } from '@chakra-ui/icons';

interface FundingDetailsProps {
  handleFundingPlan: Dispatch<SetStateAction<string>>;
  handleFundingFlow: Dispatch<SetStateAction<InputValue>>;
  fundingPlan: string;
  fundingFlow: string;
}

const FundingDetails = (props: FundingDetailsProps): React.ReactElement => {
  const [fileList, setFileList] = useState<File[]>([]);
  const onDrop = (acceptedFiles: File[]) => {
    setFileList(acceptedFiles);
  };

  return (
    <Box w="100%" mb="44px">
      <Text fontSize="16px" mb="16px">
        How do you plan on funding your users&apos; accounts?
      </Text>
      <HStack w="100%">
        <GoLivePill onClick={() => props.handleFundingPlan('Wire')} active={props.fundingPlan === 'Wire'}>
          <Text fontWeight="medium" fontSize="15px">
            Wire
          </Text>
        </GoLivePill>
        <GoLivePill
          onClick={() => props.handleFundingPlan('ACH')}
          active={props.fundingPlan === 'ACH'}
          ml="20px !important"
        >
          <Text fontWeight="medium" fontSize="15px">
            ACH (US Only)
          </Text>
        </GoLivePill>
        <GoLivePill
          onClick={() => props.handleFundingPlan('Other')}
          active={props.fundingPlan === 'Other'}
          ml="20px !important"
        >
          <Text fontWeight="medium" fontSize="15px">
            Other
          </Text>
        </GoLivePill>
      </HStack>
      <FormControl w="100%" mt="20px">
        {GoLiveInput('', 'Please describe', props.handleFundingFlow)}
      </FormControl>
      <Text fontSize="16px" mb="24px" mt="46px">
        Upload flow of funds diagram (optional)
      </Text>
      <Box>
        <FileUpload onDrop={onDrop} />
        <Box display="flex" flexDirection="column">
          {fileList.map((entry, idx) => (
            <Box key={entry.name} mt="1rem" display="flex" alignItems="center">
              <Box _hover={{ cursor: 'pointer' }}>
                <CloseIcon
                  mr="1rem"
                  onClick={() => {
                    fileList.splice(idx, 1);
                    setFileList([...fileList]);
                  }}
                />
              </Box>
              <Text fontSize="1rem">{entry.name}</Text>
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default FundingDetails;
