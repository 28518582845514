import React, { useEffect, useState } from 'react';
import { useQuery, useQueryClient, useMutation } from 'react-query';
import {
  Button,
  Flex,
  FormLabel,
  Input,
  Select,
  Spacer,
  Text,
  useColorModeValue,
  useToast,
  VStack,
} from '@chakra-ui/react';

import Table from '../components/general/Table';
import Header from '../components/layout/Header';
import { getSelf, getTeamMembers, editTeamMember, removeTeamMember, getCorrespondent } from '../api/api';
import { Role, RoleNames } from '../api/types';
import NewUserForm from '../components/user/NewUser';
import DashModal from '../components/general/Modal';
import { AddIcon } from '@chakra-ui/icons';
import { isMobile } from 'react-device-detect';

const TeamSettingsPage = (): React.ReactElement => {
  const queryClient = useQueryClient();
  const corrQuery = useQuery('correspondent', getCorrespondent);

  const { isLoading, isError, data, error } = useQuery('users', () => getTeamMembers());
  const { data: authUser } = useQuery('self', () => getSelf());
  const [teamMembers, setTeamMembers] = useState(data);
  const [newUserOpen, setNewUserOpen] = useState(false);
  const textColor = useColorModeValue('black', 'white');
  const toast = useToast();

  const [editMemberOpen, setEditMemberOpen] = useState(false);
  const [editID, setEditID] = useState('');
  const [editName, setEditName] = useState('');
  const [editEmail, setEditEmail] = useState('');
  const [editRole, setEditRole] = useState('developer');

  const newUserClose = () => {
    setNewUserOpen(false);
    queryClient.invalidateQueries('users');
  };

  useEffect(() => {
    setTeamMembers(data);
  }, [data]);

  useEffect(() => {
    if (isError)
      toast({
        title: 'An error occurred fetching team members',
        description: (error as Error).message,
        status: 'error',
      });
  }, [isError]);

  const deleteTeamMember = (index: number) => {
    if (!teamMembers) return;
    const id = teamMembers[index].id;
    removeTeamMember(id)
      .then(() => {
        setTeamMembers(teamMembers.filter((m) => m.id !== id));
      })
      .catch((err) => {
        toast({
          title: 'Delete failed',
          duration: 5000,
          description: err.message,
          status: 'error',
        });
      });
  };

  const canEditRole = (role: string) => {
    if (!authUser || !authUser.role) return false;
    return authUser.role === Role.Superuser || (authUser.role === Role.Developer && role !== Role.Superuser);
  };

  const openEditMember = (i: number) => {
    if (!teamMembers || !teamMembers[i]) return;
    if (!authUser || authUser.role === Role.Operations) return;
    const m = teamMembers[i];
    setEditRole(m.role || Role.Developer);
    setEditEmail(m.email);
    setEditName(m.name || '');
    setEditID(m.id);
    setEditMemberOpen(true);
  };

  const submitQuery = useMutation(() => editTeamMember(editID, editName, editRole), {
    mutationKey: editID,
    onSuccess: (member) => {
      toast({
        title: 'Team Member Updated',
        status: 'success',
      });

      setEditMemberOpen(false);
      // TODO: This results in a shuffle of the table which would be confusing
      // with pagination.
      setTeamMembers((arr) => [...(arr || []).filter((m) => m.id !== member.id), member]);
    },
    onError: (err: Error) => {
      toast({
        title: 'Update failed',
        duration: 5000,
        description: err.message,
        status: 'error',
      });
    },
  });

  const corName = corrQuery.data?.name || '';

  return (
    <>
      <DashModal
        isOpen={editMemberOpen}
        onClose={() => setEditMemberOpen(false)}
        onSubmit={() => submitQuery.mutate()}
        loading={submitQuery.isLoading}
        title="Edit Team Member"
        closeBtn="Cancel"
        submitBtn="Save"
      >
        <FormLabel>Email</FormLabel>
        <Input disabled variant="filled" type="email" placeholder="Email" value={editEmail} mb="20px" />
        <FormLabel>Role</FormLabel>
        <Select
          disabled={(authUser || {}).id === editID || !canEditRole(editRole)}
          variant="filled"
          onChange={(event) => setEditRole(event.currentTarget.value)}
          value={editRole}
          placeholder="Role..."
        >
          {Object.keys(RoleNames).map((r, i) => (
            <option disabled={!canEditRole(r)} value={r} key={i}>
              {RoleNames[r]}
            </option>
          ))}
        </Select>
      </DashModal>
      <NewUserForm isOpen={newUserOpen} onClose={() => newUserClose()} />
      <Header title="Settings" />
      <Flex m="2rem 0rem">
        {isMobile ? (
          <VStack>
            <Text fontSize="xl">{corName}</Text>
            <Button leftIcon={<AddIcon />} variant="ghost" onClick={() => setNewUserOpen(true)} color={textColor}>
              Add Team Member
            </Button>
          </VStack>
        ) : (
          <>
            <Text fontSize="3xl">{corName}</Text>
            <Spacer />
            <Button
              leftIcon={<AddIcon />}
              variant="ghost"
              mr="6rem"
              onClick={() => setNewUserOpen(true)}
              color={textColor}
            >
              Add Team Member
            </Button>
          </>
        )}
      </Flex>
      <Table
        buttonSkipClick
        copyIndexes={[1]}
        isLoading={isLoading}
        headers={['Name', 'Email', 'Role']}
        rowsPerPage={10}
        rows={(teamMembers || []).map((m) => [m.name || '', m.email, m.role || ''])}
        onDelete={(index) => deleteTeamMember(index)}
        onRowClick={(index) => openEditMember(index)}
      />
    </>
  );
};

export default TeamSettingsPage;
