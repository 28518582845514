import React, { useState } from 'react';
import { ChevronDownIcon } from '@chakra-ui/icons';
import { Menu, MenuButton, Select, MenuList, MenuItem } from '@chakra-ui/react';
import moment from 'moment';
import DateRangePicker from '../general/DatePicker';
import FilterHeader from './FormHeader';

interface FilterDateSelectProps {
  header: string;
  onDateSelect: (startDate: moment.Moment | null, endDate: moment.Moment | null) => void;
  disabled?: boolean;
}

const FilterDateSelect = (props: FilterDateSelectProps): React.ReactElement => {
  const [showCalInput, setShowCalInput] = useState(false);
  const [selected, setSelected] = useState('Anytime');

  const windowSelect = (window: string) => {
    const units = window.split('-');
    props.onDateSelect(moment().subtract(units[0], units[1] as 'days' | 'hours'), moment());
    setSelected(units.join(' '));
  };

  const rangeSelect = (st: Date, ed: Date) => {
    props.onDateSelect(moment(st), moment(ed));
  };

  return (
    <>
      <FilterHeader value={props.header} />
      <Menu>
        <MenuButton
          as={Select}
          variant="filled"
          rightIcon={<ChevronDownIcon />}
          width="100%"
          placeholder={selected}
          disabled={!!props.disabled}
        />
        <MenuList>
          <MenuItem
            onClick={() => {
              props.onDateSelect(null, null);
              setSelected('Anytime');
            }}
          >
            Anytime
          </MenuItem>
          <MenuItem onClick={() => windowSelect('24-hours')}>Last 24 Hours</MenuItem>
          <MenuItem onClick={() => windowSelect('7-days')}>Last 7 Days</MenuItem>
          <MenuItem onClick={() => windowSelect('14-days')}>Last 14 Days</MenuItem>
          <MenuItem onClick={() => windowSelect('30-days')}>Last 30 Days</MenuItem>
          <MenuItem
            onClick={() => {
              setShowCalInput(true);
              setSelected('Range');
            }}
          >
            Select Range
          </MenuItem>
        </MenuList>
      </Menu>
      {showCalInput && <DateRangePicker onChange={rangeSelect} />}
    </>
  );
};

export default FilterDateSelect;
