import React from 'react';

import { Image, useColorModeValue } from '@chakra-ui/react';

import emptyListLight from '../../assets/images/emptyListLight.svg';
import emptyListDark from '../../assets/images/emptyListDark.svg';

const EmptyListImage = (): React.ReactElement => {
  const src = useColorModeValue(emptyListLight, emptyListDark);
  return <Image src={src} alt="empty table contents" />;
};

export default EmptyListImage;
