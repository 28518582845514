import React, { useState, Dispatch, SetStateAction } from 'react';
import { Box, HStack, VStack, FormControl, Text } from '@chakra-ui/react';
import { GoLiveInput, GoLivePill, InputValue } from './GoLive';
import { foreignEntityCopy, formHalfWidth, formThirdWidth, dateRegex, phoneNumberRegex } from './constants';

type BusinessAction = Dispatch<SetStateAction<InputValue>>;

interface BusinessDetailsProps {
  handleLegalEntityName: BusinessAction;
  handleLegalEntityType: BusinessAction;
  handleDateOfIncorporation: BusinessAction;
  handleCountryOfRegistration: BusinessAction;
  handleStreetAddress: BusinessAction;
  handleCity: BusinessAction;
  handleState: BusinessAction;
  handleUnitNumber: BusinessAction;
  handlePostalCode: BusinessAction;
  handlePhoneNumber: BusinessAction;
  handleTaxID: BusinessAction;
  handleTaxIDType: BusinessAction;
  handleDbaLegalEntityName: BusinessAction;
  handleDbaLegalEntityType: BusinessAction;
  handleDbaDateOfIncorporation: BusinessAction;
  handleDbaCountryOfRegistration: BusinessAction;
  handleDbaStreetAddress: BusinessAction;
  handleDbaRelationship: BusinessAction;
}

const BusinessDetails = (props: BusinessDetailsProps): React.ReactElement => {
  const [registeredAdvisor, setRegisteredAdvisor] = useState<boolean | null>(null);
  const [foreignEntity, setForeignEntity] = useState<boolean | null>(null);

  return (
    <Box w="100%" mb="44px">
      <HStack justifyContent="space-between">
        <FormControl w={formHalfWidth}>
          {GoLiveInput('Legal Entity Name', 'eg: Beeple Ltd.', props.handleLegalEntityName)}
        </FormControl>
        <FormControl w={formHalfWidth}>
          {GoLiveInput('Legal Entity Type', 'eg: LLC, Inc, Corp', props.handleLegalEntityType)}
        </FormControl>
      </HStack>
      <HStack justifyContent="space-between" mt="1.5rem" alignItems="normal">
        <FormControl w={formHalfWidth}>
          {GoLiveInput(
            'Date of Incorporation',
            'eg: 2002-01-01',
            props.handleDateOfIncorporation,
            (val: string) => dateRegex.test(val),
            'Date must be in YYYY-MM-DD format',
          )}
        </FormControl>
        <FormControl w={formHalfWidth}>
          {GoLiveInput('Country of registration', 'eg: United States', props.handleCountryOfRegistration)}
        </FormControl>
      </HStack>
      <HStack justifyContent="space-between" mt="1.5rem">
        <FormControl w={formHalfWidth}>
          {GoLiveInput('Legal street address', 'Address Line', props.handleStreetAddress)}
        </FormControl>
        <FormControl w={formHalfWidth}>
          {GoLiveInput('Apartment/Unit #', 'Additional info', props.handleUnitNumber)}
        </FormControl>
      </HStack>
      <HStack justifyContent="space-between" mt="1.5rem">
        <FormControl w={formThirdWidth}>{GoLiveInput('City', 'eg: San Jose', props.handleCity)}</FormControl>
        <FormControl w={formThirdWidth}>
          {GoLiveInput('State/Province', 'eg: California', props.handleState)}
        </FormControl>
        <FormControl w={formThirdWidth}>{GoLiveInput('Postal Code', 'eg: 94401', props.handlePostalCode)}</FormControl>
      </HStack>
      <HStack justifyContent="space-between" mt="1.5rem" alignItems="normal">
        <FormControl w={formThirdWidth}>
          {GoLiveInput(
            'Business phone number',
            '+1 123 4567',
            props.handlePhoneNumber,
            (val: string) => phoneNumberRegex.test(val),
            'Invalid phone number format',
          )}
        </FormControl>
        <FormControl w={formThirdWidth}>{GoLiveInput('Tax ID', 'eg: xx-xxxxxxx', props.handleTaxID)}</FormControl>
        <FormControl w={formThirdWidth}>
          {GoLiveInput('Tax ID type', 'eg: USA - SSN', props.handleTaxIDType)}
        </FormControl>
      </HStack>
      <VStack mt="1.5rem" alignItems="start">
        <Text fontSize="16px">
          Are you registered or licensed as a financial services provider in your local jurisdiction? (optional)
        </Text>
        <HStack mb="24px">
          <GoLivePill onClick={() => setRegisteredAdvisor(false)} active={registeredAdvisor === false}>
            <Text fontWeight="medium" fontSize="15px">
              No
            </Text>
          </GoLivePill>
          <GoLivePill ml="20px" onClick={() => setRegisteredAdvisor(true)} active={registeredAdvisor}>
            <Text fontWeight="medium" fontSize="15px">
              Yes
            </Text>
          </GoLivePill>
        </HStack>
        <FormControl w="100%" display={registeredAdvisor ? 'flex ' : 'none'}>
          {GoLiveInput(
            '',
            'eg: Registered Broker- Deal with SEC, Registered Investment Advisor with the SEC',
            props.handleDbaRelationship,
          )}
        </FormControl>
      </VStack>
      <VStack mt="40px" alignItems="start">
        <Text fontSize="16px">Doing Business As, Alias, “Foreign Entity” (if applicable)</Text>
        <Text fontSize="13px" color="grey">
          {foreignEntityCopy}
        </Text>
        <HStack mb="24px">
          <GoLivePill onClick={() => setForeignEntity(false)} active={foreignEntity === false}>
            <Text fontWeight="medium" fontSize="15px">
              No
            </Text>
          </GoLivePill>
          <GoLivePill ml="20px" onClick={() => setForeignEntity(true)} active={foreignEntity}>
            <Text fontWeight="medium" fontSize="15px">
              Yes
            </Text>
          </GoLivePill>
        </HStack>
      </VStack>
      <Box mt="24px" display={foreignEntity ? 'block' : 'none'}>
        <HStack justifyContent="space-between">
          <FormControl w={formHalfWidth}>
            {GoLiveInput('Legal Entity Name', 'eg: Beeple Ltd.', props.handleDbaLegalEntityName)}
          </FormControl>
          <FormControl w={formHalfWidth}>
            {GoLiveInput('Legal Entity Type', 'eg: LLC, Inc, Corp', props.handleDbaLegalEntityType)}
          </FormControl>
        </HStack>
        <HStack justifyContent="space-between" mt="1.5rem">
          <FormControl w={formHalfWidth}>
            {GoLiveInput(
              'Date of Incorporation',
              'eg: 2002.01.01',
              props.handleDbaDateOfIncorporation,
              (val: string) => dateRegex.test(val),
              'Date must be in YYYY-MM-DD format',
            )}
          </FormControl>
          <FormControl w={formHalfWidth}>
            {GoLiveInput('Country of registration', 'eg: United States', props.handleDbaCountryOfRegistration)}
          </FormControl>
        </HStack>
        <HStack justifyContent="space-between" mt="1.5rem">
          <FormControl w="378px">
            {GoLiveInput('Full address', 'Address Line', props.handleDbaStreetAddress)}
          </FormControl>
          <FormControl w={formThirdWidth}>
            {GoLiveInput('Relationship', 'eg: DBA, alias, etc', props.handleDbaRelationship)}
          </FormControl>
        </HStack>
      </Box>
    </Box>
  );
};

export default BusinessDetails;
