import React, { useState, useEffect } from 'react';
import { Box, HStack, Text, Tabs, TabList, useToast } from '@chakra-ui/react';
import { useParams } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { useQuery } from 'react-query';
import styled from 'styled-components';
import Header from '../components/layout/Header';
import Tab from '../components/general/Tab';
import NoContent from '../components/general/NoContent';
import { firmAccountName } from '../globals/utils';
import { BrokerAPIDocSrc } from '../globals/consts';
import { getAccounts } from '../api/api';
import { Account } from '../api/types';
import ActivitesTable from '../components/tables/ActivitiesTable';

const FieldName = styled(Text)`
  opacity: 60%;
  font-size: 0.875rem;
  white-space: nowrap;
`;

const FirmDetails = (): React.ReactElement => {
  const accountsQuery = useQuery('firm_accounts', () => getAccounts({ firmAccounts: true }));
  const history = createBrowserHistory();

  const toast = useToast();

  const { accountID } = useParams();
  const [selectedTab, setSelectedTab] = useState(0);
  const [selectedAccount, setSelectedAccount] = useState<Account | undefined>(undefined);

  const selectIndex = (index: number) => {
    if (!accountsQuery.data) return;
    if (index > accountsQuery.data.length - 1) return;
    setSelectedTab(index);
    setSelectedAccount(accountsQuery.data[index]);
    history.push(`/balance/${accountsQuery.data[index].id}`);
  };

  useEffect(() => {
    (accountsQuery.data || []).forEach((a, i) => {
      if (a.id === accountID) selectIndex(i);
    });
  }, [accountsQuery.data]);

  if (accountsQuery.isError)
    toast({
      title: 'An error occurred displaying firm accounts',
      description: (accountsQuery.error as Error).message,
      status: 'error',
    });

  return (
    <>
      <Header title="Firm Balance" />
      {accountsQuery.data?.length === 0 ? (
        <NoContent
          title="No firm accounts"
          image="table"
          linkText="Learn more about firm accounts"
          linkDestination={BrokerAPIDocSrc}
        />
      ) : (
        <>
          <Tabs onChange={(i) => selectIndex(i)} mt="3rem">
            <TabList>
              {(accountsQuery.data || []).map((act, i) => (
                <Tab key={act.id} text={firmAccountName(act.account_number)} isSelected={selectedTab === i} />
              ))}
            </TabList>
          </Tabs>
          {selectedAccount && (
            <>
              <HStack spacing="3rem" m="2rem 1rem">
                <Box>
                  <FieldName>Acc #:</FieldName>
                  <Text fontSize="lg" color="brand.600" fontWeight="500">
                    {selectedAccount?.account_number}
                  </Text>
                </Box>
                <Box>
                  <FieldName>Acc ID:</FieldName>
                  <Text fontSize="lg" color="brand.600" fontWeight="500">
                    {selectedAccount?.id}
                  </Text>
                </Box>
              </HStack>
              <ActivitesTable nonTradeOnly accountID={selectedAccount?.id} />
            </>
          )}
        </>
      )}
    </>
  );
};

export default FirmDetails;
