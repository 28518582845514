import React, { useState, useEffect } from 'react';
import { Button, Box, ChakraProps, useColorModeValue } from '@chakra-ui/react';
import { Account, Order } from '../../api/types';
import { FiDownload } from 'react-icons/fi';
import { CSVDownload } from 'react-csv';

type ExportDataType = Account | Order | (string | moment.Moment)[];

interface ExportButtonProps extends ChakraProps {
  data: ExportDataType[];
  givenHeaders?: string[];
}

const ExportToCsv = (props: ExportButtonProps): React.ReactElement => {
  const { data, givenHeaders } = props;
  const [downloading, setDownloading] = useState(false);

  const textColor = useColorModeValue('black', 'white');

  let headers: string[] = [];
  if (data.length > 0 && !givenHeaders) {
    headers = formatData(data[0], [], '');
  }

  if (props.givenHeaders) {
    headers = givenHeaders as string[];
  }

  // reset after clicking - this is necessary since CSVDownload needs to mount for the download to trigger
  useEffect(() => {
    setDownloading(false);
  }, [downloading]);

  return (
    <Box h="fit-content" alignSelf="center">
      <Button
        leftIcon={<FiDownload />}
        onClick={() => setDownloading(true)}
        bgColor="transparent"
        color={textColor}
        variant="ghost"
        disabled={data.length === 0}
      >
        Export
        {downloading && <CSVDownload data={data} target="_blank" headers={headers} />}
      </Button>
    </Box>
  );
};

// recursively format the headers for the CSV file looking for nested objects
const formatData = (data: ExportDataType, headers: string[], nestedLevel: string): string[] => {
  for (const [key, value] of Object.entries(data)) {
    const currKey = nestedLevel ? `${nestedLevel}.${key}` : key;
    if (!!value && value.constructor === Object) {
      formatData(value, headers, currKey);
    } else {
      headers.push(currKey);
    }
  }

  return headers;
};

export default ExportToCsv;
