import React, { useContext, useEffect, useState } from 'react';
import { RepeatIcon, CopyIcon, InfoOutlineIcon } from '@chakra-ui/icons';
import { AiOutlineExclamationCircle } from 'react-icons/ai';
import styled from '@emotion/styled';
import {
  Alert,
  AlertIcon,
  Box,
  Button,
  HStack,
  Popover,
  PopoverTrigger,
  Text,
  useClipboard,
  useToast,
  PopoverContent,
  PopoverBody,
  PopoverHeader,
  ButtonGroup,
  PopoverFooter,
  Collapse,
  Tooltip,
  Flex,
  Grid,
  GridItem,
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  ChakraProps,
  useColorModeValue,
} from '@chakra-ui/react';
import Header from '../components/layout/Header';
import {
  createApiKey,
  disableKey,
  fetchKeys,
  checkSeedEnv,
  checkResetEnv,
  seedEnv,
  resetEnv,
  getSelf,
} from '../api/api';
import { Role } from '../api/types';
import { useQuery, useQueryClient } from 'react-query';
import Table from '../components/general/Table';
import Card from '../components/general/Card';
import { AppContext } from '../globals/appcontext';
import { SupportEmail } from '../globals/consts';
import { isMobile } from 'react-device-detect';
import CopyButton from '../components/general/CopyButton';

const DisabledBox = styled(Box)`
  width: 70%;
  min-height: 38px;
  border-radius: 8px;
  background-color: #ffffff07;
  padding: 8px 0px 8px 16px;
  display: flex;
  align-items: center;
  margin-top: 1rem;
`;

export const GenerateAPIkeys = (props: { disableBtn?: boolean }): React.ReactElement => {
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [apiKey, setApiKey] = useState('');
  const [apiSecret, setApiSecret] = useState('');
  const { onCopy: keyCopy } = useClipboard(apiKey);
  const { onCopy: secretCopy } = useClipboard(apiSecret);
  const toast = useToast();

  const generateKeys = () => {
    createApiKey()
      .then((key) => {
        setApiKey(key.id);
        setApiSecret(key.secret || '');
      })
      .catch((e) => {
        toast({ title: 'An error occurred creating API keys', description: e.message, status: 'error' });
      });
  };

  const copyKey = () => {
    keyCopy();
    toast({ description: 'API key copied', duration: 1500 });
  };

  const copySecret = () => {
    secretCopy();
    toast({ description: 'API secret copied', duration: 1500 });
  };

  const disableKeyRequest = () => {
    setConfirmOpen(false);
    disableKey(apiKey)
      .then(() => toast({ title: 'API key disabled', status: 'success' }))
      .catch((e) => {
        toast({ title: 'An error occurred disabling the API key', description: e.message, status: 'error' });
      });
  };

  return (
    <>
      <HStack>
        <Text fontWeight="500">Generate new Sandbox API keys</Text>
        <Button variant="ghost" leftIcon={<RepeatIcon />} onClick={generateKeys}>
          Generate
        </Button>
      </HStack>
      <Collapse in={!!apiKey}>
        <>
          <Box>
            <HStack mt="1.5rem">
              <Text minW="80px">api_key</Text>
              <Button fontWeight="normal" variant="ghost" rightIcon={<CopyIcon />} onClick={copyKey}>
                {apiKey}
              </Button>
            </HStack>
            <HStack mt="1rem">
              <Text minW="80px">api_secret</Text>
              <Button fontWeight="normal" variant="ghost" rightIcon={<CopyIcon />} onClick={copySecret}>
                {apiSecret}
              </Button>
            </HStack>
            <Alert status="info" maxW="425px" mb="12px" mt="12px">
              <AlertIcon />
              Please make sure to store them somewhere safe
            </Alert>
          </Box>
          {props.disableBtn && (
            <Popover isOpen={confirmOpen}>
              <PopoverTrigger>
                <Button ml="2rem" w="15rem" color="error" variant="outline" onClick={() => setConfirmOpen(true)}>
                  Disable Key
                </Button>
              </PopoverTrigger>
              <PopoverContent>
                <PopoverHeader fontWeight="bold" border="0">
                  Confirmation
                </PopoverHeader>
                <PopoverBody>Are you sure you want to delete this key?</PopoverBody>
                <PopoverFooter border="0" d="flex" justifyContent="flex-end">
                  <ButtonGroup size="sm">
                    <Button variant="outline" onClick={() => setConfirmOpen(false)}>
                      Cancel
                    </Button>
                    <Button onClick={disableKeyRequest}>Yes</Button>
                  </ButtonGroup>
                </PopoverFooter>
              </PopoverContent>
            </Popover>
          )}
        </>
      </Collapse>
    </>
  );
};

const Devs = (): React.ReactElement => {
  const toast = useToast();
  const appContext = useContext(AppContext);
  const keysQuery = useQuery('keys', fetchKeys);
  const [keys, setKeys] = useState(keysQuery.data || []);
  const { data: authUser } = useQuery('self', () => getSelf());

  const isSandbox = appContext.correspondent?.Env === 'sandbox';
  const isDisabled = appContext.correspondent.Status === 'disabled';

  // TODO: Map grants instead of hardcoding this here
  const canGenerate = authUser?.role === Role.Superuser;

  const [apiKey, setApiKey] = useState('');
  const [apiSecret, setApiSecret] = useState('');

  const generateKeys = () => {
    createApiKey()
      .then((key) => {
        setApiKey(key.id);
        setApiSecret(key.secret || '');
        setKeys([...keys, key]);
      })
      .catch((e) => {
        toast({ title: 'An error occurred creating API keys', description: e.message, status: 'error' });
      });
  };

  useEffect(() => {
    if (keysQuery.data) setKeys(keysQuery.data);
  }, [keysQuery.data]);

  const disableKeyRequest = (index: number) => {
    disableKey(keys[index].id)
      .then(() => {
        toast({ title: 'API key disabled', status: 'success' });
        setKeys(keys.filter((key) => key.id !== keys[index].id));
      })
      .catch((e) => {
        toast({ title: 'An error occurred disabling the API key', description: e.message, status: 'error' });
      });
  };

  const endpoint = isSandbox ? process.env.REACT_APP_SANDBOX_API_HOST : process.env.REACT_APP_PROD_API_HOST;

  return (
    <Box>
      <Header title="API/Devs" />
      {isSandbox && <EnvControls mt="3rem" disabled={isDisabled} />}
      <Flex mt="2rem" flexDirection="column">
        {canGenerate && (
          <HStack>
            <Text fontWeight="500">Generate new API keys</Text>
            <Button variant="ghost" leftIcon={<RepeatIcon />} onClick={generateKeys} disabled={isDisabled}>
              Generate
            </Button>
          </HStack>
        )}
        {isDisabled && (
          <DisabledBox>
            <AiOutlineExclamationCircle color="gray" fontSize="16px" />
            <Text ml="16px" fontWeight="500" color="gray">
              Your API access has been disabled. Please reach out to {SupportEmail}.
            </Text>
          </DisabledBox>
        )}
      </Flex>
      <Grid templateColumns="repeat(5, 1fr)" gap="2.5rem" mt="2rem">
        {canGenerate && (
          <GridItem colSpan={isMobile ? 5 : 2}>
            <Card>
              <Text ml="1rem" mb="5px" opacity="60%" fontWeight={500}>
                Endpoint
              </Text>
              <Alert status="info">
                <Text opacity="60%">{endpoint}</Text>
              </Alert>
              <HStack mt="1.5rem" ml="1rem">
                <Text opacity="60%" minW="80px">
                  api_key
                </Text>
                {apiKey ? <CopyButton isTruncated name="API key" value={apiKey} /> : <Text>-</Text>}
              </HStack>
              <HStack mt="1rem" ml="1rem">
                <Text opacity="60%" minW="80px">
                  api_secret
                </Text>
                {apiSecret ? <CopyButton isTruncated name="API secret" value={apiSecret} /> : <Text>-</Text>}
              </HStack>
              <HStack m="1rem" mt="2rem">
                <InfoOutlineIcon opacity="60%" />
                <Text opacity="60%">
                  {apiKey ? (
                    'Please make sure to store those keys somewhere safe'
                  ) : (
                    <>
                      Click <b>Generate</b> to get a new key and secret
                    </>
                  )}
                </Text>
              </HStack>
            </Card>
          </GridItem>
        )}
        <GridItem colSpan={isMobile || !canGenerate ? 5 : 3}>
          <Table
            isLoading={keysQuery.isLoading}
            headers={['API Key', 'Status', 'Created At']}
            rowsPerPage={8}
            copyIndexes={[0]}
            rows={keys.map((key) => [key.id, key.status, key.created_at])}
            onDelete={(rowIdx) => disableKeyRequest(rowIdx)}
            disabled={isDisabled}
          />
        </GridItem>
      </Grid>
    </Box>
  );
};

interface EnvControlProps extends ChakraProps {
  disabled?: boolean;
}

const EnvControls = (props: EnvControlProps) => {
  const toast = useToast();
  const queryClient = useQueryClient();
  const [seedDialogOpen, setSeedDialogOpen] = useState(false);
  const [resetDialogOpen, setResetDialogOpen] = useState(false);

  const cancelSeedRef = React.useRef(null);
  const cancelResetRef = React.useRef(null);

  const [disableSeed, setDisableSeed] = useState(true);
  const [disableReset, setDisableReset] = useState(true);

  const updateStatus = async () => {
    queryClient.invalidateQueries();
    const canReset = await checkResetEnv();
    const canSeed = await checkSeedEnv();
    setDisableSeed(!canSeed);
    setDisableReset(!canReset);
  };

  useEffect(() => {
    updateStatus();
  }, []);

  const seedEnvRequest = () => {
    seedEnv()
      .then(() => toast({ title: 'Environment data generated', status: 'success' }))
      .catch((e) =>
        toast({ title: 'An error occurred creating environment data', description: e.message, status: 'error' }),
      )
      .finally(updateStatus);
  };

  const resetEnvRequest = () => {
    resetEnv()
      .then(() => toast({ title: 'Environment Reset', status: 'success' }))
      .catch((e) =>
        toast({ title: 'An error occurred resetting environment', description: e.message, status: 'error' }),
      )
      .finally(updateStatus);
  };

  return (
    <HStack {...props}>
      <Tooltip label="Populate the environment with random users" placement="top">
        <Button variant="outline" onClick={() => setSeedDialogOpen(true)} disabled={disableSeed}>
          Populate Sandbox
        </Button>
      </Tooltip>
      <AlertDialog isOpen={seedDialogOpen} leastDestructiveRef={cancelSeedRef} onClose={() => setSeedDialogOpen(false)}>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Populate Sandbox
            </AlertDialogHeader>
            <AlertDialogBody>Do you want to populate this environment with seed data?</AlertDialogBody>
            <AlertDialogFooter>
              <Button ref={cancelSeedRef} onClick={() => setSeedDialogOpen(false)}>
                Cancel
              </Button>
              <Button
                onClick={() => {
                  setSeedDialogOpen(false);
                  seedEnvRequest();
                }}
                ml={3}
                variant="outline"
                disabled={props.disabled}
              >
                Populate
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
      <Tooltip label="Reset your sandbox env to an initial state" placement="top">
        <Button
          colorScheme="gray"
          color={useColorModeValue('gray.700', 'gray.300')}
          onClick={() => setResetDialogOpen(true)}
          disabled={disableReset || props.disabled}
        >
          Reset sandbox
        </Button>
      </Tooltip>
      <AlertDialog
        isOpen={resetDialogOpen}
        leastDestructiveRef={cancelResetRef}
        onClose={() => setResetDialogOpen(false)}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Reset Environment
            </AlertDialogHeader>
            <AlertDialogBody>
              Do you want to reset the data in this environment? All accounts, transactions, orders, journals and
              documents will be removed. Your team authorizations and access keys will not be affected.
            </AlertDialogBody>
            <AlertDialogFooter>
              <Button ref={cancelResetRef} onClick={() => setResetDialogOpen(false)}>
                Cancel
              </Button>
              <Button
                onClick={() => {
                  setResetDialogOpen(false);
                  resetEnvRequest();
                }}
                ml={3}
                variant="outline"
              >
                Reset
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </HStack>
  );
};

export default Devs;
