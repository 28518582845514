import React, { useState } from 'react';
import {
  Box,
  FormControl,
  FormLabel,
  Input,
  Button,
  InputGroup,
  InputRightElement,
  Text,
  Center,
  useToast,
  BoxProps,
  Icon,
  Flex,
  useColorModeValue,
} from '@chakra-ui/react';
import styled from 'styled-components';
import { useIntercom } from 'react-use-intercom';
import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons';
import { AuthService, CognitoUser } from '../../auth/authServices';
import { useNavigate, Navigate, NavLink } from 'react-router-dom';
import { AlpacaDarkSVG, AlpacaSVG } from '../../icons/Login';
import colors from '../theme/colors';
import { isMobile } from 'react-device-detect';
import useWindowSize from '../../globals/windowdim';
import { TradingAPISrc } from '../../globals/consts';
import { useContext } from 'react';
import { AppContext } from '../../globals/appcontext';

const HidePasswordButton = styled(Box)`
  color: rgba(0, 0, 0, 0.5);
  font-size: 18px;

  &:hover {
    color: black;
    cursor: pointer;
  }
`;

const SignUpLink = styled(NavLink)`
  color: ${colors.yellow};
  margin-left: 10px;
  font-weight: 500;

  &:hover {
    border-bottom: solid 2px ${colors.yellow};
  }
`;

const AlpacaLeft = () => {
  const svg = useColorModeValue(AlpacaSVG, AlpacaDarkSVG);
  return (
    <Box minWidth="max(25%, 16rem)" position="relative" bottom="-8rem" left="-15rem">
      <Icon as={svg} fontSize="650px" position="absolute" bottom="0" left="0" />
    </Box>
  );
};

const AlpacaRight = () => {
  const svg = useColorModeValue(AlpacaSVG, AlpacaDarkSVG);
  return (
    <Box width="25%" position="relative" bottom="-8rem" right="-12rem">
      <Icon as={svg} fontSize="350px" position="absolute" bottom="0" right="0" transform="scaleX(-1)" />
    </Box>
  );
};

const Login = (props: BoxProps): React.ReactElement => {
  const toast = useToast();
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const [smsCode, setSMSCode] = useState('');
  const [confirmSMS, setConfirmSMS] = useState(false);

  const [requireNewPassword, setRequireNewPassword] = useState(false);
  const [cognitoUser, setCognitoUser] = useState<CognitoUser | undefined>(undefined);
  const [name, setName] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [showNewPassword, setShowNewPassword] = useState(false);
  const winSize = useWindowSize();
  const appContext = useContext(AppContext);

  // Hide Intercom on login page
  const { hide } = useIntercom();
  hide();

  const handleNewPasswordSubmit = async (event: React.FormEvent) => {
    if (!cognitoUser) return;
    event.preventDefault();
    setIsLoading(true);
    AuthService.setNewPassword(cognitoUser, newPassword, name)
      .then(() => {
        toast({
          title: 'Login Successful',
          status: 'success',
        });
        appContext.setIsAuthenticated(true);
        setRedirect(true);
      })
      .catch((e) => {
        toast({
          title: 'There was a problem setting new password',
          description: e.message,
          status: 'error',
        });
        setIsLoading(false);
      });
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    setIsLoading(true);

    AuthService.login(email, password)
      .then((user) => {
        if (user.challengeName === 'SMS_MFA') {
          setCognitoUser(user);
          setIsLoading(false);
          setConfirmSMS(true);
          return;
        }

        if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
          setCognitoUser(user);
          setIsLoading(false);
          setRequireNewPassword(true);
          return;
        }

        toast({
          title: 'Login Successful',
          status: 'success',
        });
        appContext.setIsAuthenticated(true);
        setRedirect(true);
      })
      .catch((e) => {
        if (e.code === 'PasswordResetRequiredException') {
          navigate('/set-password', { state: { email } });
          return;
        }

        toast({
          title: 'Invalid username or password',
          description: e.message,
          status: 'error',
        });
        setIsLoading(false);
      });
  };

  const handleMFASubmit = async (event: React.FormEvent) => {
    if (!cognitoUser) return;
    event.preventDefault();
    setIsLoading(true);

    AuthService.confirmSMSLogin(cognitoUser, smsCode)
      .then(() => {
        toast({
          title: 'Login Successful',
          status: 'success',
        });
        appContext.setIsAuthenticated(true);
        setRedirect(true);
      })
      .catch((e) => {
        toast({
          title: 'Login Error',
          description: e.message,
          status: 'error',
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  if (redirect) {
    return <Navigate to="/dashboard" />;
  }

  const search = window.location.search;
  const params = new URLSearchParams(search);
  const fromSignOut = params.get('signedOut') === 'true';
  const headerFontSize = isMobile ? '3xl' : '4xl';
  const largeScreen = ['medium', 'large'].includes(winSize);

  const handlePasswordVisibility = () => setShowPassword(!showPassword);
  const handleNewPasswordVisibility = () => setShowNewPassword(!showNewPassword);

  return (
    <Flex {...props} justify="center">
      {largeScreen && <AlpacaLeft />}
      <Box w={!largeScreen ? '100%' : '50%'} position="relative">
        <Center fontSize={headerFontSize} mb="2rem" textAlign="center">
          {fromSignOut ? 'Sign Back In' : 'Log in to your Broker API account'}
        </Center>
        {!requireNewPassword && !confirmSMS && (
          <form onSubmit={handleSubmit}>
            <FormControl>
              <FormLabel>Email</FormLabel>
              <Input
                variant="filled"
                type="email"
                size="lg"
                onChange={(event) => setEmail(event.currentTarget.value)}
              />
            </FormControl>
            <FormControl mt={6}>
              <FormLabel>Password</FormLabel>
              <InputGroup>
                <Input
                  variant="filled"
                  type={showPassword ? 'text' : 'password'}
                  size="lg"
                  onChange={(event) => setPassword(event.currentTarget.value)}
                />
                <InputRightElement width="3rem">
                  <HidePasswordButton onClick={handlePasswordVisibility}>
                    {showPassword ? <ViewOffIcon /> : <ViewIcon />}
                  </HidePasswordButton>
                </InputRightElement>
              </InputGroup>
            </FormControl>
            <Box mt="3rem" float="right" fontSize="lg">
              <NavLink to="/forgot-password">
                <Text color="grey" _hover={{ color: 'black' }}>
                  Forgot password?
                </Text>
              </NavLink>
            </Box>
            <Button size="lg" type="submit" width="full" mt="1rem" isLoading={isLoading}>
              Log in
            </Button>
            <Center mt="3rem" fontSize="lg">
              <Text color="grey" mr="5px" textAlign="center">
                Don&apos;t have a Broker API account, yet?
                <SignUpLink to="/sign-up">Sign up</SignUpLink>
              </Text>
            </Center>
            {largeScreen && (
              <Center textAlign="center" mt="4rem" mb="-6rem">
                <Box color="grey" fontSize="md" display="inline">
                  For algorithmic trading, quants funds, and trading your own funds with Trading API, visit
                  <a href={TradingAPISrc}>
                    <Text fontWeight={500} display="inline" fontSize="md" color="brand.500" ml="5px">
                      Trading API product page.
                    </Text>
                  </a>
                </Box>
              </Center>
            )}
          </form>
        )}
        {requireNewPassword && (
          <form onSubmit={handleNewPasswordSubmit}>
            <FormControl isRequired mt={6}>
              <FormLabel>Full Name</FormLabel>
              <InputGroup>
                <Input
                  variant="filled"
                  type="text"
                  size="lg"
                  onChange={(event) => setName(event.currentTarget.value)}
                />
              </InputGroup>
            </FormControl>
            <FormControl isRequired mt={6}>
              <FormLabel>Create a Password</FormLabel>
              <InputGroup>
                <Input
                  variant="filled"
                  type={showNewPassword ? 'text' : 'password'}
                  size="lg"
                  onChange={(event) => setNewPassword(event.currentTarget.value)}
                />
                <InputRightElement width="3rem">
                  <HidePasswordButton onClick={handleNewPasswordVisibility}>
                    {showNewPassword ? <ViewOffIcon /> : <ViewIcon />}
                  </HidePasswordButton>
                </InputRightElement>
              </InputGroup>
            </FormControl>
            <Button size="lg" type="submit" width="full" mt="1rem" isLoading={isLoading}>
              Continue
            </Button>
          </form>
        )}
        {confirmSMS && (
          <form onSubmit={handleMFASubmit}>
            <FormControl isRequired mt={6}>
              <FormLabel>Code</FormLabel>
              <InputGroup>
                <Input
                  variant="filled"
                  type="text"
                  size="lg"
                  value={smsCode}
                  onChange={(event) => setSMSCode(event.currentTarget.value)}
                />
              </InputGroup>
            </FormControl>
            <Button size="lg" type="submit" width="full" mt="1rem" isLoading={isLoading}>
              Submit
            </Button>
          </form>
        )}
      </Box>

      {largeScreen && <AlpacaRight />}
    </Flex>
  );
};

export default Login;
