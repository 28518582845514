import React, { createRef, useState, useEffect, MouseEvent } from 'react';
import { Box, FormControl, FormLabel, Input, Button, Text, Center, useToast, BoxProps } from '@chakra-ui/react';
import { useNavigate, Navigate } from 'react-router-dom';
import ReactInputMask from 'react-input-mask';
import styled from 'styled-components';

import { AuthService } from '../../auth/authServices';

const MaskedInput = styled(ReactInputMask)`
  position: absolute;
  height: 0px;
`;

const MFA = (props: BoxProps): React.ReactElement => {
  const toast = useToast();
  const navigate = useNavigate();
  const phoneMask = createRef<ReactInputMask>();

  const [enterPhone, setEnterPhone] = useState(false);
  const [phone, setPhone] = useState('');
  const [code, setCode] = useState('');
  const [verify, setVerify] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [redirect, setRedirect] = useState(false);

  useEffect(() => {
    AuthService.isLoggedIn().then((b) => {
      if (!b) {
        navigate('/login');
      }
    });
  }, []);

  const enableMFA = async () => {
    setIsLoading(true);
    AuthService.enableMFA()
      .then((resp) => {
        switch (resp) {
          case 'ENABLED':
            setRedirect(true);
            break;
          case 'VERIFY_PHONE':
            setVerify(true);
            break;
          default:
            setEnterPhone(true);
        }
      })
      .catch((e) => {
        toast({
          title: 'An error occured',
          description: e.message,
          status: 'error',
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handlePhone = async (event: React.FormEvent) => {
    const formatted = phone.replaceAll(' ', '').replaceAll('-', '');
    event.preventDefault();
    setIsLoading(true);

    AuthService.addPhone(formatted)
      .then(() => {
        setVerify(true);
      })
      .catch((e) => {
        toast({
          title: 'An error occured',
          description: e.message,
          status: 'error',
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const submitCode = async (event: React.FormEvent) => {
    event.preventDefault();
    setIsLoading(true);

    AuthService.verifyPhone(code)
      .then(() => {
        toast({
          title: 'MFA Enabled',
          status: 'success',
        });
        setRedirect(true);
      })
      .catch((e) => {
        toast({
          title: 'An error occured',
          description: e.message,
          status: 'error',
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  if (redirect) {
    return <Navigate to="/dashboard" />;
  }

  const focusMask = (event: MouseEvent) => {
    event.preventDefault();
    phoneMask.current.focus();
  };

  return (
    <Box {...props}>
      <Center>
        {!enterPhone && (
          <Box>
            <Text fontSize="4xl" mb="2rem">
              Enable SMS MFA (US Phone Numbers only)?
            </Text>
            <Button size="lg" width="full" mt="4rem" onClick={enableMFA} isLoading={isLoading}>
              Yes
            </Button>
            <Button size="md" variant="ghost" width="full" mt="4rem" onClick={() => setRedirect(true)}>
              Skip
            </Button>
          </Box>
        )}
        {!verify && enterPhone && (
          <Box>
            <Text fontSize="4xl" mb="2rem">
              Enter Phone Number
            </Text>
            <form onSubmit={handlePhone}>
              <FormControl isRequired mt={6}>
                <FormLabel>Phone Number</FormLabel>
                <MaskedInput
                  ref={phoneMask}
                  id="phone-mask"
                  mask="+1 999-999-9999"
                  onChange={(event) => setPhone(event.currentTarget.value)}
                />
                <Input variant="filled" type="tel" size="lg" value={phone} readOnly onClick={focusMask} />
              </FormControl>
              <Button size="lg" type="submit" width="full" mt="4rem" isLoading={isLoading}>
                Next
              </Button>
            </form>
          </Box>
        )}
        {verify && (
          <Box>
            <Text fontSize="4xl" mb="2rem">
              Enter Verification Code
            </Text>
            <form onSubmit={submitCode}>
              <FormControl isRequired mt={6}>
                <FormLabel>Code</FormLabel>
                <Input
                  variant="filled"
                  size="lg"
                  value={code}
                  onChange={(event) => setCode(event.currentTarget.value)}
                />
              </FormControl>
              <Button size="lg" type="submit" width="full" mt="4rem" isLoading={isLoading}>
                Submit
              </Button>
            </form>
          </Box>
        )}
      </Center>
    </Box>
  );
};

export default MFA;
