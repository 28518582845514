import React from 'react';
import { Box, useColorModeValue } from '@chakra-ui/react';
import { Range, getTrackBackground } from 'react-range';
import colors from '../theme/colors';

interface RangeSliderProps {
  step: number;
  min: number;
  max: number;
  values: number[];
  invertTrack?: boolean;
  removeTrackColor?: boolean;
  onChange: (values: number[]) => void;
}

const RangeSlider = (props: RangeSliderProps): React.ReactElement => {
  const thumbColor = useColorModeValue('#d0d4d4', '#FFF');
  const trackColor = useColorModeValue(colors.bgLight, colors.bgDark);

  // value should not leave bounds of slider
  const bound = (value: number) => {
    return Math.min(props.max, Math.max(props.min, value));
  };

  let trackColors = [trackColor, colors.yellow];
  if (props.invertTrack) {
    trackColors = [colors.yellow, trackColor];
  }
  if (props.removeTrackColor) {
    trackColors = [trackColor, trackColor];
  }
  if (props.values.length === 2) {
    trackColors = [trackColor, colors.yellow, trackColor];
  }

  return (
    <Box m="0px 12px" mt="25px">
      <Range
        values={props.values.map(bound)}
        step={props.step}
        min={props.min}
        max={props.max}
        onChange={props.onChange}
        renderTrack={(p) => (
          <Box
            ref={p.props.ref}
            h="5px"
            borderRadius="4px"
            bg={getTrackBackground({
              values: props.values,
              colors: trackColors,
              min: props.min,
              max: props.max,
            })}
          >
            {p.children}
          </Box>
        )}
        renderThumb={(p) => {
          p.props.style.zIndex = 0;
          return <Box {...p.props} h="20px" w="20px" borderRadius="50%" backgroundColor={thumbColor} />;
        }}
      />
    </Box>
  );
};

RangeSlider.defaultProps = {
  step: 1,
  min: 0,
  max: 100,
  onChange: () => null,
};

export default RangeSlider;
