import React, { useEffect, useMemo, useState } from 'react';
import numeral from 'numeral';
import { QueryKey, useQuery } from 'react-query';
import { getPositions } from '../../api/api';
import Table from '../general/Table';
import { Box } from '@chakra-ui/react';
import { RangeType, FilterButton, FilterDrawer, FilterSelect, FilterString, dateRangeToPill } from '../filter';
import { PositionsQuery } from '../../api/types';
import { APILinks } from '../../globals/consts';

const PositionsTable = (props: { accountID: string }): React.ReactElement => {
  const aID = props.accountID;

  // Filter
  const [filterOpen, setFilterOpen] = useState(false);
  const [sideFilter, setSideFilter] = useState('all');
  const [symbols, setSymbols] = useState('');
  const [selectedDates, setSelectedDates] = useState<RangeType>([null, null]);
  const [filterKey, setFilterKey] = useState<QueryKey>(['positions', aID]);
  const [applyFilter, setApplyFilter] = useState(false);

  const positionsQuery = useQuery(filterKey, () => {
    const params: PositionsQuery = {};
    if (symbols) params.symbols = symbols;
    if (sideFilter !== 'all') params.side = sideFilter;

    if (selectedDates[0] && selectedDates[1]) {
      params.after = selectedDates[0].toISOString();
      params.until = selectedDates[1].toISOString();
    }

    return getPositions(params, aID);
  });

  const queryKeys = ['positions', sideFilter, symbols, selectedDates, aID];
  useEffect(() => {
    if (applyFilter) setFilterKey(queryKeys);
  }, [...queryKeys, applyFilter]);

  const removeFilter = (filterID: string) => {
    if (filterID === 'side') setSideFilter('all');
    if (filterID === 'symbols') setSymbols('');
    if (filterID === 'dates') setSelectedDates([null, null]);
  };

  const filterPills = {
    side: sideFilter,
    symbols,
    dates: dateRangeToPill(selectedDates),
  };

  const posRows = useMemo(
    () =>
      (positionsQuery.data || []).map((p) => [
        p.symbol,
        p.side.toUpperCase(),
        p.qty.toString(),
        numeral(p.market_value).format('$0,0.00'),
        numeral(p.unrealized_plpc).format('(0.000 %)'),
      ]),
    [positionsQuery.data],
  );

  return (
    <>
      <Box mt="-6.5rem" float="right">
        <FilterButton
          filterPills={applyFilter ? filterPills : {}}
          openFilter={() => {
            setFilterOpen(true);
            setApplyFilter(false);
          }}
          removeFilter={removeFilter}
        />
      </Box>
      <FilterDrawer
        isOpen={filterOpen}
        onClose={() => setFilterOpen(false)}
        onApply={() => {
          setApplyFilter(true);
          setFilterOpen(false);
        }}
      >
        <FilterSelect header="Side" options={['all', 'long', 'short']} onSelect={setSideFilter} selected={sideFilter} />
        <FilterString header="Symbol" onChange={setSymbols} />
      </FilterDrawer>
      <Table
        isLoading={positionsQuery.isLoading}
        headers={['Symbol', 'Side', 'Quantity', 'Market Value', 'Unrealized PL']}
        rows={posRows}
        rightJustifyIndexes={[2, 3, 4]}
        noContentTitle="No positions for this account"
        noContentLinkText="Learn more about Positions"
        noContentLinkDestination={APILinks.positions}
      />
    </>
  );
};

export default PositionsTable;
