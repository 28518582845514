import React, { useState, ReactElement, useEffect } from 'react';
import { Button, Center, HStack, Spacer, Text } from '@chakra-ui/react';
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

interface DateRangeProps {
  onChange: (startDate: Date, endDate: Date) => void;
}

const DateRangePicker = (props: DateRangeProps): ReactElement => {
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [showPicker, setShowPicker] = useState(false);

  const onChange = (dates: [Date, Date]) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
    if (end) setShowPicker(false);
  };

  // notify parent if dates selected
  useEffect(() => {
    if (startDate && endDate) {
      props.onChange(startDate, endDate);
    }
  }, [startDate, endDate]);

  const placeholder = <Text opacity="30%">Select Date</Text>;

  return (
    <>
      <HStack m="1.5rem 0rem" w="100%">
        <Button onClick={() => setShowPicker(true)} w="45%">
          {startDate?.toLocaleDateString('en-US') || placeholder}
        </Button>
        <Spacer />
        <Text fontSize="16px">—</Text>
        <Spacer />
        <Button onClick={() => setShowPicker(true)} w="45%">
          {endDate?.toLocaleDateString('en-US') || placeholder}
        </Button>
      </HStack>
      {showPicker && (
        <Center>
          <ReactDatePicker
            inline
            selectsRange
            startDate={startDate}
            endDate={endDate}
            onChange={onChange}
            selected={startDate}
          />
        </Center>
      )}
    </>
  );
};

export default DateRangePicker;
