import React, { Dispatch, SetStateAction } from 'react';
import { Box, Text, HStack, VStack, Image, FormControl } from '@chakra-ui/react';
import { GoLiveInput, InputValue } from './GoLive';
import {
  primaryControlPersonCopy,
  formHalfWidth,
  formThirdWidth,
  dateRegex,
  emailRegex,
  phoneNumberRegex,
} from './constants';
import imgKYC from '../../assets/images/imgKYC.svg';

type ControlPersonAction = Dispatch<SetStateAction<InputValue>>;

interface ControlPersonCheckProps {
  handleFirstName: ControlPersonAction;
  handleLastName: ControlPersonAction;
  handleStreetAddress: ControlPersonAction;
  handleAptUnit: ControlPersonAction;
  handleCity: ControlPersonAction;
  handleState: ControlPersonAction;
  handlePostalCode: ControlPersonAction;
  handleCountry: ControlPersonAction;
  handlePhoneNumber: ControlPersonAction;
  handleEmail: ControlPersonAction;
  handleDOB: ControlPersonAction;
  handleTaxID: ControlPersonAction;
  handleTaxIDType: ControlPersonAction;
}

const ControlPersonCheck = (props: ControlPersonCheckProps): React.ReactElement => {
  return (
    <Box w="100%" mb="44px">
      <HStack>
        <VStack alignItems="start" w="453px">
          <Text fontSize="20px">Primary Control Person Verification Check</Text>
          <Text fontSize="14px" color="grey">
            {primaryControlPersonCopy}
          </Text>
        </VStack>
        <Box ml="34px !important">
          <Image src={imgKYC} alt="empty table contents" />
        </Box>
      </HStack>
      <HStack justifyContent="space-between" mt="44px">
        <FormControl w={formHalfWidth}>{GoLiveInput('First name', 'First name', props.handleFirstName)}</FormControl>
        <FormControl w={formHalfWidth}>{GoLiveInput('Last name', 'Last name', props.handleLastName)}</FormControl>
      </HStack>
      <HStack justifyContent="space-between" mt="1.5rem">
        <FormControl w={formHalfWidth}>
          {GoLiveInput('Street address', 'Address Line 1', props.handleStreetAddress)}
        </FormControl>
        <FormControl w={formHalfWidth}>
          {GoLiveInput('Apartment or Unit #', 'Address Line 2', props.handleAptUnit)}
        </FormControl>
      </HStack>
      <HStack justifyContent="space-between" mt="1.5rem">
        <FormControl w={formThirdWidth}>{GoLiveInput('City', 'eg: San Jose', props.handleCity)}</FormControl>
        <FormControl w={formThirdWidth}>
          {GoLiveInput('State/Province (opt.)', 'State/Province', props.handleState)}
        </FormControl>
        <FormControl w={formThirdWidth}>{GoLiveInput('Postal Code', 'eg: 90210', props.handlePostalCode)}</FormControl>
      </HStack>
      <HStack justifyContent="space-between" mt="1.5rem" alignItems="normal">
        <FormControl w={formHalfWidth}>{GoLiveInput('Country', 'eg: United States', props.handleCountry)}</FormControl>
        <FormControl w={formHalfWidth}>
          {GoLiveInput(
            'Phone number',
            '+1 (123) 456-7890',
            props.handlePhoneNumber,
            (val: string) => phoneNumberRegex.test(val),
            'Invalid phone number format',
          )}
        </FormControl>
      </HStack>
      <HStack justifyContent="space-between" mt="1.5rem" alignItems="normal">
        <FormControl w={formHalfWidth}>
          {GoLiveInput(
            'Email address',
            'eg: your@mail.com',
            props.handleEmail,
            (val: string) => emailRegex.test(val),
            'Invalid email format',
          )}
        </FormControl>
        <FormControl w={formHalfWidth}>
          {GoLiveInput(
            'Date of birth',
            'eg: 1992.04.20',
            props.handleDOB,
            (val: string) => dateRegex.test(val),
            'Date must be in YYYY-MM-DD format',
          )}
        </FormControl>
      </HStack>
      <HStack justifyContent="space-between" mt="1.5rem">
        <FormControl w={formHalfWidth}>{GoLiveInput('SSN/Tax ID', 'AAA-GG-SSSS', props.handleTaxID)}</FormControl>
        <FormControl w={formHalfWidth}>
          {GoLiveInput('Tax ID type', 'eg: USA - SSN, Australia - TFN...', props.handleTaxIDType)}
        </FormControl>
      </HStack>
    </Box>
  );
};

export default ControlPersonCheck;
