import React from 'react';
import { Input } from '@chakra-ui/react';
import FilterHeader from './FormHeader';

interface FilterStringProps {
  header: string;
  onChange: (value: string) => void;
}

const FilterString = (props: FilterStringProps): React.ReactElement => {
  return (
    <>
      <FilterHeader value={props.header} />
      <Input variant="filled" placeholder={`Enter ${props.header}`} onChange={(e) => props.onChange(e.target.value)} />
    </>
  );
};

export default FilterString;
