import React, { ReactElement } from 'react';
import { Auth } from 'aws-amplify';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { ChakraProvider } from '@chakra-ui/react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { IntercomProvider } from 'react-use-intercom';
import AppContextProvider from './globals/appcontext';
import InitSentry from './globals/sentry';

import Layout from './components/layout/Layout';
import { theme } from './components/theme/Theme';
import Balance from './pages/Balance';
import Dashboard from './pages/Dashboard';
import Devs from './pages/Devs';
import Documents from './pages/Documents';
import Transactions from './pages/Transactions';
import Users from './pages/Users';
import AuthPage from './pages/Auth';
import awsConfig from './auth/awsConfig';
import User from './pages/User';
import TeamSettingsPage from './pages/TeamSettings';
import Journals from './pages/Journals';
import Orders from './pages/Orders';
import Onboarding from './pages/Onboarding';
import NotFound from './pages/NotFound';
import LiveTesting from './pages/LiveTesting';
import FirmDetailsPage from './pages/FirmDetails';
import GoLive from './pages/golive/GoLive';
import Support from './pages/Support';

// Cognito ID
Auth.configure(awsConfig);

InitSentry();

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      staleTime: 30000,
    },
  },
});

export const App: React.FC = () => {
  const nav = (child: ReactElement) => <Layout>{child}</Layout>;

  return (
    <IntercomProvider appId={process.env.REACT_APP_INTERCOM_ID || ''} autoBoot>
      <QueryClientProvider client={queryClient}>
        <AppContextProvider>
          <ChakraProvider theme={theme}>
            <Router>
              <Routes>
                <Route path="/onboarding" element={nav(<Onboarding />)} />
                <Route path="/balance" element={nav(<Balance />)} />
                <Route path="/balance/:accountID" element={nav(<FirmDetailsPage />)} />
                <Route path="/dashboard" element={nav(<Dashboard />)} />
                <Route path="/dev" element={nav(<Devs />)} />
                <Route path="/dev/live-testing" element={nav(<LiveTesting />)} />
                <Route path="/documents" element={nav(<Documents />)} />
                <Route path="/go-live" element={nav(<GoLive />)} />
                <Route path="/transactions" element={nav(<Transactions />)} />
                <Route path="/transactions/orders" element={nav(<Orders />)} />
                <Route path="/transactions/journals" element={nav(<Journals />)} />
                <Route path="/accounts" element={nav(<Users />)} />
                <Route path="/accounts/:accountID" element={nav(<User />)} />
                <Route path="/team-settings" element={nav(<TeamSettingsPage />)} />
                <Route path="/contact-us" element={nav(<Support />)} />
                <Route path="/login" element={<AuthPage type="login" />} />
                <Route path="/sign-up" element={<AuthPage type="signup" />} />
                <Route path="/verification" element={<AuthPage type="verification" />} />
                <Route path="/forgot-password" element={<AuthPage type="forgot-password" />} />
                <Route path="/set-password" element={<AuthPage type="set-password" />} />
                <Route path="/mfa" element={<AuthPage type="mfa" />} />
                <Route path="/" element={<Navigate to="/dashboard" />} />
                <Route path="/*" element={<NotFound />} />
              </Routes>
            </Router>
          </ChakraProvider>
        </AppContextProvider>
      </QueryClientProvider>
    </IntercomProvider>
  );
};
