import React, { useState, useEffect, useRef } from 'react';
import { Box, Button, useColorMode, useClipboard } from '@chakra-ui/react';
import { ArrowForwardIcon, CopyIcon } from '@chakra-ui/icons';
import styled from 'styled-components';
import colors from '../theme/colors';

const CodeEditor = React.lazy(() => import('@monaco-editor/react'));

const CopyBtnIcon = styled(CopyIcon)`
  margin-left: 0.5rem;
`;

// TODO: Hardcoded for 'Send'
const SubmitBtnIcon = styled(ArrowForwardIcon)`
  margin-left: 0.5rem;
`;

const customLightTheme = {
  base: 'vs',
  inherit: true,
  rules: [{ background: colors.nestedBGLight }],
  colors: {
    'editor.background': colors.nestedBGLight,
  },
};

const customDarkTheme = {
  base: 'vs-dark',
  inherit: true,
  rules: [{ background: colors.nestedBGDark }],
  colors: {
    'editor.background': colors.nestedBGDark,
  },
};

interface EditorProps {
  language: string;
  content: string | undefined;
  onChange?: (value: string) => void;
  showSubmit?: boolean;
  onSubmit?: (value: string) => void;
  showFormat?: boolean;
  readOnly?: boolean;
}

const Editor = (props: EditorProps): React.ReactElement => {
  const editorRef = useRef(null);
  const { colorMode } = useColorMode();
  const [content, setContent] = useState(props.content || '');
  const [evokeSubmit, setEvokeSubmit] = useState(false);
  const { onCopy } = useClipboard(content);

  const [editorTheme, setEditorTheme] = useState(colorMode === 'light' ? 'light' : 'vs-dark');

  useEffect(() => {
    setEditorTheme(colorMode === 'light' ? 'custom-light' : 'custom-dark');
  }, [colorMode]);

  const options = {
    fontSize: 17,
    lineHeight: 30,
    lineNumbers: 'on',
    minimap: { enabled: false },
    padding: { top: 25, bottom: 25 },
    readOnly: !!props.readOnly,
    renderIndentGuides: true,
    renderLineHighlight: 'none',
    scrollBeyondLastLine: false,
    verticalScrollbarSize: 10,
    verticalSliderSize: 5,
  };

  function handleEditorChange(value: string | undefined) {
    if (!value) value = '';
    setContent(value);
    if (props.onChange) {
      props.onChange(value);
    }
  }

  const handleFormat = () => {
    editorRef.current.getAction('editor.action.formatDocument')?.run();
  };

  const handleSubmit = () => {
    if (!props.onSubmit) return;
    props.onSubmit(content || '');
  };

  // CopyButton not compatible
  const handleCopy = () => {
    onCopy();
  };

  useEffect(() => {
    if (!evokeSubmit) return;
    setEvokeSubmit(false);
    handleSubmit();
  }, [evokeSubmit]);

  const submitAction = () => {
    setEvokeSubmit(true);
  };

  const handleEditorDidMount = (editor, monaco) => {
    editorRef.current = editor;
    monaco.editor.defineTheme('custom-light', customLightTheme);
    monaco.editor.defineTheme('custom-dark', customDarkTheme);

    // editor param is IStandaloneCodeEditor
    editor.addCommand(monaco.KeyMod.CtrlCmd | monaco.KeyCode.Enter, submitAction);
  };

  const loading = <div>...</div>;

  return (
    <>
      <CodeEditor
        className="editor-container"
        theme={editorTheme}
        height="500px"
        defaultLanguage={props.language}
        value={content}
        onChange={handleEditorChange}
        options={options}
        onMount={handleEditorDidMount}
        loading={loading}
      />

      <Box mt="1.5rem">
        <Button variant="ghost" marginRight="1rem" onClick={handleCopy}>
          Copy
          <CopyBtnIcon />
        </Button>
        {props.showFormat && <Button onClick={handleFormat}>Format</Button>}
        {props.onSubmit && props.showSubmit && (
          <Button float="right" onClick={handleSubmit}>
            Send
            <SubmitBtnIcon />
          </Button>
        )}
      </Box>
    </>
  );
};

export default Editor;
