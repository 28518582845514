import { default as ReactCookieConsent } from 'react-cookie-consent';
import * as React from 'react';

const CookieConsent: React.FC = () => {
  return (
    <ReactCookieConsent
      location="bottom"
      buttonText="Accept"
      cookieName="gdpr_accepted"
      style={{ background: '#2B373B', zIndex: 999999 }}
      buttonStyle={{
        color: '#4e503b',
        fontSize: '13px',
        marginRight: '2em',
      }}
    >
      Alpaca and selected third parties use cookies on this website as specified in the
      <a href="https://files.alpaca.markets/disclosures/CookiePolicy.pdf" style={{ margin: '5px', color: '#ffe34d' }}>
        cookie policy
      </a>
      . You accept the use of these cookies or other identifiers by pressing ‘Accept’, closing this notice, or logging
      your account.
    </ReactCookieConsent>
  );
};

export default CookieConsent;
