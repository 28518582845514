import React from 'react';
import {
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerHeader,
  DrawerBody,
  Text,
  Flex,
  Spacer,
  Divider,
  Box,
} from '@chakra-ui/react';
import { Order } from '../../api/types';
import styled from 'styled-components';
import CopyButton from '../general/CopyButton';
import moment from 'moment';
import numeral from 'numeral';
import { capitalize } from '../../globals/utils';
import { CheckIcon, CloseIcon } from '@chakra-ui/icons';

const DetailLabel = styled(Text)`
  opacity: 60%;
  font-weight: 600;
  margin: auto;
  min-width: 100px;
`;

interface OrderDetailsProps {
  isOpen: boolean;
  onClose: () => void;
  order: Order | undefined;
}

const DetailRow = (props: { label: string; children: React.ReactElement }) => (
  <Flex>
    <DetailLabel>{props.label}</DetailLabel>
    <Spacer />
    {props.children}
  </Flex>
);

const OrderDetails = (props: OrderDetailsProps): React.ReactElement => {
  const o = props.order;

  // Order status markup
  const status = o?.status || '';
  let statusColor = '';
  let statusIcon = <Box />;
  if (['filled', 'partially_filled'].includes(status)) {
    statusColor = 'success';
    statusIcon = <CheckIcon mr="5px" />;
  }
  if (['cancelled', 'rejected'].includes(status)) {
    statusColor = 'error';
    statusIcon = <CloseIcon mr="5px" />;
  }

  // should always be US equity
  const assetClass = o?.asset_class === 'us_equity' ? 'US Equity' : capitalize(o?.asset_class);

  const copyDetail = (label: string, value: string | undefined) => (
    <DetailRow label={label}>
      <CopyButton isTruncated iconSide="left" value={value || ''} />
    </DetailRow>
  );

  const orderDetail = (label: string, value: string | undefined) => (
    <DetailRow label={label}>
      <Text lineHeight="40px" fontWeight={600}>
        {value || '-'}
      </Text>
    </DetailRow>
  );

  const moneyDetail = (label: string, value: number | undefined) => (
    <DetailRow label={label}>
      <Text lineHeight="40px" fontWeight={600}>
        {value ? numeral(value).format('$0.00') : '-'}
      </Text>
    </DetailRow>
  );

  const dateDetail = (label: string, date: moment.Moment | undefined) => (
    <DetailRow label={label}>
      <>
        <Text fontSize="12px" lineHeight="40px" fontWeight={600} opacity="60%" mr="5px">
          {!date ? '' : date.format('hh:mm a')}
        </Text>
        <Text lineHeight="40px" fontWeight={600}>
          {!date ? '-' : date.format('MM.DD.YYYY')}
        </Text>
      </>
    </DetailRow>
  );

  return (
    <Drawer isOpen={props.isOpen} placement="right" size="md" onClose={props.onClose}>
      <DrawerOverlay>
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>Order details</DrawerHeader>
          <DrawerBody>
            {copyDetail('Order ID', o?.id)}
            {copyDetail('Client Order ID', o?.client_order_id)}
            {copyDetail('Account ID', o?.account_id)}
            {copyDetail('Asset ID', o?.asset_id)}
            <Divider borderTopWidth="2px" m="20px 0px" />
            {orderDetail('Symbol', o?.symbol)}
            {orderDetail('Asset Class', assetClass)}
            {orderDetail('Quantity', o?.qty?.toString())}
            {orderDetail('Filled Quantity', o?.filled_qty?.toString())}
            {moneyDetail('Filled Average Price', o?.filled_avg_price)}
            {moneyDetail('Commission', o?.commission)}
            {orderDetail('Type', capitalize(o?.type))}
            {orderDetail('Side', capitalize(o?.side))}
            {orderDetail('Time In Force', o?.time_in_force?.toUpperCase())}
            {moneyDetail('Limit Price', o?.limit_price)}
            {moneyDetail('Stop Price', o?.stop_price)}
            <DetailRow label="Status">
              <Text color={statusColor} lineHeight="40px" fontWeight={600}>
                {statusIcon}
                {capitalize(status)}
              </Text>
            </DetailRow>
            <Divider borderTopWidth="2px" m="20px 0px" />
            {dateDetail('Created At', o?.created_at)}
            {dateDetail('Updated At', o?.updated_at)}
            {dateDetail('Submitted At', o?.submitted_at)}
            {dateDetail('Filled At', o?.filled_at)}
            {dateDetail('Expired At', o?.expired_at)}
            {dateDetail('Cancelled At', o?.cancelled_at)}
            {dateDetail('Failed At', o?.failed_at)}
          </DrawerBody>
        </DrawerContent>
      </DrawerOverlay>
    </Drawer>
  );
};

export default OrderDetails;
