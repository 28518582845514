// external links
export const BrokerAPIDocSrc = 'https://alpaca.markets/docs/broker/';
export const StatusPage = 'https://status.alpaca.markets/';
export const SupportPage = 'https://alpaca.markets/support';
export const TradingAPISrc = 'https://alpaca.markets/algotrading';

export const APILinks = {
  accounts: BrokerAPIDocSrc + 'api-references/accounts/accounts',
  funding: BrokerAPIDocSrc + 'api-references/funding/transfers',
  orders: BrokerAPIDocSrc + 'api-references/trading/orders',
  firm: BrokerAPIDocSrc + 'integration/#firm-accounts',
  transactions: BrokerAPIDocSrc + 'api-references/funding/transfers',
  journals: BrokerAPIDocSrc + 'api-references/journals',
  documents: BrokerAPIDocSrc + 'api-references/documents',
  positions: BrokerAPIDocSrc + 'api-references/trading/positions',
  activities: BrokerAPIDocSrc + 'api-references/accounts/account-activities',
};

// emails
export const SupportEmail = 'brokersupport@alpaca.markets';
