import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { Box, Flex, Spacer, useToast } from '@chakra-ui/react';
import Table from '../general/Table';
import {
  FilterDrawer,
  dateRangeToPill,
  FilterDateSelect,
  FilterNumber,
  FilterSelect,
  FilterString,
  NumRange,
  rangeToPill,
  RangeType,
  FilterButton,
  QueryKey,
} from '../filter';
import ExportToCsv from './ExportButton';
import { useInfiniteQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { Order, OrdersParams } from '../../api/types';
import { ordersToTableRows } from '../../globals/utils';
import OrderDetails from '../page/OrderDetails';
import { getOrders } from '../../api/api';
import { APILinks } from '../../globals/consts';

const QUERY_LIMIT = 100;

const OrdersTable = (props: { accountID?: string }): React.ReactElement => {
  const [filterOpen, setFilterOpen] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState<Order | undefined>();
  const toast = useToast();
  const navigate = useNavigate();
  const aID = props.accountID;

  const [statusFilter, setStatusFilter] = useState('all');
  const [sideFilter, setSideFilter] = useState('all');
  const [symbols, setSymbols] = useState('');
  const [qtyFilter, setQtyFilter] = useState<NumRange>([null, null]);
  const [selectedDates, setSelectedDates] = useState<RangeType>([null, null]);
  const [filterKey, setFilterKey] = useState<QueryKey>(['orders', aID]);
  const [applyFilter, setApplyFilter] = useState(false);

  // Paginated query
  const fetchOrders = ({ pageParam = moment() }) => {
    const params: OrdersParams = {
      status: statusFilter,
      limit: QUERY_LIMIT,
      until: pageParam.toISOString(),
      side: sideFilter,
      symbols,
    };

    // qty filter
    if (qtyFilter) {
      if (qtyFilter[0]) params.qty_above = qtyFilter[0];
      if (qtyFilter[1]) params.qty_below = qtyFilter[1];
    }

    // date filter
    if (selectedDates[0] && selectedDates[1]) {
      params.after = selectedDates[0].toISOString();
      params.until = selectedDates[1].toISOString();
    }

    return getOrders(params, aID);
  };

  const queryKeys = ['orders', statusFilter, sideFilter, symbols, qtyFilter, selectedDates, aID];
  useEffect(() => {
    if (applyFilter) setFilterKey(queryKeys);
  }, [...queryKeys, applyFilter]);

  const ordersQuery = useInfiniteQuery(filterKey, fetchOrders, {
    getNextPageParam: (lastPage) => {
      if (lastPage.length === QUERY_LIMIT) {
        return lastPage[lastPage.length - 1].submitted_at;
      }
    },
  });

  if (ordersQuery.isError) {
    toast({
      title: 'An error occurred fetching orders',
      description: (ordersQuery.error as Error).message,
      status: 'error',
    });
  }

  const orders = ordersQuery.data?.pages?.flat() || [];
  const rows = ordersToTableRows(orders, !aID);

  const openFilter = () => {
    setFilterOpen(true);
    setApplyFilter(false);
  };

  const filterPills = {
    status: statusFilter,
    side: sideFilter,
    symbols,
    qty: rangeToPill('qty', qtyFilter),
    dates: dateRangeToPill(selectedDates),
  };

  const removeFilter = (filterID: string) => {
    if (filterID === 'side') setSideFilter('all');
    if (filterID === 'status') setStatusFilter('all');
    if (filterID === 'symbols') setSymbols('');
    if (filterID === 'qty') setQtyFilter([null, null]);
    if (filterID === 'dates') setSelectedDates([null, null]);
  };
  let tableHeaders = ['Order ID', 'Side', 'Symbol', 'Quantity', 'Total', 'Status', 'Updated At'];
  const csvHeaders = ['account_id', 'id', 'side', 'symbol', 'qty', 'notional', 'status', 'updated_at'];

  const filterBtn = aID ? (
    <Box mt="-6.5rem" float="right" display="flex">
      <ExportToCsv data={orders} givenHeaders={csvHeaders} />
      <FilterButton filterPills={applyFilter ? filterPills : {}} openFilter={openFilter} removeFilter={removeFilter} />
    </Box>
  ) : (
    <Flex>
      <Spacer />
      <Box mb="1rem" display="flex">
        <ExportToCsv data={orders} givenHeaders={csvHeaders} />
        <FilterButton
          filterPills={applyFilter ? filterPills : {}}
          openFilter={openFilter}
          removeFilter={removeFilter}
        />
      </Box>
    </Flex>
  );

  if (!aID) tableHeaders = ['Account ID', ...tableHeaders];

  return (
    <>
      <OrderDetails isOpen={!!selectedOrder} onClose={() => setSelectedOrder(undefined)} order={selectedOrder} />
      <FilterDrawer
        isOpen={filterOpen}
        onClose={() => setFilterOpen(false)}
        onApply={() => {
          setApplyFilter(true);
          setFilterOpen(false);
        }}
      >
        <FilterSelect
          header="Status"
          options={['all', 'open', 'closed']}
          onSelect={setStatusFilter}
          selected={statusFilter}
        />
        <FilterSelect
          header="Side"
          options={['all', 'buy', 'sell', 'sell_short']}
          onSelect={setSideFilter}
          selected={sideFilter}
        />
        <FilterString header="Symbol" onChange={setSymbols} />
        <FilterNumber header="Qty" minRange={0} maxRange={1000} step={1} onChange={setQtyFilter} />
        <FilterDateSelect header="Updated At" onDateSelect={(st, ed) => setSelectedDates([st, ed])} />
      </FilterDrawer>
      {filterBtn}
      <Table
        isLoading={ordersQuery.isLoading}
        headers={tableHeaders}
        copyIndexes={aID ? [0] : [0, 1]}
        rightJustifyIndexes={[4, 5]}
        rows={rows}
        rowsPerPage={10}
        loadMoreData={ordersQuery.fetchNextPage}
        onValueClick={(rowIdx, colIdx) => {
          if (colIdx === 0 && !aID) navigate(`/accounts/${rows[rowIdx][0]}`);
        }}
        onRowClick={(idx) => setSelectedOrder(orders[idx])}
        noContentTitle="No orders available"
        noContentLinkText="Learn more about Orders"
        noContentLinkDestination={APILinks.orders}
      />
    </>
  );
};

export default OrdersTable;
