import React from 'react';

import { Image, useColorModeValue } from '@chakra-ui/react';

import emptyChartLight from '../../assets/images/emptyChartLight.svg';
import emptyChartDark from '../../assets/images/emptyChartDark.svg';

const EmptyChartImage = (): React.ReactElement => {
  const src = useColorModeValue(emptyChartLight, emptyChartDark);
  return <Image src={src} alt="empty chart contents" />;
};

export default EmptyChartImage;
