import React, { useContext, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import { NavLink, useLocation } from 'react-router-dom';
import { Transaction, Report, Api, Users, Wallet, Shapes } from '../../icons/Menu';
import { BrokerAPIDocSrc, StatusPage, SupportPage } from '../../globals/consts';
import {
  Box,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  HStack,
  Icon,
  Spacer,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import MenuElement from './MenuItem';
import Zendesk from 'react-zendesk';
import { AiOutlineRight } from 'react-icons/ai';
import { AppContext } from '../../globals/appcontext';
import colors from '../theme/colors';
import { Scrollbox } from '../general/ScrollBox';
import { getCorrespondent } from '../../api/api';
import { useQuery } from 'react-query';
import useWindowDimensions from '../../globals/windowdim';
import { HamburgerIcon } from '@chakra-ui/icons';
import { useEffect } from 'react';

const ZENDESK_KEY = 'd1129a4a-e01a-41e4-a34c-0a596cc59cad';
const zendeskSettings = {
  color: {
    theme: '#000',
  },
  offset: { vertical: '25px' },
  launcher: {
    chatLabel: {
      'en-US': 'Need Help',
    },
  },
  contactForm: {
    fields: [{ id: 'description', prefill: { '*': '' } }],
  },
};

const MenuContainer = styled(Box)`
  padding: 1em;
  width: ${(props) => (props.$expanded ? 288 : 96)}px;
  height: 100vh;
  transition: width 0.2s;
`;

const MenuItems = styled(Scrollbox)`
  display: flex;
  height: calc(100vh - 125px);
  overflow-y: scroll;
  flex-direction: column;
`;

const TeamBadge = styled(Box)`
  height: 28px;
  min-width: 28px;
  text-align: center;
  background: ${colors.yellow};
  border-radius: 50%;
  color: black;
  font-weight: bold;
  font-size: 18px;
`;

const Header = styled(Text)`
  font-weight: 500;
  font-size: 22px;
  max-width: 200px;
  visibility: ${(props) => (props.$hide ? 'hidden' : 'visible')};
`;

const pulseAnimation = keyframes`
  0% { -webkit-box-shadow: 0 0 0 0 rgba(0, 255, 0, 0.4); }
  70% { -webkit-box-shadow: 0 0 0 7px rgba(0, 255, 0, 0); }
  100% { -webkit-box-shadow: 0 0 0 0 rgba(0, 255, 0, 0); }
`;

const EnvBadge = styled.div`
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background-color: ${colors.lightGreen};

  box-shadow: 0 0 0 rgba(204, 169, 44, 0.4);
  border-color: black;
  animation-name: ${pulseAnimation};
  animation-duration: 2s;
  animation-iteration-count: infinite;
`;

const OpenButton = styled(Icon)<{ $flip: boolean }>`
  position: absolute;
  bottom: 10px;
  color: black;
  transform: rotate(${(props) => (props.$flip ? 180 : 0)}deg);
  padding: 5px;
  border-radius: 50%;
  font-size: 32px;
  transition-duration: 0.2s;

  &:hover {
    cursor: pointer;
    background: rgba(255, 215, 0, 0.48);
  }
`;

const MenuButton = styled(HamburgerIcon)`
  font-size: 2rem;
  position: absolute;
  margin: 25px;
  z-index: 10;
`;

interface MenuDrawerProps {
  children: React.ReactElement;
  isOpen: boolean;
  onClose: () => void;
  title: string;
}

const DrawerMenu = (props: MenuDrawerProps): React.ReactElement => {
  const winSize = useWindowDimensions();

  if (winSize === 'mobile') {
    return (
      <Drawer placement="left" onClose={props.onClose} isOpen={props.isOpen}>
        <DrawerOverlay>
          <DrawerContent>
            <DrawerCloseButton />
            <DrawerHeader borderBottomWidth="1px">{props.title}</DrawerHeader>
            <DrawerBody>{props.children}</DrawerBody>
          </DrawerContent>
        </DrawerOverlay>
      </Drawer>
    );
  }

  return props.children;
};

const defaultMenuItems = [
  { name: 'Dashboard/Overview', path: '/dashboard', icon: Shapes },
  { name: 'Firm Balance', path: '/balance', icon: Wallet },
  { name: 'Accounts', path: '/accounts', icon: Users },
  {
    name: 'Transactions',
    path: '/transactions',
    icon: Transaction,
    submenu: [
      { name: 'Orders', path: '/transactions/orders' },
      { name: 'Journals', path: '/transactions/journals' },
    ],
  },
  { name: 'Documents', path: '/documents', icon: Report },
  { name: 'API/Devs', path: '/dev', icon: Api, submenu: [{ name: 'Live Testing', path: '/dev/live-testing' }] },
];

const omnibusMenuItems = [
  { name: 'Dashboard/Overview', path: '/dashboard', icon: Shapes },
  { name: 'Firm Balance', path: '/balance', icon: Wallet },
  {
    name: 'Transactions',
    path: '/transactions',
    icon: Transaction,
    submenu: [{ name: 'Orders', path: '/transactions/orders' }],
  },
  { name: 'Documents', path: '/documents', icon: Report },
  { name: 'API/Devs', path: '/dev', icon: Api, submenu: [{ name: 'Live Testing', path: '/dev/live-testing' }] },
];

const footerItems = [
  { name: 'Team Settings', path: '/team-settings' },
  { name: 'Contact Us', path: '/contact-us' },
  { name: 'Support', path: SupportPage },
  { name: 'Documentation', path: BrokerAPIDocSrc },
  { name: 'Status Page', path: StatusPage },
];

const LeftMenu = (): React.ReactElement | null => {
  const location = useLocation();
  const [expanded, setExpanded] = useState(true);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [menuItems, setMenuItems] = useState(defaultMenuItems);
  const corrQuery = useQuery('correspondent', getCorrespondent);
  const color = useColorModeValue('black', 'white');
  const colorMode = useColorModeValue('light', 'dark');
  const winSize = useWindowDimensions();
  const p = location.pathname;

  const appContext = useContext(AppContext);
  const isLive = appContext.correspondent?.Env === 'live';
  const teamName = corrQuery.data?.name || '';

  const goLiveSideBar = p === '/go-live';

  useEffect(() => {
    const isSandbox = appContext.correspondent.Env === 'sandbox';
    const newMenuItems = appContext.correspondent.Setup === 'omnibus' ? omnibusMenuItems : defaultMenuItems;
    newMenuItems.forEach((i) => {
      if (i.name === 'API/Devs') {
        i.submenu = isSandbox ? [{ name: 'Live Testing', path: '/dev/live-testing' }] : undefined;
      }
    });
    setMenuItems(newMenuItems);
  }, [appContext.correspondent]);

  const Link = (props: { to: string; children: React.ReactElement }) => {
    if (props.to.startsWith('http'))
      return (
        <a href={props.to} target="noopener noreferrer">
          {props.children}
        </a>
      );
    return <NavLink to={props.to}>{props.children}</NavLink>;
  };

  const Menu = (
    <MenuItems colormode={colorMode}>
      <Box>
        {menuItems.map((item, idx) => (
          <MenuElement key={idx} {...item} expanded={expanded} />
        ))}
      </Box>
      <Spacer />
      <Box mb="3rem">
        {expanded &&
          footerItems?.map((item) => (
            <Link key={item.path} to={item.path}>
              <Text pt="1rem" pl="2rem" _hover={{ fontWeight: '600' }} fontWeight={p === item.path ? 'bold' : 'normal'}>
                {item.name}
              </Text>
            </Link>
          ))}
      </Box>
    </MenuItems>
  );

  // Go Live flow has a custom sidebar
  if (goLiveSideBar) {
    return null;
  }

  if (winSize === 'mobile') {
    return (
      <>
        <MenuButton onClick={() => setDrawerOpen(true)} />
        <DrawerMenu isOpen={drawerOpen} onClose={() => setDrawerOpen(false)} title={teamName}>
          {Menu}
        </DrawerMenu>
      </>
    );
  }

  return (
    <MenuContainer $expanded={expanded}>
      <Zendesk defer zendeskKey={ZENDESK_KEY} {...zendeskSettings} />
      <HStack mb="3em" mt="1.5em" ml="1em">
        <TeamBadge>{teamName.charAt(0)}</TeamBadge>
        <Header isTruncated $hide={!expanded}>
          {teamName}
        </Header>
        {isLive && expanded && <EnvBadge />}
      </HStack>

      {Menu}
      <OpenButton $flip={expanded} as={AiOutlineRight} onClick={() => setExpanded(!expanded)} color={color} />
    </MenuContainer>
  );
};

export default LeftMenu;
