import React from 'react';
import { Box, Grid, Text, Stack, HStack, useToast, GridItem, StackDirection } from '@chakra-ui/react';
import { ChevronRightIcon } from '@chakra-ui/icons';
import Header from '../components/layout/Header';
import Card from '../components/general/Card';
import NoContent from '../components/general/NoContent';
import { APILinks } from '../globals/consts';
import { firmAccountName } from '../globals/utils';
import moment from 'moment';
import number from 'numeral';
import { useQuery } from 'react-query';
import { getAccounts } from '../api/api';
import { NavLink } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import useTradingAccount from '../components/user/TradingAccount';

interface BalanceCardProps {
  title: string;
  accountNum: string;
  lastUpdate: moment.Moment;
  actID: string;
}

const BalanceCard = (props: BalanceCardProps): React.ReactElement => {
  const tradingAccountResponse = useTradingAccount(props.actID);

  // Wait for the trading account to be fetched before rendering
  // If an error does occur fetching the trading account, the rest of the page will load anyway
  if (tradingAccountResponse.isLoading) {
    return <></>;
  }

  const stackDir: StackDirection = isMobile ? ['column'] : ['row'];
  return (
    <GridItem colSpan={1}>
      <Card>
        <NavLink to={`${props.actID}`}>
          <Box p="0.5rem 1rem">
            <Text fontSize="2xl" fontWeight="600" mb="1.5rem">
              {props.title}
              <ChevronRightIcon ml="0.2rem" />
            </Text>
            <Stack direction={stackDir} spacing={isMobile ? '1rem' : '3rem'} fontWeight="500">
              <Box>
                <Text fontSize="sm" opacity="60%">
                  Acc #:
                </Text>
                <Text fontSize="lg" color="brand.600">
                  {props.accountNum}
                </Text>
              </Box>
              <Box position="relative">
                <Text fontSize="sm" opacity="60%">
                  Acc ID:
                </Text>
                <Text fontSize="lg" color="brand.600">
                  {props.actID}
                </Text>
              </Box>
              <Box flexGrow={1} textAlign={isMobile ? 'left' : 'right'}>
                <Text fontSize="sm" opacity="60%">
                  Amount
                </Text>
                <Text flex="1" fontSize="lg">
                  {number(tradingAccountResponse.data?.cash).format('$ 0,00.00')}
                </Text>
              </Box>
              <Box textAlign={isMobile ? 'left' : 'right'}>
                <Text fontSize="sm" opacity="60%">
                  Last Updated
                </Text>
                <HStack>
                  <Text display="inline" opacity="60%" mt="-7px" mb="-7px" fontSize="small">
                    {props.lastUpdate.format('hh:mm a')}
                  </Text>
                  <Text flex="1" display="inline:" fontSize="lg">
                    {props.lastUpdate.format('YYYY.MM.DD')}
                  </Text>
                </HStack>
              </Box>
            </Stack>
          </Box>
        </NavLink>
      </Card>
    </GridItem>
  );
};

const Balance = (): React.ReactElement => {
  const accountsQuery = useQuery('firm_accounts', () => getAccounts({ firmAccounts: true, status: 'ACTIVE' }));
  const toast = useToast();

  if (accountsQuery.isError)
    toast({
      title: 'An error occurred fetching firm accounts',
      description: (accountsQuery.error as Error).message,
      status: 'error',
    });

  const firmAccounts = accountsQuery.data || [];

  return (
    <Box mb="2rem">
      <Header title="Firm Balance" />
      {firmAccounts.length === 0 ? (
        <NoContent
          image="table"
          title="No firm accounts upgraded"
          linkText="Learn more about Firm Accounts"
          linkDestination={APILinks.firm}
        />
      ) : (
        <Grid templateColumns="repeat(1, 1fr)" gap="2.5rem">
          {firmAccounts.map((act) => (
            <BalanceCard
              key={act.account_number}
              title={firmAccountName(act.account_number) + ' Account'}
              accountNum={act.account_number}
              lastUpdate={act.created_at}
              actID={act.id}
            />
          ))}
        </Grid>
      )}
    </Box>
  );
};

export default Balance;
