import { extendTheme } from '@chakra-ui/react';
import { mode } from '@chakra-ui/theme-tools';
import colors from './colors';

export const theme = extendTheme({
  config: {
    initialColorMode: 'dark',
  },
  styles: {
    global: (props) => {
      const datePickerClasses = ['in-range', 'in-selecting-range', 'selected', 'keyboard-selected']
        .map((prop) => `.react-datepicker__day--${prop}, .react-datepicker__day--${prop}:hover`)
        .join(',');

      return {
        body: {
          fontFamily: 'Carnas',
          background: mode('rgb(244,246,251)', '#171719')(props),
          color: mode('black', 'white')(props),
          strokeWidth: '0px',
          fontSize: '14px',
        },
        '.editor-container': {
          borderRadius: '8px',
          overflow: 'hidden',
        },
        '.react-datepicker': {
          border: 'none',
          backgroundColor: colors.bgLight,
          borderRadius: '10px',
        },
        '.react-datepicker__header': {
          backgroundColor: colors.bgLight,
          borderBottom: 'none',
          borderRadius: '10px !important',
        },
        [datePickerClasses]: {
          backgroundColor: colors.yellow,
          color: 'black',
        },
      };
    },
  },

  // github.com/chakra-ui/chakra-ui/blob/master/packages/theme/src/foundations/colors.ts
  colors: {
    success: '#06E66D',
    warning: '#EB930F',
    error: '#EA1145',

    brand: {
      50: '#fffcea',
      200: '#ffe34d',
      300: '#ffdf33',
      400: '#ffdb1a',
      500: '#ffd700',
      600: '#e6c200',
      700: '#ccac00',
      800: '#b39700',
    },
  },

  // github.com/chakra-ui/chakra-ui/tree/master/packages/theme/src/components
  components: {
    Button: {
      defaultProps: {
        colorScheme: 'brand',
        color: 'black',
      },
      variants: {
        solid: {
          color: 'black',
        },
      },
      baseStyle: {
        borderRadius: '10px',
      },
    },
    Checkbox: {
      defaultProps: {
        colorScheme: 'brand',
      },
    },
    Radio: {
      defaultProps: {
        colorScheme: 'brand',
      },
    },
    Switch: {
      defaultProps: {
        colorScheme: 'brand',
      },
    },
    Tabs: {
      defaultProps: {
        variant: 'unstyled',
      },
    },
    Badge: {
      baseStyle: {
        borderRadius: '5px',
        padding: '4px 8px',
      },
    },
    Alert: {
      baseStyle: {
        container: {
          borderRadius: '8px',
          fontWeight: 500,
        },
      },
    },
    Slider: {
      defaultProps: {
        colorScheme: 'brand',
      },
    },
  },
});
