import { ArrowForwardIcon, QuestionOutlineIcon } from '@chakra-ui/icons';
import { Center, Box, Button, Flex } from '@chakra-ui/react';
import React from 'react';
import { NavLink } from 'react-router-dom';

const NotFound = (): React.ReactElement => {
  return (
    <Center>
      <Box mt="30vh">
        <Flex>
          <QuestionOutlineIcon fontSize="38px" color="blue.300" mr="10px" mt="4px" />
          <Box fontSize="32px">Page Not Found</Box>
        </Flex>
        <NavLink to="/dashboard">
          <Button float="right" mt="2rem" rightIcon={<ArrowForwardIcon />}>
            Dashboard
          </Button>
        </NavLink>
      </Box>
    </Center>
  );
};

export default NotFound;
