import React, { useState } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  useToast,
  Input,
  Alert,
  AlertIcon,
} from '@chakra-ui/react';
import { AuthService } from '../../auth/authServices';

interface ChangePasswordProps {
  isOpen: boolean;
  onClose: () => void;
}

const ChangePasswordModal = (props: ChangePasswordProps): React.ReactElement => {
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const toast = useToast();

  const changePassword = async () => {
    setLoading(true);
    await AuthService.changePassword(oldPassword, newPassword)
      .then(async () => {
        toast({
          title: 'Login Successful',
          status: 'success',
        });
        await AuthService.signOut();
        window.location.href = '/login?signedOut=true';
      })
      .catch((e) => {
        setError(e.message);
        setNewPassword('');
      });

    setLoading(false);
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      changePassword();
    }
  };

  return (
    <Modal isOpen={props.isOpen} onClose={props.onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Change Password</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Input
            mt="1rem"
            variant="filled"
            type="password"
            placeholder="Old password"
            size="lg"
            onChange={(event) => setOldPassword(event.currentTarget.value)}
          />
          <Input
            mt="1rem"
            variant="filled"
            type="password"
            placeholder="New password"
            size="lg"
            onChange={(event) => setNewPassword(event.currentTarget.value)}
            onKeyDown={handleKeyDown}
          />
          {error && (
            <Alert status="error" mt="1rem">
              <AlertIcon />
              {error}
            </Alert>
          )}
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="blue" variant="outlined" mr={3} onClick={props.onClose}>
            Cancel
          </Button>
          <Button isLoading={loading} onClick={changePassword}>
            Ok
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ChangePasswordModal;
