import React from 'react';
import { Box, HStack, Text } from '@chakra-ui/react';
import numeral from 'numeral';
import useWindowDimensions from '../../globals/windowdim';

interface StatProps {
  change?: number;
  changeInfo?: string;
  stat: number;
  statInfo: string;
  isMoney?: boolean;
}

const Stat = (props: StatProps): React.ReactElement => {
  const format = props.isMoney ? '$0,0' : '0,0';
  const winSize = useWindowDimensions();

  let statSize = '34px';
  switch (winSize) {
    case 'small':
      statSize = '3xl';
      break;
    case 'mobile':
      statSize = 'xl';
  }
  statSize = '34px';

  const isMobile = winSize === 'mobile';

  return (
    <Box>
      <Text opacity="60%">{props.statInfo}</Text>
      <Text fontSize={statSize} fontWeight={700}>
        {numeral(props.stat).format(format)}
      </Text>
      {props.change || props.change === 0 ? (
        <HStack>
          <Text color="success">{numeral(props.change).format('+0.00 %')}</Text>
          {!isMobile && <Text color="grey">{props.changeInfo}</Text>}
        </HStack>
      ) : (
        <Box h="27px" />
      )}
    </Box>
  );
};

export default Stat;
