import React from 'react';
import { Spinner } from '@chakra-ui/react';
import styled from 'styled-components';

const StyledLoader = styled(Spinner)`
  position: fixed;
  top: 50%;
  left: 50%;
`;

export const Loader = (): React.ReactElement => {
  return <StyledLoader label="loading-spinner" size="xl" />;
};
