import React, { useState, useContext } from 'react';
import {
  Box,
  FormControl,
  FormLabel,
  Input,
  Button,
  InputGroup,
  InputRightElement,
  Text,
  Center,
  useToast,
  Alert,
  HStack,
  BoxProps,
} from '@chakra-ui/react';
import styled from 'styled-components';
import { useIntercom } from 'react-use-intercom';
import { CloseIcon, ViewIcon, ViewOffIcon } from '@chakra-ui/icons';
import { AuthService } from '../../auth/authServices';
import { useNavigate, NavLink } from 'react-router-dom';
import colors from '../theme/colors';
import { AppContext } from '../../globals/appcontext';
import { isMobile } from 'react-device-detect';

const HidePasswordButton = styled(Box)`
  color: rgba(0, 0, 0, 0.5);
  font-size: 18px;

  &:hover {
    color: black;
    cursor: pointer;
  }
`;

const LoginLink = styled(NavLink)`
  color: ${colors.yellow};
  margin-left: 10px;
  font-weight: 500;

  &:hover {
    border-bottom: solid 2px ${colors.yellow};
  }
`;

const Signup = (props: BoxProps): React.ReactElement => {
  const appContext = useContext(AppContext);
  const navigate = useNavigate();
  const toast = useToast();
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const [validationErrors, setValErrors] = useState<string[]>([]);

  // Hide Intercom on signup page
  const { hide } = useIntercom();
  hide();

  const updatePassword = (value: string) => {
    setPassword(value);
    const validationErrors: string[] = [];

    if (value.length < 12) {
      validationErrors.push('Password must be at least 12 characters');
    }

    if (value.toLowerCase() === value) {
      validationErrors.push('Password must include uppercase characters');
    }

    if (value.toUpperCase() === value) {
      validationErrors.push('Password must include lowercase characters');
    }

    if (!/\d/.test(value)) {
      validationErrors.push('Password must contain a number');
    }

    setValErrors(validationErrors);
  };

  const updateName = (value: string) => {
    setName(value);
    const validationErrors: string[] = [];

    if (!/^[a-zA-Z]{2,} [a-zA-Z]{2,}$/.test(value)) {
      validationErrors.push('Please enter a valid full name of at least 2 characters in both first and last name');
    }

    setValErrors(validationErrors);
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    setIsLoading(true);

    AuthService.register(email, password)
      .then(() => {
        toast({
          title: 'Sign up successful',
          status: 'success',
        });

        appContext.clear();
        setRedirect(true);
      })
      .catch((e) => {
        toast({
          title: 'An error occured during sign up',
          description: e.message,
          status: 'error',
        });
        setIsLoading(false);
      });
  };

  if (redirect) {
    navigate('/verification', { state: { name, email, password } });
  }

  const handlePasswordVisibility = () => setShowPassword(!showPassword);
  const headerFontSize = isMobile ? '3xl' : '4xl';

  return (
    <Box {...props}>
      <Center>
        <Box>
          <Center fontSize={headerFontSize} mb="2rem">
            Create your Broker API account
          </Center>
          <form onSubmit={handleSubmit}>
            <FormControl isRequired mt={6}>
              <FormLabel>Full Name</FormLabel>
              <Input
                variant="filled"
                type="text"
                size="lg"
                onChange={(event) => updateName(event.currentTarget.value)}
              />
            </FormControl>
            <FormControl isRequired mt={6}>
              <FormLabel>Email</FormLabel>
              <Input
                variant="filled"
                type="email"
                size="lg"
                onChange={(event) => setEmail(event.currentTarget.value)}
              />
            </FormControl>
            <FormControl isRequired mt={6}>
              <FormLabel>Password</FormLabel>
              <InputGroup>
                <Input
                  variant="filled"
                  type={showPassword ? 'text' : 'password'}
                  size="lg"
                  onChange={(event) => updatePassword(event.currentTarget.value)}
                />
                <InputRightElement width="3rem">
                  <HidePasswordButton onClick={handlePasswordVisibility}>
                    {showPassword ? <ViewOffIcon /> : <ViewIcon />}
                  </HidePasswordButton>
                </InputRightElement>
              </InputGroup>
            </FormControl>
            {validationErrors.length > 0 && (
              <Alert status="error" mt="2rem">
                <Box>
                  {validationErrors.map((err) => (
                    <HStack key={err}>
                      <CloseIcon color="error" mr="5px" />
                      <Text color="error">{err}</Text>
                    </HStack>
                  ))}
                </Box>
              </Alert>
            )}
            <Button
              size="lg"
              type="submit"
              width="full"
              mt="4rem"
              isLoading={isLoading}
              disabled={validationErrors.length > 0}
            >
              Sign up
            </Button>
            <Center mt="3rem" fontSize="lg">
              <Text color="grey" mr="5px">
                Already have a Broker API account?
                <LoginLink to="/login">Log in</LoginLink>
              </Text>
            </Center>
          </form>
        </Box>
      </Center>
    </Box>
  );
};

export default Signup;
