import React from 'react';
import Header from '../components/layout/Header';
import TransactionsTable from '../components/tables/TransactionsTable';

const Transactions = (): React.ReactElement => {
  return (
    <>
      <Header title="Transactions" />
      <TransactionsTable />
    </>
  );
};

export default Transactions;
