import React, { useState } from 'react';
import { Box, FormControl, FormLabel, Input, Button, Text, Center, useToast, BoxProps } from '@chakra-ui/react';
import { Navigate, NavLink } from 'react-router-dom';
import { AuthService } from '../../auth/authServices';

const ResetPassword = (props: BoxProps): React.ReactElement => {
  const toast = useToast();
  const [email, setEmail] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const [unverified, setUnverified] = useState(false);

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    setIsLoading(true);

    AuthService.forgotPassword(email)
      .then(() => {
        toast({
          title: 'Reset code sent to your email',
          status: 'success',
        });
        setRedirect(true);
      })
      .catch((e) => {
        // for the case where a user wants to reset their password before having verified their email
        // they'll be redirected to verify their account before coming back to this page again.
        if (e.message.includes('no registered/verified email or phone_number')) {
          AuthService.resendConfirmationCode(email).then(() => {
            setUnverified(true);
            setRedirect(true);
          });
        } else {
          toast({
            title: 'An error occured resetting your password',
            description: e.message,
            status: 'error',
          });
          setIsLoading(false);
        }
      });
  };

  if (unverified) {
    return <Navigate to="/verification" state={{ email, unverified }} />;
  }

  if (redirect) {
    return <Navigate to="/set-password" state={{ email }} />;
  }

  return (
    <Box {...props}>
      <Center>
        <Box>
          <Text fontSize="4xl" mb="2rem">
            Reset your password
          </Text>
          <form onSubmit={handleSubmit}>
            <FormControl isRequired mt={6}>
              <FormLabel>Email</FormLabel>
              <Input
                variant="filled"
                type="email"
                size="lg"
                onChange={(event) => setEmail(event.currentTarget.value)}
              />
            </FormControl>
            <Button size="lg" type="submit" width="full" mt="4rem" isLoading={isLoading}>
              Get reset code
            </Button>
            <Center mt="3rem">
              <NavLink to="/login">
                <Text fontSize="xl">Cancel</Text>
              </NavLink>
            </Center>
          </form>
        </Box>
      </Center>
    </Box>
  );
};

export default ResetPassword;
