export const foreignEntityCopy =
  'If your company is registered as or operating under a different name and/or address or may be found in corporate databases with a different name and/or address than what is listed above, please provide that information here. For example, if your company is registered in Delaware but also registered in California or another state as a “foreign entity”, please fill this section out.';

export const primaryControlPersonCopy =
  'The Primary Control Person should be authorized to make decisions on behalf of the company and have significant responsibility for controlling the entity (CEO, President, etc.)';

export const appFocusCopy =
  'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.';

export const alpacaAuthorizeCopy =
  'I hereby authorize Alpaca Securities LLC and/or any entity directed by Alpaca Securities LLC to obtain a background investigation report about me. I understand that a “background investigation report” may include information regarding my credit history (including residential address history). I further understand and agree that a background investigation report may be obtained at any time, and any number of times, as Alpaca Securities LLC in its sole discretion determines is necessary before, during or after my involvement with Alpaca Securities LLC. I hereby authorize all reporting agencies and other persons or entities having information about me to provide such information to Alpaca Securities LLC or other entity that obtains information for Alpaca Securities LLC. I further fully release Alpaca Securities LLC, its employees, officers, directors, agents, successors and assigns, and all other parties involved in this background investigation, including but not limited to investigators, credit agencies and those companies or individuals who provide information to Alpaca Securities LLC concerning me, from any claims or actions for any liability whatsoever related to the process or results of the background investigation.';

export const formHalfWidth = '282px';
export const formThirdWidth = '188px';

export const phoneNumberRegex = /^[0-9+ .-]*$/;
export const dateRegex = /^\d{4}\-(0?[1-9]|1[012])\-(0?[1-9]|[12][0-9]|3[01])$/;
export const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
