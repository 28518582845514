import React from 'react';
import Header from '../components/layout/Header';
import DocumentsTable from '../components/tables/DocumentsTable';

const Documents = (): React.ReactElement => {
  return (
    <>
      <Header title="Documents" />
      <DocumentsTable />
    </>
  );
};

export default Documents;
