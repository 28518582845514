import React, { useState } from 'react';
import { useQuery } from 'react-query';
import {
  Button,
  Checkbox,
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  FormLabel,
  Grid,
  GridItem,
  Input,
  Select,
  useToast,
} from '@chakra-ui/react';

import { getSelf, inviteTeamMember } from '../../api/api';
import { UserInvitation } from '../../api/types';

interface NewUserProps {
  isOpen: boolean;
  onClose: () => void;
}

const labelStyle = {
  fontSize: '0.8em',
  opacity: '0.9',
  marginLeft: '2em',
};

const defaultRole = 'operations';

const NewUserForm = (props: NewUserProps): React.ReactElement => {
  const { data: authUser } = useQuery('self', () => getSelf());
  const { isOpen, onClose } = props;
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [role, setRole] = useState(defaultRole);
  const [sendAnother, setSendAnother] = useState(false);
  const toast = useToast();

  const submitNewUser = () => {
    if (!email || email === '') {
      toast({
        title: 'Email required',
        description: 'Please enter an email address',
        status: 'warning',
      });
      return;
    } else if (!role || role === '') {
      toast({
        title: 'Role required',
        description: 'Please select a role for the new user',
        status: 'warning',
      });
      return;
    }

    const inv: UserInvitation = {
      email: email,
      role: role,
      name: name,
    };
    inviteTeamMember(inv)
      .then(() => {
        setEmail('');
        setRole(defaultRole);
        if (!sendAnother) {
          onClose();
        }
      })
      .catch((e) => {
        console.warn(e);
        toast({
          title: 'Error Inviting User',
          description: 'There was an error encountered sending user invitation. Please try again soon',
          status: 'error',
        });
      });
  };

  return (
    <Drawer isOpen={isOpen} placement="right" onClose={onClose} size="md">
      <DrawerOverlay zIndex={999999}>
        <DrawerContent>
          <DrawerHeader>Add team member</DrawerHeader>
          <DrawerCloseButton />

          <DrawerBody>
            <FormLabel style={labelStyle}>Name</FormLabel>
            <Input
              variant="filled"
              style={{ marginBottom: '2em' }}
              placeholder="Name..."
              type="name"
              size="lg"
              value={name}
              onChange={(event) => setName(event.currentTarget.value)}
            />
            <FormLabel style={labelStyle}>Email</FormLabel>
            <Input
              variant="filled"
              style={{ marginBottom: '2em' }}
              placeholder="Email..."
              type="email"
              size="lg"
              value={email}
              onChange={(event) => setEmail(event.currentTarget.value)}
            />

            <FormLabel style={labelStyle}>Role</FormLabel>
            <Select
              variant="filled"
              onChange={(event) => setRole(event.currentTarget.value)}
              value={role}
              placeholder="Role..."
              size="lg"
            >
              <option disabled={(authUser || {}).role !== 'superuser'} value="superuser">
                Super User
              </option>
              <option value="developer">Developer (Admin)</option>
              <option value="operations">Operations</option>
            </Select>
          </DrawerBody>

          <DrawerFooter>
            <Grid width="100%" templateColumns="repeat(10, 1fr)">
              <GridItem colSpan={5}>
                <Checkbox onChange={(event) => setSendAnother(event.target.checked)}>Add another user</Checkbox>
              </GridItem>
              <GridItem colSpan={5}>
                <Button float="right" variant="link" onClick={() => submitNewUser()}>
                  Send Request
                </Button>
              </GridItem>
            </Grid>
          </DrawerFooter>
        </DrawerContent>
      </DrawerOverlay>
    </Drawer>
  );
};

export default NewUserForm;
