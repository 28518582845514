import React, { useState, useEffect } from 'react';
import {
  Box,
  GridItem,
  HStack,
  VStack,
  Text,
  Input,
  Select,
  Button,
  CloseButton,
  useColorModeValue,
  Icon,
  Image,
  useColorMode,
} from '@chakra-ui/react';
import { ChevronLeftIcon } from '@chakra-ui/icons';
import { AppContext } from '../../globals/appcontext';
import { updateCorrespondent } from '../../api/api';
import styled from '@emotion/styled';
import countries from '../../globals/countries';
import colors from '../theme/colors';
import { AlpacaSVG, AlpacaDarkSVG } from '../../icons/Login';
import greenCheck from '../../assets/images/greenCheck.svg';
import { UseQueryResult } from 'react-query';
import { CorrespondentResponse } from '../../api/types';

const Banner = styled(Box)`
  height: ${(props) => (props.done ? '196px' : '190px')};
  width: 100%;
  border-radius: ${(props) => (props.done ? '8px 8px' : '0px 0px')} 8px 8px;
  background-color: ${(props) =>
    props.colorMode === 'light' ? colors.cardBackgroundLight : colors.cardBackgroundDark};
  padding: 20px 26px 20px 28px;
`;

const ProgressBar = styled(Box)`
  width: ${(props) => props.percent}%;
  height: 6px;
  background-color: ${colors.yellow};
  border-radius: 8px ${(props) => (props.percent === 100 ? '8px' : '0px')} 0px 0px;
`;

const OptionPill = styled(Button)`
  height: 36px;
  width: 137px;
  border-radius: 8px;
  background-color: ${(props) => (props.active ? '#F6D00012' : props.colorMode === 'light' ? '#e2e8f0' : '#35383d')};
  color: ${(props) => (props.active ? '#F6D000' : props.colorMode === 'light' ? 'black' : '#ffffff95')};
  border-color: ${(props) => (props.active ? '#F6D000' : '')};
  border-width: ${(props) => (props.active ? '1px' : '')};
  :focus {
    outline: 0 !important;
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0) !important;
  }
  :hover {
    color: black;
  }
`;

const PaginationPrev = styled(ChevronLeftIcon)`
  font-size: 22px;
  &:hover {
    cursor: pointer;
  }
`;

const PaginationNext = styled(PaginationPrev)`
  transform: rotate(180deg);
`;

const AlpacaIconBox = styled(Box)`
  @media (max-width: 1325px) {
    display: none;
  }
`;

const FinishedBox = () => {
  const src = useColorModeValue(greenCheck, greenCheck);
  return (
    <HStack ml="28px">
      <Image src={src} boxSize="80px" />
      <VStack alignItems="start" ml="40px !important">
        <Text fontSize="24px" fontWeight="medium">
          Thank you!
        </Text>
        <Text fontSize="16px" fontWeight="medium" color="gray">
          Feel free to email us anytime at
        </Text>
        <Text fontSize="16px" fontWeight="medium" color={colors.yellow}>
          support@alpaca.markets
        </Text>
      </VStack>
    </HStack>
  );
};

const AlpacaLeft = (done: boolean) => {
  const svg = useColorModeValue(AlpacaSVG, AlpacaDarkSVG);
  return (
    <AlpacaIconBox position="absolute" right="25rem" top={done ? '4.75rem ' : '4.3rem'}>
      <Icon as={svg} fontSize="100px" />
    </AlpacaIconBox>
  );
};

const AlpacaRight = (done: boolean) => {
  const svg = useColorModeValue(AlpacaSVG, AlpacaDarkSVG);
  return (
    <AlpacaIconBox position="absolute" right="19rem" top={done ? '1rem ' : '0.55rem'}>
      <Icon as={svg} fontSize="160px" transform="scaleX(-1)" />
    </AlpacaIconBox>
  );
};

const UserCounts = ['< 1,000', '1,001 - 5,000', '5,001 - 25,000', '> 25,001'];
const TradingServices = ['Focus of app', 'Add-on'];

interface BannerStep {
  question: string;
  responseElement: React.ReactElement;
  response: number | string | null;
  tooltip?: string;
}

interface SignupBannerProps {
  colSpan: number;
  onClose: React.Dispatch<React.SetStateAction<boolean>>;
  corr: UseQueryResult<CorrespondentResponse, unknown>;
}

const SignupBanner = (props: SignupBannerProps): React.ReactElement => {
  const [step, setStep] = useState(0);
  const [url, setUrl] = useState('');
  const [location, setLocation] = useState('');
  const [userCount, setUserCount] = useState<number | null>(null);
  const [tradingService, setTradingService] = useState<number | null>(null);
  const [replacingProduct, setReplacingProduct] = useState<number | null>(null);
  const appContext = React.useContext(AppContext);
  const { colorMode } = useColorMode();

  // used for rendering of steps 3-5 which consists of pill selection buttons
  const pillCallbackFunctions = [setUserCount, setTradingService, setReplacingProduct];
  const pillStates = [userCount, tradingService, replacingProduct];

  const submitBdData = async () => {
    await updateCorrespondent({
      ...appContext.correspondent,
      BdData: {
        url,
        app_type: TradingServices[tradingService as number],
        number_of_users: UserCounts[userCount as number],
        integration: replacingProduct === 0 ? 'not_replacement' : 'is_replacement',
        user_countries: [location],
      },
    });
  };
  useEffect(() => {
    if (step === stepList.length) {
      submitBdData();
    }
  }, [step]);

  const optionPill = (text: string, idx: number) => (
    <OptionPill
      ml={idx > 0 ? '20px !important' : '0px'}
      onClick={() => pillCallbackFunctions[step - 2](idx)}
      active={idx === pillStates[step - 2]}
      colorMode={colorMode}
    >
      <Text fontWeight="medium" fontSize="15px">
        {text}
      </Text>
    </OptionPill>
  );

  const optionPillRow = (options: string[]) => <HStack>{options.map((value, idx) => optionPill(value, idx))}</HStack>;

  const urlInput = (
    <Input
      size="md"
      maxWidth="508px"
      value={url}
      onChange={(event) => setUrl(event.currentTarget.value)}
      variant="filled"
      placeholder="https://"
    />
  );

  const countrySelect = (
    <Select
      placeholder="eg: United States"
      variant="filled"
      onChange={(event) => setLocation(event.currentTarget.value)}
      value={location}
      w="344px"
    >
      {countries.map(({ label, value }) => (
        <option key={label} value={value}>
          {label}
        </option>
      ))}
    </Select>
  );

  const userCountRow = optionPillRow(UserCounts);

  const tradingServicesRow = optionPillRow(TradingServices);

  const replacingProductRow = optionPillRow(['No', 'Yes']);

  const stepList: BannerStep[] = [
    { question: 'Company URL', responseElement: urlInput, response: url },
    { question: 'Where are you located?', responseElement: countrySelect, response: location },
    { question: 'How many users do you currently have?', responseElement: userCountRow, response: userCount },
    {
      question: 'Will trading services provided by Alpaca be the focus of the app or a simple add-on?',
      responseElement: tradingServicesRow,
      response: tradingService,
    },
    {
      question: 'Are you replacing another platform/product with Alpaca Broker API?',
      responseElement: replacingProductRow,
      response: replacingProduct,
    },
  ];

  const currentStep = stepList[step];
  const currentResp = currentStep?.response;

  const nextEnabled = currentResp !== null && currentResp !== '' && step < stepList.length;
  const pagination = (
    <HStack>
      <PaginationPrev
        onClick={() => {
          if (step > 0) {
            setStep(step - 1);
          }
        }}
        color={colors.yellow}
      />
      <Text>
        {step + 1} {'/'} {stepList.length}
      </Text>
      <PaginationNext
        onClick={() => {
          if (nextEnabled) {
            setStep(step + 1);
          }
        }}
        color={nextEnabled ? colors.yellow : colors.gray}
      />
    </HStack>
  );

  const progressPercent = Math.floor(((step + 1) / stepList.length) * 100);
  const done = step === stepList.length;
  return (
    <GridItem colSpan={props.colSpan}>
      {!done && <ProgressBar percent={progressPercent} />}
      <Banner done={done} colorMode={colorMode}>
        <HStack>
          {!done && (
            <HStack mr="auto">
              <Text fontSize="23px" fontWeight="medium">
                Tell us about your company
              </Text>
              {pagination}
            </HStack>
          )}
          <HStack width="max-content" ml="auto" position="relative">
            {AlpacaLeft(done)}
            {AlpacaRight(done)}
            <Text fontSize="16px" fontWeight="light">
              {done ? 'Close' : 'Skip for now'}
            </Text>
            <CloseButton
              onClick={() => {
                props.onClose(true);
                props.corr.refetch();
              }}
            />
          </HStack>
        </HStack>
        {done && <FinishedBox />}
        {!done && (
          <VStack alignItems="start">
            <Text fontSize="18px" fontWeight="normal" ml="1rem" mt="32px">
              {currentStep.question}
            </Text>
            {currentStep.responseElement}
          </VStack>
        )}
      </Banner>
    </GridItem>
  );
};

export default SignupBanner;
