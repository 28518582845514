import React from 'react';
import { Text, VStack, Image } from '@chakra-ui/react';
import greenCheckAlt from '../../assets/images/greenCheckAlt.svg';

const Summary = (): React.ReactElement => {
  return (
    <VStack w="100%" mb="44px" spacing="2rem" align="center" textAlign="center">
      <Text fontSize="20px" fontWeight="bold">
        We have received your request to move from Sandbox into Production!
      </Text>
      <Image src={greenCheckAlt} width="400" />
      <Text fontSize="16px">We will be in touch via email after we process your application.</Text>
    </VStack>
  );
};

export default Summary;
