import { Auth } from 'aws-amplify';
import axios, { Method } from 'axios';
import moment from 'moment';
import {
  Account,
  Activity,
  ApiKey,
  ClaimInviteRequest,
  CorrespondentResponse,
  Document,
  DocumentDownloadParams,
  DocumentsParams,
  EditTeamMemberRequest,
  Journal,
  JournalsParams,
  ListActivitiesParams,
  Order,
  OrdersParams,
  RegistrationRequest,
  Stats,
  TeamMember,
  Transfer,
  TransfersParams,
  Position,
  UserInvitation,
  AccountsParams,
  Generic,
  PositionsQuery,
  UpdateCorrespondentRequest,
  BusinessType,
  CreateEntityDetailsRequest,
  EntityDetailsResponse,
  CreateControlPersonRequest,
  CreateControlPersonResponse,
  UploadZendeskTicketAttachmentResponse,
  CreateZendeskTicketRequest,
  CreateZendeskTicketResponse,
  TradingAccount,
} from './types';

import { Correspondent } from '../globals/appcontext';

const sandbox = `${process.env.REACT_APP_SANDBOX_API_HOST}/internal/dash`;
const prod = `${process.env.REACT_APP_PROD_API_HOST}/internal/dash`;
const brokerAPI = `${process.env.REACT_APP_PROD_API_HOST}/dash`;
const brokerAPISandbox = `${process.env.REACT_APP_SANDBOX_API_HOST}/dash`;
const paciam = `${process.env.REACT_APP_PACIAM_API_HOST}/api`;

// Leave commented for now, see ENG-9457
// The timeout will be 0, which means the request will never timeout
// axios.defaults.timeout = 10000;
axios.defaults.headers = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
};

const instance = axios.create({ baseURL: sandbox });
const brokerAPIInstance = axios.create({ baseURL: brokerAPISandbox });
const iamInstance = axios.create({ baseURL: paciam });

const zendeskInstance = axios.create({ baseURL: 'https://alpacahelp.zendesk.com/api/v2' });

export const setEnv = (c: Correspondent): void => {
  setCorrespondentHeader(c.ID || '', c.Env || '');
  switch (c.Env) {
    case 'live':
      instance.defaults.baseURL = prod;
      brokerAPIInstance.defaults.baseURL = brokerAPI;
      break;
    default:
      instance.defaults.baseURL = sandbox;
      brokerAPIInstance.defaults.baseURL = brokerAPISandbox;
      break;
  }
};

export const getEnv = (): string => {
  switch (instance.defaults.baseURL) {
    case prod:
      return 'live';
    default:
      return 'sandbox';
  }
};

export const setAuthHeader = async (): Promise<void> => {
  try {
    const currentSession = await Auth.currentSession();
    const idToken = currentSession.getIdToken();
    iamInstance.defaults.headers['Authorization'] = `Bearer ${idToken.getJwtToken()}`;
    instance.defaults.headers['Authorization'] = `Bearer ${idToken.getJwtToken()}`;
    brokerAPIInstance.defaults.headers['Authorization'] = `Bearer ${idToken.getJwtToken()}`;
  } catch (err) {
    window.location.href = '/login';
  }
};

export const setCorrespondentHeader = (id: string, env: string): void => {
  const isSandbox = env === 'sandbox';
  iamInstance.defaults.headers['X-Correspondent'] = btoa(JSON.stringify({ ID: id, Sandbox: isSandbox }));
  instance.defaults.headers['X-Correspondent'] = btoa(JSON.stringify({ ID: id, Sandbox: isSandbox }));
  brokerAPIInstance.defaults.headers['X-Correspondent'] = btoa(JSON.stringify({ ID: id, Sandbox: isSandbox }));
};

export const getAccounts = async (params: AccountsParams = { firmAccounts: false }): Promise<Account[]> => {
  let queryParams = `?firm_accounts=${Boolean(params.firmAccounts)}`;
  queryParams += '&entities=contact,identity';

  for (const key of Object.keys(params)) {
    if (key === 'status') {
      if (params.status !== 'All') queryParams += `&status=${params.status}`;
    } else {
      queryParams += `&${key}=${(params as Generic)[key]}`;
    }
  }

  const res = await instance.get<Account[]>(`/accounts${queryParams}`);

  // convert dates
  return res.data.map((a: Account) => {
    a.created_at = moment(a.created_at);
    a.email = a.contact?.email_address || '';
    a.name = '';
    if (a.identity) a.name = `${a.identity?.given_name} ${a.identity?.family_name}`;
    return a;
  });
};

export const getActivities = async (params: ListActivitiesParams = {}, type?: string): Promise<Activity[]> => {
  const res = await instance.get<Activity[]>(`/accounts/activities/${type ?? ''}`, { params });

  return res.data.map((a) => {
    if (a.transaction_time) {
      a.transaction_time = moment(a.transaction_time);
    }
    return a;
  });
};

export const getPositions = async (params: PositionsQuery, accountID: string): Promise<Position[]> => {
  if (Array.isArray(params.symbols)) {
    params.symbols = params.symbols.join(',');
  }

  const res = await instance.get<Position[]>(`trading/accounts/${accountID}/positions`, { params });
  return res.data;
};

export const getOrders = async (params: OrdersParams = {}, accountID?: string): Promise<Order[]> => {
  if (Array.isArray(params.symbols)) {
    params.symbols = params.symbols.join(',');
  }

  if (params.side === 'all') {
    delete params.side;
  }

  let res;
  if (accountID) {
    res = await instance.get<Order[]>(`trading/accounts/${accountID}/orders`, { params });
  } else {
    res = await instance.get<Order[]>('/orders', { params });
  }

  return res.data.map((order) => {
    if (order.submitted_at) order.submitted_at = moment(order.submitted_at);
    if (order.created_at) order.created_at = moment(order.created_at);
    if (order.updated_at) order.updated_at = moment(order.updated_at);
    if (order.filled_at) order.filled_at = moment(order.filled_at);
    if (order.expired_at) order.expired_at = moment(order.expired_at);
    if (order.cancelled_at) order.cancelled_at = moment(order.cancelled_at);
    if (order.failed_at) order.failed_at = moment(order.failed_at);

    order.account_id = order.account_id || '';

    if (!order.notional) {
      const qty = order.qty || order.filled_qty;
      order.notional = qty * order.filled_avg_price;
    }

    return order;
  });
};

export const getTransfers = async (params: TransfersParams = {}, accountID?: string): Promise<Transfer[]> => {
  // remove `all` selections
  for (const key of Object.keys(params)) {
    if ((params as Generic)[key] === 'all') {
      delete (params as Generic)[key];
    }
  }

  let res;
  if (accountID) {
    res = await instance.get<Transfer[]>(`/accounts/${accountID}/transfers`, { params });
  } else {
    res = await instance.get<Transfer[]>('/transfers', { params });
  }

  return res.data.map((transfer) => {
    if (transfer.created_at) transfer.created_at = moment(transfer.created_at);
    if (transfer.updated_at) transfer.updated_at = moment(transfer.updated_at);
    if (transfer.expires_at) transfer.expires_at = moment(transfer.expires_at);
    return transfer;
  });
};

export const getStats = async (): Promise<Stats> => {
  const res = await instance.get<Stats>('/stats');
  Object.entries(res.data.accounts_created_30d).map((entry) => {
    res.data.accounts_created_30d[entry[0]] = parseInt(entry[1] as string);
  });
  return res.data;
};

export const checkSeedEnv = async (): Promise<boolean> => {
  try {
    await instance.get('/check-populate-env');
    return true;
  } catch {
    return false;
  }
};

export const checkResetEnv = async (): Promise<boolean> => {
  try {
    await instance.get('/check-reset-env');
    return true;
  } catch {
    return false;
  }
};

export const seedEnv = async (): Promise<void> => {
  await instance.post('/populate-env');
};

export const resetEnv = async (): Promise<void> => {
  await instance.post('/reset-env');
};

export const createApiKey = async (): Promise<ApiKey> => {
  const res = await instance.post<ApiKey>('/api-keys');
  res.data.created_at = moment(res.data.created_at);
  return res.data;
};

export const fetchKeys = async (): Promise<ApiKey[]> => {
  const res = await instance.get<ApiKey[]>('/api-keys');
  return res.data.map((key) => {
    key.created_at = moment(key.created_at);
    return key;
  });
};

export const disableKey = async (keyId: string): Promise<void> => {
  await instance.delete(`/api-keys/${keyId}`);
};

export const getAccount = async (accountID: string): Promise<Account> => {
  const res = await instance.get<Account>(`/accounts/${accountID}`);
  return res.data;
};

export const getDocuments = async (params: DocumentsParams, accountID?: string): Promise<Document[]> => {
  let res;
  if (accountID) {
    res = await instance.get<Document[]>(`/accounts/${accountID}/documents`, { params });
  } else {
    res = await instance.get<Document[]>('/documents', { params });
  }
  return res.data;
};

export const docDownloadLink = async (params: DocumentDownloadParams): Promise<void> => {
  await instance
    .get(`/accounts/${params.account_id}/documents/${params.document_id}/download?redirect=${params.redirect}`, {
      headers: { Accept: 'application/pdf' },
    })
    .then((res) => {
      window.open(res.data);
    });
};

export const getJournals = async (params: JournalsParams): Promise<Journal[]> => {
  if (params.status === 'all') {
    delete params.status;
  }

  const res = await instance.get<Journal[]>('/journals', { params });
  return res.data.map((journal) => {
    if (journal.settle_date) journal.settle_date = moment(journal.settle_date);
    if (journal.system_date) journal.system_date = moment(journal.system_date);
    return journal;
  });
};

export const getSelf = async (): Promise<TeamMember> => {
  const res = await iamInstance.get<TeamMember>(`/users/self`);
  return res.data;
};

export const getTeamMembers = async (): Promise<TeamMember[]> => {
  const res = await iamInstance.get<TeamMember[]>(`/users`);
  return res.data;
};

export const getTeamMember = async (userID: string): Promise<TeamMember> => {
  const res = await iamInstance.get<TeamMember>(`/users/${userID}`);
  const member = res.data;
  member.created_at = moment(member.created_at);
  member.updated_at = moment(member.updated_at);
  return member;
};

export const inviteTeamMember = async (inv: UserInvitation): Promise<TeamMember> => {
  const res = await iamInstance.post<TeamMember>(`/users/invite`, inv);
  return res.data;
};

export const claimInvite = async (req: ClaimInviteRequest): Promise<TeamMember> => {
  const res = await iamInstance.patch<TeamMember>(`/users`, req);
  return res.data;
};

export const createAccount = async (req: RegistrationRequest): Promise<TeamMember> => {
  const res = await iamInstance.post<TeamMember>(`/users`, req);
  return res.data;
};

export const editTeamMember = async (userID: string, name: string, role: string): Promise<TeamMember> => {
  const req: EditTeamMemberRequest = { name, role };
  const res = await iamInstance.patch<TeamMember>(`/users/${userID}`, req);
  return res.data;
};

export const removeTeamMember = async (userID: string): Promise<void> => {
  await iamInstance.delete<TeamMember>(`/users/${userID}/permission`);
};

export const createCorrespondent = async (
  name: string,
  env: string,
  code?: string,
  sandboxCode?: string,
): Promise<CorrespondentResponse> => {
  switch (env) {
    case 'live':
      // used for the Go Live flow where we want to create an entry for correspondents in prod w/ status Onboarding
      instance.defaults.baseURL = prod;
      const liveRes = await instance.post<CorrespondentResponse>('/correspondent', {
        name,
        setup: 'fully-disclosed',
        correspondent: code,
        sandbox_code: sandboxCode,
      });

      // set back to sandbox afterwards
      instance.defaults.baseURL = sandbox;
      return liveRes.data;
    default:
      const sandboxRes = await iamInstance.post<CorrespondentResponse>('/correspondents', { name });
      return sandboxRes.data;
  }
};

export const updateCorrespondent = async (corr: Correspondent): Promise<CorrespondentResponse> => {
  const req: UpdateCorrespondentRequest = {
    business_type: corr.BusinessType as BusinessType,
    name: corr.Name,
    user_countries: corr.UserCountries,
    bd_data: corr.BdData,
  };
  await iamInstance.patch<CorrespondentResponse>('/correspondents', req);
  const res = await instance.patch<CorrespondentResponse>('/correspondent', req);
  return res.data;
};

export const getCorrespondent = async (): Promise<CorrespondentResponse> => {
  const res = await instance.get<CorrespondentResponse>('/correspondent');
  return res.data;
};

export const brokerAPIRequest = async <T>(method: string, path: string, data?: string): Promise<T> => {
  let url = path;
  if (path.startsWith('/v1')) {
    url = path.substring(3);
  }
  const m: Method = method as Method;
  const res = await brokerAPIInstance.request<T>({
    method: m,
    url: url,
    data: data,
  });
  return res.data;
};

export const getTradingAccount = async (accountID: string): Promise<TradingAccount> =>
  brokerAPIRequest('GET', `/v1/trading/accounts/${accountID}/account`);

// Entity details and control persons should only exist in Production

export const createEntityDetails = async (req: CreateEntityDetailsRequest): Promise<EntityDetailsResponse> => {
  instance.defaults.baseURL = prod;
  const res = await instance.post<EntityDetailsResponse>(`/entities`, req);
  instance.defaults.baseURL = sandbox;
  return res.data;
};

export const createControlPerson = async (req: CreateControlPersonRequest): Promise<CreateControlPersonResponse> => {
  instance.defaults.baseURL = prod;
  const res = await instance.post<CreateControlPersonResponse>(`/control_persons`, req);
  instance.defaults.baseURL = sandbox;
  return res.data;
};

export const createZendeskTicket = async (req: CreateZendeskTicketRequest): Promise<CreateZendeskTicketResponse> => {
  const res = await zendeskInstance.post<CreateZendeskTicketResponse>('/requests', req);
  return res.data;
};

export const uploadZendeskTicketAttachment = async (file: File): Promise<UploadZendeskTicketAttachmentResponse> => {
  const res = await zendeskInstance.post<UploadZendeskTicketAttachmentResponse>(
    '/uploads.json?filename=' + file.name,
    file,
    {
      headers: {
        'Content-Type': 'application/binary',
      },
    },
  );
  return res.data;
};
