import React from 'react';
import Header from '../components/layout/Header';
import OrdersTable from '../components/tables/OrdersTable';

const Orders = (): React.ReactElement => {
  return (
    <>
      <Header title="Transactions > Orders" />
      <OrdersTable />
    </>
  );
};

export default Orders;
