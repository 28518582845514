import React, { useState, useEffect } from 'react';
import { useQueryClient } from 'react-query';
import { useIntercom } from 'react-use-intercom';
import { getCorrespondent, getSelf } from '../api/api';
import { CognitoUser } from '@aws-amplify/auth';
import { setEnv, setAuthHeader } from '../api/api';
import { TeamMember, BdData } from '../api/types';
import { AuthService } from '../auth/authServices';

export interface Correspondent {
  ID?: string;
  Env?: string;
  Description?: string;
  BusinessType?: string;
  UserCountries?: string[];
  Name?: string;
  Setup?: string;
  Status?: string;
  BdData?: BdData;
}

const ckey = 'active_correspondent';
const getC = (): Correspondent => {
  const stored = localStorage.getItem(ckey);
  return JSON.parse(stored ?? '{}') as Correspondent;
};

const emptyCorrespondent: Correspondent = {
  ID: '',
  Env: 'sandbox',
  BusinessType: '',
  Setup: '',
};

interface Context {
  correspondent: Correspondent;
  isLoading: boolean;
  cognitoUser?: CognitoUser;
  authenticated: boolean;
  showMFA: boolean;
  setIsAuthenticated(isAuthenticated: boolean): void;
  setCognitoUser(user: CognitoUser): void;
  setIsLoading(isLoading: boolean): void;
  updateCorrespondent(correspondent?: Correspondent): void;
  clear(): void;
}

export const AppContext = React.createContext<Context>({} as Context);

interface AppContextProviderProps {
  children: React.ReactElement | React.ReactElement[];
}

const AppContextProvider = (props: AppContextProviderProps): React.ReactElement => {
  const local = getC();
  const [isLoading, setIsLoading] = useState(true);
  const [authenticated, setIsAuthenticated] = useState(false);
  const [showMFA, setShowMFA] = useState(false);
  const [cognitoUser, setCognitoUser] = useState<CognitoUser>();
  const [self, setSelf] = useState<TeamMember>();
  const [activeCorrespondent, setActiveCorrespondent] = React.useState<Correspondent>(local || emptyCorrespondent);
  const queryClient = useQueryClient();
  const { update } = useIntercom();

  useEffect(() => {
    if (!cognitoUser) return;
    getSelf().then((teamMember) => {
      setSelf(teamMember);
    });
  }, [cognitoUser]);

  useEffect(() => {
    if (!self) return;
    // Update intercom.
    update({ name: self.name, email: self.email, userId: self.id });

    const { correspondents = [] } = self;
    const correspondent = correspondents.find((c) => c.correspondent === activeCorrespondent.ID) || correspondents[0];

    if (!correspondent) {
      updateCorrespondent(emptyCorrespondent);
      return setIsLoading(false);
    }

    const env = correspondent.sandbox ? 'sandbox' : 'live';
    setEnv({
      ID: correspondent.correspondent,
      Env: env,
    });

    updateCorrespondent({
      ID: correspondent.correspondent,
      Env: env,
    });

    getCorrespondent()
      .then((c) => {
        updateCorrespondent({
          ID: c.correspondent,
          BusinessType: c.business_type,
          Env: env,
          Name: c.name,
          Setup: c.setup,
          Status: c.status,
        });
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  }, [self]);

  useEffect(() => {
    setEnv(activeCorrespondent);
    const cachedCorrespondent = getC();
    if (activeCorrespondent.ID !== cachedCorrespondent.ID || activeCorrespondent.Env !== cachedCorrespondent.Env) {
      localStorage.setItem(ckey, JSON.stringify(activeCorrespondent));
      queryClient.resetQueries();
    }
  }, [activeCorrespondent]);

  useEffect(() => {
    const mfaCheck = localStorage.getItem('show-mfa');

    if (mfaCheck === null) {
      AuthService.getPreferredMFA()
        .then((mfa) => {
          if (mfa !== 'SMS_MFA') {
            setShowMFA(true);
            localStorage.setItem('show-mfa', 'true');
          } else {
            localStorage.setItem('show-mfa', 'false');
          }
        })
        .catch(() => console.error('Error getting preferred MFA'));
    } else {
      setShowMFA(mfaCheck === 'true');
    }
  }, []);

  const updateCorrespondent = (newCorrespondent?: Correspondent) => {
    if (!newCorrespondent) {
      return setActiveCorrespondent(emptyCorrespondent);
    }
    setActiveCorrespondent({ ...activeCorrespondent, ...newCorrespondent });
  };

  const clear = () => {
    setActiveCorrespondent(emptyCorrespondent);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      // avoid refreshing auth headers in these routes as it will error and re-direct to login
      const nonAuthRoutes = ['/login', '/sign-up', '/forgot-password', '/set-password', '/verification'];
      if (!nonAuthRoutes.includes(window.location.pathname)) {
        setAuthHeader();
      }
    }, 10000);
    return () => clearInterval(interval);
  }, []);

  return (
    <AppContext.Provider
      value={{
        correspondent: activeCorrespondent,
        clear,
        updateCorrespondent,
        isLoading,
        cognitoUser,
        setCognitoUser,
        authenticated,
        setIsAuthenticated,
        setIsLoading,
        showMFA,
      }}
    >
      {props.children}
    </AppContext.Provider>
  );
};

export default AppContextProvider;
