import React, { useState, useCallback } from 'react';
import { getSelf } from '../api/api';
import { useQuery } from 'react-query';

import { Box, Text, Textarea, Button, useToast } from '@chakra-ui/react';
import FileUpload from '../components/general/FileUpload';
import { uploadZendeskTicketAttachment, createZendeskTicket } from '../api/api';
import styled from '@emotion/styled';
import { CreateZendeskTicketRequest } from '../api/types';

const SupportTextArea = styled(Textarea)`
  height: 15rem;
`;

const SupportFormBox = styled(Box)`
  height: 100%;
  display: flex;
  flex-direction: column;
  width: 75%;
  margin-top: 3rem;
`;

const FileListDisplay = styled(Box)`
  display: flex;
  flex-direction: column;
`;

const Support = (): React.ReactElement => {
  const { data: authUser } = useQuery('self', () => getSelf());
  const [ticketBody, setTicketBody] = useState('');
  const [fileList, setFileList] = useState<File[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast();

  const onDrop = useCallback((acceptedFiles) => {
    setFileList(acceptedFiles);
  }, []);

  const createTicket = async () => {
    setIsLoading(true);
    if (ticketBody === '') {
      toast({
        title: 'You must provide a description for your ticket.',
        status: 'error',
      });
      return;
    }
    // upload any files to Zendesk and then attach their IDs to the ticket request body
    const uploadTokens: string[] = [];
    for (const file of fileList) {
      const resp = await uploadZendeskTicketAttachment(file as File);
      uploadTokens.push(resp.upload.token);
    }
    // the subject in zendesk is the first 50 chars of the msg or the entire message if less than that
    const subject = ticketBody.substr(0, ticketBody.length < 50 ? ticketBody.length : 50);
    const createRequest: CreateZendeskTicketRequest = {
      request: {
        comment: { body: ticketBody, uploads: uploadTokens },
        requester: { name: authUser?.name ?? '', email: authUser?.email ?? '' },
        subject,
        tags: ['broker-support'],
      },
    };
    createZendeskTicket(createRequest)
      .then(() => {
        toast({
          title: 'Ticket submitted successfully!',
          status: 'success',
        });
        setIsLoading(false);
        setTicketBody('');
        setFileList([]);
      })
      .catch(() => {
        toast({
          title: 'An error occurred while creating your ticket.',
          status: 'error',
        });
        setIsLoading(false);
      });
  };

  return (
    <Box>
      <Text fontSize="2.5rem" fontWeight="500">
        Support
      </Text>

      <SupportFormBox>
        <Text fontSize="1.5rem" fontWeight="500" mb="1rem">
          How can we help?
        </Text>
        <SupportTextArea
          placeholder="Describe your issue..."
          onChange={(e) => {
            setTicketBody(e.target.value);
          }}
          value={ticketBody}
        />
        <Box mt="1rem">
          <FileUpload onDrop={onDrop} />
          <FileListDisplay>
            {fileList.map((entry, idx) => (
              <Text key={idx} fontSize="1rem" mt="1rem">
                {fileList[idx].name}
              </Text>
            ))}
          </FileListDisplay>
          <Button onClick={createTicket} mt="1.5rem" isLoading={isLoading} disabled={ticketBody === ''}>
            Submit
          </Button>
        </Box>
      </SupportFormBox>
    </Box>
  );
};

export default Support;
