import React, { useState } from 'react';
import {
  Box,
  FormControl,
  FormLabel,
  Input,
  Button,
  Text,
  Center,
  useToast,
  Alert,
  AlertIcon,
  BoxProps,
} from '@chakra-ui/react';
import { setAuthHeader, createAccount } from '../../api/api';
import { RegistrationRequest } from '../../api/types';
import { useLocation, Navigate } from 'react-router-dom';
import { AuthService } from '../../auth/authServices';

interface LocationState {
  email?: string;
}

const SetPassword = (props: BoxProps): React.ReactElement => {
  const { state } = useLocation();
  const locationState: LocationState = state as LocationState;

  const [email, setEmail] = useState(locationState?.email || '');
  const [code, setCode] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const [error, setError] = useState('');
  const toast = useToast();

  const handleCreateAccount = async (password: string) => {
    const authUser = await AuthService.login(email, password);
    const req: RegistrationRequest = {
      cognito_id: authUser.username,
      email: email,
    };
    await setAuthHeader();
    const newUser = await createAccount(req);
    console.info('Registration completed: ', newUser);
  };

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    setIsLoading(true);

    AuthService.forgotPasswordSetNew(email, code, newPassword)
      .then(() => {
        toast({
          title: 'Your password has been updated',
          status: 'success',
        });
        if (localStorage.getItem('unverified-account') !== null) {
          handleCreateAccount(newPassword).then(() => localStorage.removeItem('unverified-account'));
        }
        setRedirect(true);
      })
      .catch((e) => {
        setError(e.message);
        setNewPassword('');
      });

    setIsLoading(false);
  };

  if (redirect) {
    return <Navigate to="/login" />;
  }

  return (
    <Box {...props}>
      <Center>
        <Box>
          <Text fontSize="4xl" mb="2rem">
            Update your password
          </Text>
          <form onSubmit={handleSubmit}>
            <FormControl isRequired mt={6}>
              <FormLabel>Email</FormLabel>
              <Input
                disabled={!!locationState?.email}
                variant="filled"
                type="email"
                size="lg"
                value={email}
                onChange={(event) => setEmail(event.currentTarget.value)}
              />
            </FormControl>
            <FormControl isRequired mt={6}>
              <FormLabel>Code</FormLabel>
              <Input variant="filled" type="text" size="lg" onChange={(event) => setCode(event.currentTarget.value)} />
            </FormControl>
            <FormControl isRequired mt={6}>
              <FormLabel>New Password</FormLabel>
              <Input
                variant="filled"
                type="password"
                size="lg"
                onChange={(event) => setNewPassword(event.currentTarget.value)}
              />
            </FormControl>
            {error && (
              <Alert status="error" mt="1rem">
                <AlertIcon />
                {error}
              </Alert>
            )}
            <Button size="lg" type="submit" width="full" mt="4rem" isLoading={isLoading}>
              Update Password
            </Button>
          </form>
        </Box>
      </Center>
    </Box>
  );
};

export default SetPassword;
