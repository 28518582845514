import React, { Dispatch, SetStateAction } from 'react';
import { Box, Text, HStack, VStack, FormControl } from '@chakra-ui/react';
import { GoLivePill, GoLiveInput, InputValue } from './GoLive';

interface ProductDetailsProps {
  handleUrl: Dispatch<SetStateAction<InputValue>>;
  handleUserbase: Dispatch<SetStateAction<string>>;
  handleReplacingPlatform: Dispatch<SetStateAction<boolean | null>>;
  handleAlpacaUse: Dispatch<SetStateAction<string>>;
  userbase: string;
  alpacaUse: string;
  replacingPlatform: boolean | null;
  productUrl: string;
}

const ProductDetails = (props: ProductDetailsProps): React.ReactElement => {
  return (
    <Box w="100%" mb="44px">
      <Text fontSize="16px" mb="12px">
        Website URL
      </Text>
      <FormControl w="100%">{GoLiveInput('', 'https://', props.handleUrl)}</FormControl>
      <Text fontSize="16px" mt="24px" mb="12px">
        How many users do you currently have?
      </Text>
      <HStack w="100%">
        <GoLivePill onClick={() => props.handleUserbase('< 1,000')} active={props.userbase === '< 1,000'}>
          <Text fontWeight="medium" fontSize="15px">
            {'< 1,000'}
          </Text>
        </GoLivePill>
        <GoLivePill
          onClick={() => props.handleUserbase('1,001 - 5,000')}
          active={props.userbase === '1,001 - 5,000'}
          ml="20px !important"
        >
          <Text fontWeight="medium" fontSize="15px">
            {'1,001 - 5,000'}
          </Text>
        </GoLivePill>
        <GoLivePill
          onClick={() => props.handleUserbase('5,001 - 25,000')}
          active={props.userbase === '5,001 - 25,000'}
          ml="20px !important"
        >
          <Text fontWeight="medium" fontSize="15px">
            {'5,001 - 25,000'}
          </Text>
        </GoLivePill>
        <GoLivePill
          onClick={() => props.handleUserbase('> 25,000')}
          active={props.userbase === '> 25,000'}
          ml="20px !important"
        >
          <Text fontWeight="medium" fontSize="15px">
            {'> 25,000'}
          </Text>
        </GoLivePill>
      </HStack>
      <VStack mt="40px" alignItems="start">
        <Text fontSize="16px" mb="12px !important">
          Will trading services provided by Alpaca be the focus of the app or a simple add-on?
        </Text>
        <HStack mb="24px">
          <GoLivePill onClick={() => props.handleAlpacaUse('focus')} active={props.alpacaUse === 'focus'}>
            <Text fontWeight="medium" fontSize="15px">
              Focus of app
            </Text>
          </GoLivePill>
          <GoLivePill
            ml="24px !important"
            onClick={() => props.handleAlpacaUse('add-on')}
            active={props.alpacaUse === 'add-on'}
          >
            <Text fontWeight="medium" fontSize="15px">
              Add-on
            </Text>
          </GoLivePill>
        </HStack>
      </VStack>
      <VStack mt="40px" alignItems="start">
        <Text fontSize="16px" mb="8px">
          Are you replacing another platform/product with Alpaca Broker API?
        </Text>
        <HStack mb="24px">
          <GoLivePill onClick={() => props.handleReplacingPlatform(false)} active={props.replacingPlatform === false}>
            <Text fontWeight="medium" fontSize="15px">
              Yes
            </Text>
          </GoLivePill>
          <GoLivePill
            ml="24px !important"
            onClick={() => props.handleReplacingPlatform(true)}
            active={props.replacingPlatform}
          >
            <Text fontWeight="medium" fontSize="15px">
              No
            </Text>
          </GoLivePill>
        </HStack>
      </VStack>
    </Box>
  );
};

export default ProductDetails;
