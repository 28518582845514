import moment from 'moment';
import numeral from 'numeral';
import { Order, Transfer, Document, Journal, Account } from '../api/types';
import { TableValue } from '../components/general/Table';
import { AccountTableRow } from './types';

export const capitalize = (value: string | undefined, split = true): string => {
  if (!value) return '';
  if (split) value = value.split('_').join(' ');
  return value
    .toLowerCase()
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};

export const ordersToTableRows = (
  orders: (Order | undefined)[] | undefined,
  includeAccountID = false,
): (string | moment.Moment)[][] => {
  if (!orders) return [];

  return orders.map((order) => {
    const row = [
      order?.id || '',
      capitalize(order?.side || ''),
      order?.symbol || '',
      order?.qty && order.qty > 0 ? order.qty.toString() || '' : numeral(order?.filled_qty).format('0.00'),
      numeral(order?.notional).format('$0,0.00'),
      capitalize(order?.status || ''),
      order?.updated_at || '',
    ];
    if (includeAccountID) row.unshift(order?.account_id || '');
    return row;
  });
};

// Get a cash formatted string from a number or string
export const cashFormat = (num: number | string): string => {
  return numeral(num || '0.00').format('$0,0.00');
};

// Transform accounts into AccountTableRows (modifies rows in place)
export const mapAccountsToRowsInPlace = (accounts: Account[], rows: Record<string, AccountTableRow>): void => {
  accounts.map((account: Account) => {
    rows[account.id] = {
      name: account.name || '',
      email_address: account.contact?.email_address || '',
      id: account.id,
      // Default to last_equity if it exists
      equity: cashFormat(account.last_equity ?? '0.00'),
      status: capitalize(account.status.split('_').join(' ')),
      created_at: account.created_at,
    };
  });
};

// Transform AccountTableRow[]s to TableValue[]s
export const mapAccountTableRowsToTableValues = (rows: AccountTableRow[]): TableValue[][] => {
  return rows.map((row) => Object.values(row));
};

export const txsToTableRows = (
  transfers: (Transfer | undefined)[] | undefined,
  includeAccountID = true,
): (string | moment.Moment)[][] => {
  if (!transfers) return [];

  return transfers.map((t) => {
    const row = [
      t?.id || '',
      capitalize(t?.direction || ''),
      t?.type.toUpperCase() || '',
      numeral(t?.amount).format('$0,0.0'),
      t?.status || '',
      t?.updated_at || '',
    ];
    if (includeAccountID) row.unshift(t?.account_id || '');
    return row;
  });
};

export const docsToTableRows = (documents: (Document | undefined)[] | undefined, withActID = true): string[][] => {
  if (!documents) return [];
  if (withActID)
    return documents.map((doc) => [
      doc?.id || '',
      doc?.account_id || '',
      doc?.type.split('_').join(' ') || '',
      doc?.date || '',
    ]);
  return documents.map((doc) => [doc?.id || '', doc?.type.split('_').join(' ') || '', doc?.date || '']);
};

export const journalsToTableRows = (
  journals: (Journal | undefined)[] | undefined,
  type = 'JNLC',
): (string | moment.Moment)[][] => {
  if (!journals) return [];
  if (type === 'JNLC')
    return journals.map((j) => [
      j?.id || '',
      j?.entry_type || '',
      j?.from_account || '',
      j?.to_account || '',
      numeral(j?.net_amount).format('$0,0.00'),
      j?.status || '',
      j?.settle_date || '',
    ]);

  return journals.map((j) => [
    j?.id || '',
    j?.entry_type || '',
    j?.symbol || '',
    numeral(j?.qty).format('0.00'),
    j?.from_account || '',
    j?.to_account || '',
    '',
    '',
  ]);
};

export const getDates = (from: moment.Moment, to: moment.Moment, inclusive?: boolean): moment.Moment[] => {
  const dates = [];
  const f = from.clone();

  while (f.isBefore(to)) {
    dates.push(f.clone());
    f.add(1, 'd');
  }

  if (inclusive) {
    dates.push(to.clone());
  }

  return dates;
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any
export const formatJSON = (j: any): string => {
  if (typeof j === 'string') j = JSON.parse(j);
  return JSON.stringify(j, null, '\t');
};

export const firmAccountName = (accountNum: string): string => {
  if (accountNum.endsWith('SW')) {
    return 'Sweep';
  } else if (accountNum.endsWith('ER')) {
    return 'Error';
  } else if (accountNum.endsWith('DP')) {
    return 'Deposit';
  } else if (accountNum.endsWith('RW')) {
    return 'Rewards';
  } else if (accountNum.endsWith('CR')) {
    return 'Credit';
  } else if (accountNum.endsWith('BR')) {
    return 'Billing Receivables';
  } else if (accountNum.endsWith('CP')) {
    return 'Commissions Payable';
  }
  return 'Firm';
};
