import React, { useState, useEffect } from 'react';

import {
  Box,
  Flex,
  Text,
  Input,
  Button,
  Collapse,
  HStack,
  Alert,
  AlertIcon,
  Select,
  Stack,
  useToast,
} from '@chakra-ui/react';
import Card from '../components/general/Card';
import styled from 'styled-components';
import { NavLink, useNavigate } from 'react-router-dom';
import { ArrowForwardIcon, CheckIcon } from '@chakra-ui/icons';

import { GenerateAPIkeys } from './Devs';
import { AppContext } from '../globals/appcontext';
import colors from '../components/theme/colors';
import { createCorrespondent, getCorrespondent, updateCorrespondent } from '../api/api';
import { BusinessType } from '../api/types';
import { BrokerAPIDocSrc } from '../globals/consts';

const StepTab = styled(Button)`
  && {
    min-width: 30px;
    font-size: 16px;
    height: 33px;
    width: 34px;
    text-align: center;
    margin-right: 1rem;
    ${(props) =>
      props.status < StepStatus.Started
        ? 'border: 1px solid; background-color: transparent; color: gray; height: 33px;'
        : `background-color: ${colors.yellow}; color: black`};
    border-radius: 100%;
  }
`;

const DocumentationLink = styled.a`
  color: ${colors.yellow};
  margin: 0px 5px;
  &:hover {
    font-weight: 700;
  }
`;

const businessTypes = [
  {
    value: BusinessType.BrokerDealer,
    label: 'Broker-Dealer',
    description: 'We are a registered broker-dealer looking to offer our customers access to the US equities market.',
  },
  {
    value: BusinessType.NeoBank,
    label: 'Challenger Bank/Neobank',
    description:
      'We are a challenger bank/neobank that is adding a trading/investing component in the US equities market.',
  },
  {
    value: BusinessType.EstablishedFintech,
    label: 'Established Fintech',
    description:
      'We are an established fintech company providing financial services to your customers, such as payroll or payments, and are looking to build a trading/investing component to augment our existing product/service.',
  },
  {
    value: BusinessType.RegisteredInvestmentAdvisor,
    label: 'Registered Investment Advisor',
    description:
      'We are a registered investment advisor looking to build a mobile or web-based offering for our customers.  We have a fiduciary responsibility to your customers and provide investment advice to our customers and/or manage our customers’ investment portfolios.',
  },
  {
    value: BusinessType.SaaS,
    label: 'SaaS',
    description:
      'We are not a fintech company but we are looking to bring in investment services to our users. We aren’t registered with any financial regulatory body like the SEC, SIPC, or FINRA (at least not yet).',
  },
  {
    value: BusinessType.Other,
    label: "I'm not sure/for fun",
    description: 'Select this option if none of the above options best describes you, or if you are not sure.',
  },
];

enum StepStatus {
  Incomplete = 0,
  Started = 1,
  Completed = 2,
}

const Onboarding = (): React.ReactElement => {
  const toast = useToast();
  const navigate = useNavigate();
  const appContext = React.useContext(AppContext);
  const [currentStep, setStep] = useState(0);
  const [stepStatus, setStepStatus] = useState([
    StepStatus.Started,
    StepStatus.Incomplete,
    StepStatus.Incomplete,
    StepStatus.Incomplete,
  ]);
  const [teamName, setTeamName] = useState('');
  const [businessType, setBusinessType] = useState({ value: '', label: '', description: '' });
  const [isUpdating, setIsUpdating] = useState(false);

  const handleTeamNameChange = (event: React.ChangeEvent<HTMLInputElement>) => setTeamName(event.target.value);

  const handleBusinessTypeChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const type = businessTypes.find((t) => t.value === event.target.value) || { value: '', label: '', description: '' };
    setBusinessType(Object.assign({}, type));
  };

  const handleStepChange = (indx: number) => {
    if (stepStatus[indx] > StepStatus.Incomplete) {
      setStep(indx);
    }
  };

  const handleCompleteStatus = (indx: number) => {
    const status = [...stepStatus];
    status[indx] = StepStatus.Completed;

    // check if next step wasn't already complete
    if (status[indx + 1] !== StepStatus.Completed) {
      status[indx + 1] = StepStatus.Started;
    }

    // final two steps don't require submssion
    if (indx == 1) {
      status[indx + 2] = StepStatus.Started;
    }
    setStepStatus(status);
  };

  const showError = (err: Error, title = 'There was a problem with the request') =>
    toast({
      title,
      description: err.message,
      status: 'error',
    });

  useEffect(() => {
    const correspondent = appContext?.correspondent || {};

    if (appContext?.correspondent?.ID) {
      const status = [...stepStatus];
      status[0] = StepStatus.Completed;

      if (!correspondent.BusinessType) {
        status[1] = StepStatus.Started;
        setStepStatus(status);
        return setStep(1);
      }

      if (!correspondent.UserCountries || !correspondent.UserCountries.length) {
        status[1] = StepStatus.Completed;
        status[2] = StepStatus.Started;
        setStepStatus(status);
        return setStep(2);
      }

      navigate('/dashboard');
    }
  }, []);

  const waitForAuthorization = async () => {
    for (let tries = 1; tries <= 5; tries++) {
      try {
        // Slightly hacky but need to give db trigger invalidation time to
        // propogate to all auth instances.
        const c = await getCorrespondent();
        appContext.updateCorrespondent({ ID: c.correspondent, Env: 'sandbox', Name: c.name });
        break;
      } catch (err) {
        await new Promise((r) => setTimeout(r, 10 * tries));
        console.debug(err);
      }
    }
  };

  const submitTeamName = async () => {
    if (teamName === '') return;
    try {
      setIsUpdating(true);

      // if it already exists, just update the name
      if (appContext.correspondent.ID) {
        await updateCorrespondent({ ...appContext.correspondent, Name: teamName });
        appContext.updateCorrespondent({ Name: teamName });
      } else {
        const c = await createCorrespondent(teamName, 'sandbox');
        appContext.updateCorrespondent({ ID: c.correspondent, Env: 'sandbox' });
        await waitForAuthorization();
      }
      handleCompleteStatus(0);
      setStep(1);
    } catch (e) {
      showError(e);
    }
    setIsUpdating(false);
  };

  const submitBusinessType = async () => {
    if (!businessType.value) {
      return setStep(2);
    }
    try {
      setIsUpdating(true);
      await updateCorrespondent({ ...appContext.correspondent, BusinessType: businessType.value });
      appContext.updateCorrespondent({ BusinessType: businessType.value });
      handleCompleteStatus(1);
      setStep(2);
    } catch (e) {
      showError(e);
    }
    setIsUpdating(false);
  };

  const renderNumberOrCheck = (index: number) => {
    if (stepStatus[index] == StepStatus.Completed) {
      return <CheckIcon w={3} h={3} />;
    }
    return index + 1;
  };
  const steps: React.ReactElement[] = [
    <Box key="0">
      <Text fontSize="xl">Choose Team Name</Text>
      <Text opacity="70%">You can change it any time</Text>
      <Collapse in={currentStep === 0}>
        <HStack>
          <Input
            value={teamName}
            placeholder="Team name"
            variant="filled"
            m="1rem 0rem"
            onChange={handleTeamNameChange}
          />
          <Button mr="1rem" onClick={() => submitTeamName()} disabled={isUpdating}>
            Ok
          </Button>
        </HStack>
      </Collapse>
    </Box>,
    <Box key="1">
      <Text fontSize="xl">What best describes your product?</Text>
      <Collapse in={currentStep === 1}>
        <Text opacity="70%">
          Depending on the type of product we will be able to optimize your experience with Alpaca
        </Text>
        <Stack direction="column" margin="1em 0">
          <Box>
            <Select
              placeholder="Select option"
              variant="filled"
              value={businessType.value}
              onChange={handleBusinessTypeChange}
            >
              {businessTypes.map(({ label, value }, i) => (
                <option key={i} value={value}>
                  {label}
                </option>
              ))}
            </Select>
          </Box>
          {businessType.description && (
            <>
              <Card>
                <Text>{businessType.description}</Text>
              </Card>
              <Box>
                <Button onClick={() => submitBusinessType()} disabled={isUpdating}>
                  Next
                </Button>
              </Box>
            </>
          )}
        </Stack>
      </Collapse>
    </Box>,
    <Box key="2" width={{ base: '', md: 'fit-content' }}>
      <Text fontSize="xl">Generate Sandbox API Keys</Text>
      <Text opacity="70%">You&apos;ll need API keys to operate with the system</Text>
      <Collapse in={currentStep === 2}>
        <GenerateAPIkeys />
        <Button
          mr="1rem"
          onClick={() => {
            setStep(3);
            handleCompleteStatus(2);
          }}
        >
          Ok
        </Button>
      </Collapse>
    </Box>,
    <Box key="3" width={{ base: '', md: 'fit-content' }}>
      <Text fontSize="xl">Go to Dashboard</Text>
      <Collapse in={currentStep === 3}>
        <Alert status="info" mt="1rem" width={{ base: '', md: 'max-content' }}>
          <AlertIcon />
          Check out our
          <DocumentationLink href={BrokerAPIDocSrc} target="_blank">
            Getting Started Guide
          </DocumentationLink>
          for more information on how to get up and running with Alpaca Broker API.
        </Alert>
        <Button mr="1rem" mt="1rem" variant="ghost" onClick={() => setStep(2)}>
          Prev
        </Button>
        <NavLink to="/dashboard">
          <Button mt="1rem" rightIcon={<ArrowForwardIcon />}>
            Dashboard
          </Button>
        </NavLink>
      </Collapse>
    </Box>,
  ];

  return (
    <Box p={[null, '4rem']}>
      <Text fontSize="34px" fontWeight="bold">
        Welcome!
      </Text>
      <Box maxWidth="30em">
        {steps.map((step, idx) => (
          <Flex key={idx} mt="2rem">
            <StepTab status={stepStatus[idx]} onClick={() => handleStepChange(idx)} _focus={{ boxShadow: 'none' }}>
              {renderNumberOrCheck(idx)}
            </StepTab>
            <Box width="100%">{step}</Box>
          </Flex>
        ))}
      </Box>
    </Box>
  );
};

export default Onboarding;
