import { Box } from '@chakra-ui/react';
import { isMobile } from 'react-device-detect';
import styled from 'styled-components';
import colors from '../theme/colors';

const getBbColor = (colorMode: string, inCard: boolean) => {
  if (colorMode === 'light') return inCard ? colors.cardBackgroundLight : colors.bgLight;
  return inCard ? colors.cardBackgroundDark : colors.bgDark;
};

const hideScrollBar = `
  ::-webkit-scrollbar {
    display: none;
  }
  scrollbar-width: none;
  -ms-overflow-style: none;
`;

export const Scrollbox = styled(Box)`
  &::-webkit-scrollbar {
    width: 12px;
  }

  &::-webkit-scrollbar-track {
    background: ${(props) => getBbColor(props.colormode, props.$inCard)};
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${(props) =>
      props.$inCard && props.colormode === 'dark' ? colors.bgDark : colors.cardBackgroundDark};
    border-radius: 20px;
    border: 2px solid ${(props) => getBbColor(props.colormode, false)};
  }

  ${isMobile ? hideScrollBar : ''}
`;
