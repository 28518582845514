import React from 'react';
import { Text } from '@chakra-ui/react';

const FilterHeader = (props: { value: string }): React.ReactElement => {
  return (
    <Text fontSize="md" mt="2rem" ml="3px" fontWeight={500}>
      {props.value}
    </Text>
  );
};

export default FilterHeader;
