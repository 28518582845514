import React, { useEffect, useState } from 'react';
import { useToast, Box, Spacer, Flex } from '@chakra-ui/react';
import { QueryKey, useInfiniteQuery } from 'react-query';
import { FilterDrawer, FilterSelect, FilterButton, RangeType, FilterDateSelect, dateRangeToPill } from '../filter';
import Table from '../general/Table';
import { getDocuments, docDownloadLink } from '../../api/api';
import { docsToTableRows } from '../../globals/utils';
import { useNavigate } from 'react-router-dom';
import { DocumentsParams } from '../../api/types';
import { APILinks } from '../../globals/consts';

const documentTypes = [
  'account_statement',
  'trade_confirmation',
  'tax_1099_b_details',
  'tax_1099_b_form',
  'tax_1099_div_details',
  'tax_1099_div_form',
  'tax_1099_int_details',
  'tax_1099_int_form',
  'tax_w8',
  'account_dump',
  'esign_agreement',
  'trulioo_transaction_record',
  'etc_form',
  'non_solicitation_form',
];

const QUERY_LIMIT = 50;

const DocumentsTable = (props: { accountID?: string }): React.ReactElement => {
  const toast = useToast();
  const navigate = useNavigate();

  // Filters
  const [filterOpen, setFilterOpen] = useState(false);
  const [typeFilter, setTypeFilter] = useState('all');
  const [selectedDates, setSelectedDates] = useState<RangeType>([null, null]);
  const [filterKey, setFilterKey] = useState<QueryKey>(['documents', props.accountID]);
  const [applyFilter, setApplyFilter] = useState(false);

  const keys = ['documents', typeFilter, selectedDates, props.accountID];
  useEffect(() => {
    if (applyFilter) setFilterKey(keys);
  }, [applyFilter, ...keys]);

  const fetchDocuments = ({ pageParam = 0 }) => {
    const params: DocumentsParams = {
      type: typeFilter,
      limit: QUERY_LIMIT,
      offset: pageParam * QUERY_LIMIT,
    };

    // date filter
    if (selectedDates[0] && selectedDates[1]) {
      params.start = selectedDates[0].format('YYYY-MM-DD');
      params.end = selectedDates[1].format('YYYY-MM-DD');
    }

    return getDocuments(params, props.accountID);
  };

  const documentsQuery = useInfiniteQuery(filterKey, fetchDocuments, {
    getNextPageParam: (lastPage, pages) => {
      if (lastPage.length === QUERY_LIMIT) {
        return pages.length;
      }
    },
  });

  if (documentsQuery.isError) {
    toast({
      title: 'An error occurred fetching documents',
      description: (documentsQuery.error as Error).message,
      status: 'error',
    });
  }

  const rows = docsToTableRows(documentsQuery.data?.pages.flat(), !props.accountID);
  const downloadDoc = async (index: number) => {
    docDownloadLink({
      document_id: rows[index][0],
      account_id: props.accountID || rows[index][1],
      redirect: false,
    }).catch((err) =>
      toast({
        title: 'Failed to download document',
        description: err.message,
        status: 'error',
      }),
    );
  };

  const removeFilter = (filterID: string) => {
    if (filterID === 'type') setTypeFilter('all');
    if (filterID === 'date') setSelectedDates([null, null]);
  };

  const filterButton = (
    <FilterButton
      filterPills={
        applyFilter
          ? {
              type: typeFilter,
              date: dateRangeToPill(selectedDates),
            }
          : {}
      }
      removeFilter={removeFilter}
      openFilter={() => {
        setFilterOpen(true);
        setApplyFilter(false);
      }}
    />
  );

  const headers = props.accountID ? ['Document ID', 'Type', 'Date'] : ['Document ID', 'Account ID', 'Type', 'Date'];
  const copyRows = props.accountID ? [0] : [0, 1];

  const filterBtn = props.accountID ? (
    <Box mt="-6.5rem" float="right">
      {filterButton}
    </Box>
  ) : (
    <Flex mt="3rem">
      <Spacer />
      {filterButton}
    </Flex>
  );

  return (
    <>
      <FilterDrawer
        isOpen={filterOpen}
        onClose={() => setFilterOpen(false)}
        onApply={() => {
          setApplyFilter(true);
          setFilterOpen(false);
        }}
      >
        <FilterSelect header="Document Type" options={documentTypes} onSelect={setTypeFilter} selected={typeFilter} />
        <FilterDateSelect header="Date" onDateSelect={(st, ed) => setSelectedDates([st, ed])} />
      </FilterDrawer>
      {filterBtn}
      <Table
        isLoading={documentsQuery.isLoading}
        headers={headers}
        rows={rows}
        copyIndexes={copyRows}
        rowsPerPage={10}
        onDownload={(index) => downloadDoc(index)}
        loadMoreData={documentsQuery.fetchNextPage}
        onValueClick={(rowIdx, colIdx) => {
          if (colIdx === 1 && !props.accountID) {
            navigate(`/accounts/${rows[rowIdx][colIdx]}`);
          }
        }}
        noContentTitle="No documents available for this account"
        noContentLinkText="Learn more about Documents"
        noContentLinkDestination={APILinks.documents}
      />
    </>
  );
};

export default DocumentsTable;
