import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
} from '@chakra-ui/react';
import React from 'react';

interface DashModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit?: () => void;
  children: React.ReactElement | React.ReactElement[];
  loading?: boolean;
  title: string;
  closeBtn?: string;
  submitBtn: string;
}

const DashModal = (props: DashModalProps): React.ReactElement => {
  return (
    <Modal isOpen={props.isOpen} onClose={props.onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{props.title}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>{props.children}</ModalBody>
        <ModalFooter>
          {props.closeBtn && (
            <Button colorScheme="blue" mr={3} onClick={props.onClose}>
              {props.closeBtn}
            </Button>
          )}
          {props.onSubmit && (
            <Button onClick={props.onSubmit} isLoading={props.loading}>
              {props.submitBtn}
            </Button>
          )}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

DashModal.defaultProps = {
  title: '',
  submitBtn: 'Save',
};

export default DashModal;
